// src/admin/pages/Acces.jsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select, // Importer Select pour le sélecteur d'icônes
  Stack,
  Heading,
  useToast,
  Flex,
  Textarea,
  Spinner,
  VStack,
  Divider,
  Center,
  Text,
  Icon,
} from '@chakra-ui/react';
import { getFirestore, collection, addDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import * as MdIcons from 'react-icons/md'; // Importer toutes les icônes de Material Design

const Acces = () => {
  const toast = useToast();
  const db = getFirestore();

  const [accessList, setAccessList] = useState([]);
  const [path, setPath] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState(''); // Nouvel état pour l'icône
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour charger les Voies d'Accès
  const fetchAccess = useCallback(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'acces'),
      (snapshot) => {
        const accessData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAccessList(accessData);
        setIsLoading(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des accès:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les accès.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  useEffect(() => {
    fetchAccess();
  }, [fetchAccess]);

  // Fonction pour ajouter une Voie d'Accès
  const handleAddAccess = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (path.trim() === '' || description.trim() === '' || icon.trim() === '') {
      toast({
        title: 'Champs manquants',
        description: 'Veuillez remplir tous les champs.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await addDoc(collection(db, 'acces'), {
        path: path.trim(),
        description: description.trim(),
        icon: icon.trim(), // Enregistrer l'icône sélectionnée
        createdAt: new Date(),
      });
      toast({
        title: 'Voie d\'accès ajoutée.',
        description: 'La voie d\'accès a été ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setPath('');
      setDescription('');
      setIcon(''); // Réinitialiser l'icône sélectionnée
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la voie d\'accès:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter la voie d\'accès. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fonction pour supprimer une Voie d'Accès
  const handleDeleteAccess = async (accessId) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette voie d\'accès ?');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'acces', accessId));
      toast({
        title: 'Voie d\'accès supprimée.',
        description: 'La voie d\'accès a été supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la voie d\'accès:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer la voie d\'accès. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="start" minH="100vh" bg="gray.50" p="10">
      <Box
        w="full"
        maxW="6xl"
        bg="white"
        p="8"
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading as="h2" size="lg" mb="6">
          Gérer les Voies d'Accès
        </Heading>

        {/* Formulaire pour ajouter une Voie d'Accès */}
        <form onSubmit={handleAddAccess}>
          <Stack spacing="4">
            <FormControl id="path" isRequired>
              <FormLabel>Voie d'Accès</FormLabel>
              <Input
                type="text"
                value={path}
                onChange={(e) => setPath(e.target.value)}
                placeholder="Entrez la voie d'accès"
                bg="gray.100"
              />
            </FormControl>

            <FormControl id="description" isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Entrez la description"
                bg="gray.100"
              />
            </FormControl>

            {/* Sélecteur d'Icône */}
            <FormControl id="icon" isRequired>
              <FormLabel>Icône</FormLabel>
              <Select
                placeholder="Sélectionnez une icône"
                value={icon}
                onChange={(e) => setIcon(e.target.value)}
                bg="gray.100"
              >
                <option value="MdBusinessCenter">Business Center</option>
                <option value="MdSchool">School</option>
                <option value="MdComputer">Computer</option>
                <option value="MdLocationOn">Location On</option>
                <option value="MdFlight">Flight</option>
                <option value="MdTrendingUp">Trending Up</option>
                {/* Ajoutez d'autres options d'icônes si nécessaire */}
              </Select>
            </FormControl>

            <Button
              type="submit"
              colorScheme="teal"
              isLoading={isSubmitting}
            >
              Ajouter
            </Button>
          </Stack>
        </form>

        <Divider my="6" />

        {/* Liste des Voies d'Accès */}
        <Heading as="h3" size="md" mb="4">
          Liste des Voies d'Accès
        </Heading>

        {isLoading ? (
          <Center>
            <Spinner size="xl" color="teal.500" />
          </Center>
        ) : accessList.length === 0 ? (
          <Text>Aucune voie d'accès disponible.</Text>
        ) : (
          <VStack align="stretch" spacing="4">
            {accessList.map((access) => {
              const IconComponent = MdIcons[access.icon] || MdIcons.MdTrendingUp; // Sélectionner l'icône basée sur le nom
              return (
                <Box key={access.id} p="4" bg="gray.100" borderRadius="md">
                  <Flex justify="space-between" align="center">
                    <Flex align="center">
                      <Icon as={IconComponent} w={6} h={6} color="teal.500" mr="4" />
                      <Box>
                        <Text fontWeight="bold">{access.path}</Text>
                        <Text>{access.description}</Text>
                      </Box>
                    </Flex>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDeleteAccess(access.id)}
                    >
                      Supprimer
                    </Button>
                  </Flex>
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>
    </Flex>
  );
};

export default Acces;
