// src/public/pages/HomePage.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Heading,
  Flex,
  Text,
  Grid,
  Image,
  Tag,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  Icon,
  VStack,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  SimpleGrid,
  HStack,
  Divider,
  useBreakpointValue,
  Tooltip,
  Circle,
} from '@chakra-ui/react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore'; // Importé 'query' et 'where'
import {
  MdAdminPanelSettings,
  MdAccessTime,
  MdHelpOutline,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdFilterList,
  MdMap,
  MdLink,
  MdCloudDownload,
} from 'react-icons/md';
import {
  FaBriefcase,
  FaCode,
  FaChalkboardTeacher,
  FaHospital,
  FaIndustry,
  FaPaintBrush,
  FaBook,
  FaInfoCircle,
  FaSchool,
  FaCertificate,
} from 'react-icons/fa';
import { motion } from 'framer-motion';

// Composant Motion pour les animations (si nécessaire)
const MotionBox = motion(Box);

const HomePage = () => {
  const navigate = useNavigate();
  const [formations, setFormations] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);
  const [formationsByDomaine, setFormationsByDomaine] = useState({});
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [domaines, setDomaines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subTabIndex, setSubTabIndex] = useState(0); // État pour l'onglet de domaine actif

  // États pour la pagination des formations
  const [currentPageFormations, setCurrentPageFormations] = useState(1);
  const [itemsPerPageFormations, setItemsPerPageFormations] = useState(8);
  const [totalPagesFormations, setTotalPagesFormations] = useState(1);

  // États pour la pagination des examens
  const [currentPageExams, setCurrentPageExams] = useState(1);
  const [itemsPerPageExams, setItemsPerPageExams] = useState(8);
  const [totalPagesExams, setTotalPagesExams] = useState(1);

  // États pour le tri des formations
  const [sortOption, setSortOption] = useState('title');

  // États pour les filtres
  const [selectedDomaines, setSelectedDomaines] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // Mapping des domaines aux icônes
  const domaineIcons = {
    Informatique: FaCode,
    Santé: FaHospital,
    Art: FaPaintBrush,
    Enseignement: FaChalkboardTeacher,
    Industrie: FaIndustry,
    Business: FaBriefcase,
    Littérature: FaBook,
    'Non spécifié': FaBriefcase,
    // Ajoutez d'autres domaines et icônes si nécessaire
  };

  // Mapping des domaines aux couleurs (pour mode clair et sombre)
  const domaineColorsLight = {
    Informatique: 'blue.100',
    Santé: 'green.100',
    Art: 'pink.100',
    Enseignement: 'purple.100',
    Industrie: 'teal.100',
    Business: 'yellow.100',
    Littérature: 'orange.100',
    'Non spécifié': 'gray.100',
    // Ajoutez d'autres domaines et couleurs si nécessaire
  };

  const domaineColorsDark = {
    Informatique: 'blue.700',
    Santé: 'green.700',
    Art: 'pink.700',
    Enseignement: 'purple.700',
    Industrie: 'teal.700',
    Business: 'yellow.700',
    Littérature: 'orange.700',
    'Non spécifié': 'gray.700',
    // Ajoutez d'autres domaines et couleurs si nécessaire
  };

  const domaineColors = useColorModeValue(domaineColorsLight, domaineColorsDark);

  // Variables de thème
  const cardBg = useColorModeValue('white', 'gray.700'); // Remplacé par couleur basée sur le domaine
  const alertBg = useColorModeValue('red.50', 'orange.900');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const subHeadingColor = useColorModeValue('teal.500', 'teal.200');
  const tagColor = useColorModeValue('orange.100', 'yellow.800');
  const financeurBg = useColorModeValue('purple.50', 'purple.800');
  const linkColor = useColorModeValue('teal.600', 'teal.300');
  const linkBg = useColorModeValue('teal.50', 'teal.800');
  const epreuveBg = useColorModeValue('gray.50', 'gray.600');

  // Fonction de navigation pour l'admin
  const handleAdminLogin = () => {
    navigate('/admin/login');
  };

  const handleAidesFormation = () => {
    navigate('/aides-formation'); // Assurez-vous que cette route existe dans votre application
  };

  // Fonction de récupération des détails de la formation
  const fetchFormationDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Créer une requête pour récupérer uniquement les formations actives
      const formationsQuery = query(
        collection(db, 'formations'),
        where('isActive', '==', true) // Filtrer les formations actives
      );
      const formationsSnapshot = await getDocs(formationsQuery);
      const formationsList = formationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Récupérer les domaines
      const domainesCol = collection(db, 'ficheMetiers');
      const domainesSnapshot = await getDocs(domainesCol);
      const domainesList = domainesSnapshot.docs.map((doc) => ({
        id: doc.id,
        domaine: doc.data().domaine,
      }));
      setDomaines(domainesList);

      // Créer une map de codeMetierId à domaine
      const codeMetierToDomaineMap = {};
      domainesList.forEach((dom) => {
        codeMetierToDomaineMap[dom.id] = dom.domaine;
      });

      // Enrichir les formations avec leur domaine métier
      const formationsEnriched = formationsList.map((formation) => ({
        ...formation,
        domaine: formation.codeMetierId
          ? codeMetierToDomaineMap[formation.codeMetierId] || 'Non spécifié'
          : 'Non spécifié',
      }));

      setFormations(formationsEnriched);
      setFilteredFormations(formationsEnriched);

      // Organiser les formations par domaine
      const formationsGrouped = {};
      formationsEnriched.forEach((formation) => {
        const domaine = formation.domaine || 'Non spécifié';
        if (!formationsGrouped[domaine]) {
          formationsGrouped[domaine] = [];
        }
        formationsGrouped[domaine].push(formation);
      });
      setFormationsByDomaine(formationsGrouped);

      // Récupérer les examens/tests
      const examsCol = collection(db, 'exams');
      const examsSnapshot = await getDocs(examsCol);
      const examsList = examsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setExams(examsList);
      setFilteredExams(examsList);
    } catch (err) {
      console.error('Erreur lors de la récupération des données:', err);
      setError('Impossible de charger les données. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFormationDetails();
  }, [fetchFormationDetails]);

  // Filtrage basé sur les filtres et le tri
  useEffect(() => {
    let filtered = formations;

    // Filtrage par domaines sélectionnés
    if (selectedDomaines.length > 0) {
      filtered = filtered.filter((formation) =>
        selectedDomaines.includes(formation.domaine)
      );
    }

    // Tri des formations
    if (sortOption === 'title') {
      filtered.sort((a, b) =>
        a.title.localeCompare(b.title, 'fr', { ignorePunctuation: true })
      );
    } else if (sortOption === 'date') {
      filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    setFilteredFormations(filtered);

    // Calcul du nombre total de pages pour les formations
    setTotalPagesFormations(Math.ceil(filtered.length / itemsPerPageFormations));
    setCurrentPageFormations(1); // Réinitialiser à la première page lors du filtrage ou tri

    // Réorganiser les formations filtrées par domaine
    const formationsGrouped = {};
    filtered.forEach((formation) => {
      const domaine = formation.domaine || 'Non spécifié';
      if (!formationsGrouped[domaine]) {
        formationsGrouped[domaine] = [];
      }
      formationsGrouped[domaine].push(formation);
    });
    setFormationsByDomaine(formationsGrouped);

    // Réinitialiser l'indice de l'onglet de domaine actif
    setSubTabIndex(0);
  }, [formations, sortOption, selectedDomaines, itemsPerPageFormations]);

  useEffect(() => {
    let filteredExams = exams;

    // Calcul du nombre total de pages pour les examens
    setTotalPagesExams(Math.ceil(filteredExams.length / itemsPerPageExams));
    setCurrentPageExams(1); // Réinitialiser à la première page lors du filtrage
    setFilteredExams(filteredExams);
  }, [exams, itemsPerPageExams]);

  // Gestion du changement de page pour les formations
  const handlePageChangeFormations = (direction) => {
    if (direction === 'next' && currentPageFormations < totalPagesFormations) {
      setCurrentPageFormations(currentPageFormations + 1);
    } else if (direction === 'prev' && currentPageFormations > 1) {
      setCurrentPageFormations(currentPageFormations - 1);
    }
  };

  // Gestion du changement de page pour les examens
  const handlePageChangeExams = (direction) => {
    if (direction === 'next' && currentPageExams < totalPagesExams) {
      setCurrentPageExams(currentPageExams + 1);
    } else if (direction === 'prev' && currentPageExams > 1) {
      setCurrentPageExams(currentPageExams - 1);
    }
  };

  // Gestion du changement de tri
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  // Gestion des filtres de domaines
  const handleDomainFilterChange = (domaine) => {
    if (selectedDomaines.includes(domaine)) {
      setSelectedDomaines(selectedDomaines.filter((dom) => dom !== domaine));
    } else {
      setSelectedDomaines([...selectedDomaines, domaine]);
    }
  };

  // Palette de couleurs
  const headerOverlayBg = 'rgba(255, 165, 0, 0.6)'; // Couleur orange semi-transparente

  // Obtenir les domaines disponibles après filtrage
  const availableDomains = Object.keys(formationsByDomaine);

  // Calculer les formations à afficher pour la page actuelle
  const indexOfLastFormation = currentPageFormations * itemsPerPageFormations;
  const indexOfFirstFormation = indexOfLastFormation - itemsPerPageFormations;
  const currentFormations = filteredFormations.slice(
    indexOfFirstFormation,
    indexOfLastFormation
  );

  // Calculer les examens à afficher pour la page actuelle
  const indexOfLastExam = currentPageExams * itemsPerPageExams;
  const indexOfFirstExam = indexOfLastExam - itemsPerPageExams;
  const currentExams = filteredExams.slice(indexOfFirstExam, indexOfLastExam);

  // Récupérer la taille de l'écran pour des composants réactifs
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box maxW="7xl" mx="auto" mt="10" p="5">
      {/* Section en Haut de la Page : Nom du Site et Cercles */}
      <Flex
        align="center"
        mb="6"
        justify="flex-start"
        direction="column"
      >
        {/* Nom du Site */}
        <Text
          fontFamily="'Playlist Script', cursive"
          fontSize={['2xl', '3xl', '4xl']}
          fontWeight="bold"
          mb="2"
          align="left"
        >
          Ma formation
        </Text>
        {/* Cercles Alignés Horizontalement */}
        <HStack spacing="3">
          <Circle size="15px" bg="#78b159" />
          <Circle size="15px" bg="#55acee" />
          <Circle size="15px" bg="#ff914d" />
        </HStack>
      </Flex>

      {/* Header Principal avec Image de Fond */}
      <Box
        position="relative"
        mb="10"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="xl"
        backgroundImage="url('/enTete.webp')"
        backgroundSize="cover"
        backgroundPosition="bottom" // Affiche le bas de l'image
        height="200px"
      >
        {/* Superposition pour améliorer la lisibilité du texte */}
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg={headerOverlayBg}
        />

        {/* Contenu de l'en-tête */}
        <Flex
          position="relative"
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
          px="6"
          color="white"
          textAlign="center"
          zIndex="1" // Assure que le contenu est au-dessus de la superposition
        >
          {/* Titre et Description au Centre */}
          <Box textAlign="center" flex="1">
            <Heading as="h1" size="2xl" mb="4" textShadow="2px 2px #000">
              Espace Formation
            </Heading>
            <Text fontSize="xl" textShadow="1px 1px #000">
              Explorez un large choix de formations.
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Nouvelle Section : Bouton Carte "Rechercher les aides à la formation" */}
      <Box mb="10">
        <Box
          bg="blue.50"
          p="6"
          borderRadius="md"
          boxShadow="md"
          cursor="pointer"
          onClick={handleAidesFormation}
          _hover={{
            transform: 'scale(1.05)',
            transition: 'transform 0.3s',
            bg: 'blue.100',
          }}
        >
          <Flex justify="center" align="center" direction={['column', 'row']}>
            <Icon as={MdHelpOutline} w={12} h={12} color="blue.500" mr={[0, 6]} mb={[4, 0]} />
            <Box textAlign={['center', 'left']}>
              <Heading as="h2" size="lg" color="blue.600">
                Rechercher les aides à la formation
              </Heading>
              <Text color="blue.500" mt="3">
                Trouvez les aides financières disponibles pour votre formation.
              </Text>
              <Button mt="4" colorScheme="blue" size="md" rightIcon={<MdHelpOutline />}>
                Aller aux Aides
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* Titre et Bouton Connexion Admin */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb="6"
        flexDirection={['column', 'row']}
      >
        <Heading as="h1" size="xl" color="orange.700" mb={[4, 0]}>
          Catalogue de Formations
        </Heading>
        <Button
          onClick={handleAdminLogin}
          colorScheme="orange"
          leftIcon={<MdAdminPanelSettings />}
          size="lg"
          _hover={{ bg: 'orange.600' }}
        >
          Connexion Admin
        </Button>
      </Flex>

      {/* Options de Tri et Filtres */}
      <Stack spacing="4" mb="6">
        <Flex justify="space-between" align="center" direction={['column', 'row']}>
          <Select
            width="200px"
            value={sortOption}
            onChange={handleSortChange}
            placeholder="Trier par"
            mb={[4, 0]}
          >
            <option value="title">Titre</option>
            <option value="date">Date</option>
          </Select>

          {/* Filtres de Domaines */}
          <Button
            leftIcon={<MdFilterList />}
            onClick={() => setShowFilters(!showFilters)}
            colorScheme="orange"
            variant={showFilters ? 'solid' : 'outline'}
            _hover={{ bg: 'orange.600', color: 'white' }}
          >
            {isMobile ? <MdFilterList /> : 'Filtres'}
          </Button>
        </Flex>

        {/* Section des Filtres */}
        {showFilters && (
          <SimpleGrid columns={[2, 3, 4, 6]} spacing={4}>
            {domaines.map((dom, idx) => (
              <Button
                key={idx}
                variant={selectedDomaines.includes(dom.domaine) ? 'solid' : 'outline'}
                colorScheme="orange"
                leftIcon={<Icon as={domaineIcons[dom.domaine] || FaBriefcase} />}
                onClick={() => handleDomainFilterChange(dom.domaine)}
                size="sm"
                _hover={{
                  bg: selectedDomaines.includes(dom.domaine) ? 'orange.600' : 'orange.100',
                  color: selectedDomaines.includes(dom.domaine) ? 'white' : 'orange.600',
                }}
              >
                {dom.domaine}
              </Button>
            ))}
          </SimpleGrid>
        )}
      </Stack>

      {/* Contenu Principal avec Animations */}
      {isLoading ? (
        <Center mt="10">
          <Spinner size="xl" color="orange.500" />
        </Center>
      ) : error ? (
        <Alert status="error" mt="10" borderRadius="md" bg={alertBg} boxShadow="md">
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <Tabs variant="enclosed" colorScheme="orange" mt="6">
          <TabList>
            <Tab>Formations</Tab>
            <Tab>Examens et Tests</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Liste des Formations avec Pagination */}
              {currentFormations.length === 0 ? (
                <Text fontSize="lg" color="gray.600">
                  Aucune formation trouvée.
                </Text>
              ) : (
                <>
                  <Grid
                    templateColumns={[
                      '1fr',
                      'repeat(2, 1fr)',
                      'repeat(3, 1fr)',
                      'repeat(4, 1fr)',
                    ]}
                    gap={6}
                  >
                    {currentFormations.map((formation) => (
                      <Link
                        to={`/formation/${formation.id}`}
                        key={formation.id}
                        style={{ textDecoration: 'none' }}
                      >
                        <Box
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          bg={domaineColors[formation.domaine] || domaineColors['Non spécifié']}
                          boxShadow="lg"
                          _hover={{
                            boxShadow: '2xl',
                            transform: 'scale(1.05)',
                            transition: 'all 0.3s',
                          }}
                          cursor="pointer"
                        >
                          {formation.imageUrl &&
                            typeof formation.imageUrl === 'string' && (
                              <Image
                                src={formation.imageUrl}
                                alt={
                                  typeof formation.title === 'string'
                                    ? formation.title
                                    : 'Formation'
                                }
                                height="200px"
                                width="100%"
                                objectFit="cover"
                              />
                            )}
                          <Box p="6">
                            {formation.codeRNCP &&
                              typeof formation.codeRNCP === 'string' && (
                                <Tag
                                  borderRadius="full"
                                  px="2"
                                  colorScheme="orange"
                                  mb="2"
                                >
                                  RNCP: {formation.codeRNCP}
                                </Tag>
                              )}
                            <Heading
                              as="h3"
                              size="md"
                              mt="2"
                              mb="2"
                              color="orange.600"
                            >
                              {typeof formation.title === 'string'
                                ? formation.title
                                : 'Titre non disponible'}
                            </Heading>
                            {/* Description supprimée */}

                            {/* Compétences supprimées */}

                            {formation.volumeAnnuel &&
                              typeof formation.volumeAnnuel === 'number' && (
                                <Flex align="center" mt="4">
                                  <Icon
                                    as={MdAccessTime}
                                    w={5}
                                    h={5}
                                    color="gray.500"
                                    mr="2"
                                  />
                                  <Text fontSize="sm" color="gray.600">
                                    Volume Annuel : {formation.volumeAnnuel} heures
                                  </Text>
                                </Flex>
                              )}
                          </Box>
                        </Box>
                      </Link>
                    ))}
                  </Grid>

                  {/* Pagination */}
                  <Flex justify="center" align="center" mt="6">
                    <Button
                      onClick={() => handlePageChangeFormations('prev')}
                      isDisabled={currentPageFormations === 1}
                      leftIcon={<MdKeyboardArrowLeft />}
                      variant="outline"
                      colorScheme="orange"
                      mr="2"
                    >
                      Précédent
                    </Button>
                    <Text>
                      Page {currentPageFormations} sur {totalPagesFormations}
                    </Text>
                    <Button
                      onClick={() => handlePageChangeFormations('next')}
                      isDisabled={currentPageFormations === totalPagesFormations}
                      rightIcon={<MdKeyboardArrowRight />}
                      variant="outline"
                      colorScheme="orange"
                      ml="2"
                    >
                      Suivant
                    </Button>
                  </Flex>
                </>
              )}
            </TabPanel>
            <TabPanel>
              {/* Section Examens et Tests avec Pagination */}
              {currentExams.length === 0 ? (
                <Text fontSize="lg" color="gray.600">
                  Aucun examen/test trouvé.
                </Text>
              ) : (
                <>
                  <Grid
                    templateColumns={[
                      '1fr',
                      'repeat(2, 1fr)',
                      'repeat(3, 1fr)',
                      'repeat(4, 1fr)',
                    ]}
                    gap={6}
                  >
                    {currentExams.map((exam) => (
                      <Link
                        to={`/exam/${exam.id}`}
                        key={exam.id}
                        style={{ textDecoration: 'none' }}
                      >
                        <Box
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          bg={cardBg}
                          boxShadow="lg"
                          _hover={{
                            boxShadow: '2xl',
                            transform: 'scale(1.05)',
                            transition: 'all 0.3s',
                          }}
                          cursor="pointer"
                        >
                          <Box p="6">
                            <Heading as="h3" size="md" color="yellow.600" mb="2">
                              {typeof exam.name === 'string'
                                ? exam.name
                                : 'Nom non disponible'}
                            </Heading>
                            <Text fontSize="sm" color="gray.600" mb="4">
                              Type : {exam.type || 'Type non spécifié'}
                            </Text>
                            <Text fontSize="sm" color="gray.700" mb="4">
                              {exam.description || 'Description non disponible'}
                            </Text>

                            <Accordion allowToggle>
                              {exam.epreuves && exam.epreuves.length > 0 && (
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Box flex="1" textAlign="left">
                                        Épreuves
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    <VStack align="start" spacing={1}>
                                      {exam.epreuves.map((epreuve, index) => (
                                        <Box
                                          key={index}
                                          p={2}
                                          borderWidth="1px"
                                          borderRadius="md"
                                          width="full"
                                          bg={epreuveBg}
                                          boxShadow="sm"
                                          _hover={{ bg: 'yellow.100' }}
                                          transition="background 0.2s"
                                        >
                                          <Text>
                                            <strong>Nom :</strong> {epreuve.nom || `Épreuve ${index + 1}`}
                                          </Text>
                                          <Text>
                                            <strong>Description :</strong> {epreuve.description || 'N/A'}
                                          </Text>
                                        </Box>
                                      ))}
                                    </VStack>
                                  </AccordionPanel>
                                </AccordionItem>
                              )}
                              {exam.tests && exam.tests.length > 0 && (
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Box flex="1" textAlign="left">
                                        Tests
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    <VStack align="start" spacing={1}>
                                      {exam.tests.map((test, index) => (
                                        <Text key={index}>- {test.nom}</Text>
                                      ))}
                                    </VStack>
                                  </AccordionPanel>
                                </AccordionItem>
                              )}
                            </Accordion>
                          </Box>
                        </Box>
                      </Link>
                    ))}
                  </Grid>

                  {/* Pagination */}
                  <Flex justify="center" align="center" mt="6">
                    <Button
                      onClick={() => handlePageChangeExams('prev')}
                      isDisabled={currentPageExams === 1}
                      leftIcon={<MdKeyboardArrowLeft />}
                      variant="outline"
                      colorScheme="orange"
                      mr="2"
                    >
                      Précédent
                    </Button>
                    <Text>
                      Page {currentPageExams} sur {totalPagesExams}
                    </Text>
                    <Button
                      onClick={() => handlePageChangeExams('next')}
                      isDisabled={currentPageExams === totalPagesExams}
                      rightIcon={<MdKeyboardArrowRight />}
                      variant="outline"
                      colorScheme="orange"
                      ml="2"
                    >
                      Suivant
                    </Button>
                  </Flex>
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {/* Footer */}
      <Box mt="12" py="6" borderTopWidth="1px" borderColor="gray.200">
        <Flex justify="space-between" align="center" flexDirection={['column', 'row']}>
          <Text fontSize="sm" color="gray.600">
            © {new Date().getFullYear()} Espace Formation. Tous droits réservés.
          </Text>
          <HStack spacing="4" mt={[4, 0]}>
            <Link to="/about">
              <Text fontSize="sm" color="gray.600" _hover={{ color: 'orange.500' }}>
                À propos
              </Text>
            </Link>
            <Link to="/contact">
              <Text fontSize="sm" color="gray.600" _hover={{ color: 'orange.500' }}>
                Contact
              </Text>
            </Link>
            <Link to="/privacy">
              <Text fontSize="sm" color="gray.600" _hover={{ color: 'orange.500' }}>
                Politique de confidentialité
              </Text>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default HomePage;
