// src/admin/pages/FicheMetiers.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormLabel,
  VStack,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Select as ChakraSelect,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Center,
  Text,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { db } from '../../firebase'; // Assurez-vous que firebase.js exporte 'db'
import {
  serverTimestamp,
  collection,
  query,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { MdAdd, MdClose, MdEdit } from 'react-icons/md';

const FicheMetiers = () => {
  const [ficheMetiers, setFicheMetiers] = useState([]);
  const [domaines, setDomaines] = useState([]);
  const [code, setCode] = useState('');
  const [lienExterne, setLienExterne] = useState('');
  const [domaine, setDomaine] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // États pour le modal d'ajout de domaine
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newDomaine, setNewDomaine] = useState('');
  const [loadingDomaine, setLoadingDomaine] = useState(false);
  const [errorDomaine, setErrorDomaine] = useState('');
  const [successDomaine, setSuccessDomaine] = useState('');

  // États pour le modal de modification
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [editFicheId, setEditFicheId] = useState(null);
  const [editCode, setEditCode] = useState('');
  const [editLienExterne, setEditLienExterne] = useState('');
  const [editDomaine, setEditDomaine] = useState('');
  const [editError, setEditError] = useState('');
  const [editSuccess, setEditSuccess] = useState('');
  const [editLoading, setEditLoading] = useState(false);

  // Fonction pour récupérer les fiches métiers
  const fetchFicheMetiers = useCallback(async () => {
    try {
      const q = query(
        collection(db, 'ficheMetiers'),
        orderBy('createdAt', 'desc')
      );
      const snapshot = await getDocs(q);
      const fetchedFicheMetiers = snapshot.docs.map((doc) => ({
        codeMetiersId: doc.id,
        code: doc.data().code,
        lienExterne: doc.data().lienExterne,
        domaine: doc.data().domaine || 'Non spécifié',
        createdAt: doc.data().createdAt,
      }));
      setFicheMetiers(fetchedFicheMetiers);
    } catch (error) {
      console.error('Erreur lors de la récupération des fiches métiers:', error);
      setError('Impossible de récupérer les fiches métiers.');
    }
  }, []);

  // Fonction pour récupérer les domaines de métier
  const fetchDomaines = useCallback(async () => {
    try {
      const q = query(
        collection(db, 'domainesMetier'),
        orderBy('nom', 'asc')
      );
      const snapshot = await getDocs(q);
      const fetchedDomaines = snapshot.docs.map((doc) => ({
        id: doc.id,
        nom: doc.data().nom,
      }));
      setDomaines(fetchedDomaines);
    } catch (error) {
      console.error('Erreur lors de la récupération des domaines de métier:', error);
      setError('Impossible de récupérer les domaines de métier.');
    }
  }, []);

  useEffect(() => {
    fetchFicheMetiers();
    fetchDomaines();
  }, [fetchFicheMetiers, fetchDomaines]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validation des champs
    if (!code.trim()) {
      setError('Le code métier est requis.');
      return;
    }
    if (!domaine) {
      setError('Le domaine de métier est requis.');
      return;
    }

    // Vérifier si le code existe déjà
    const codeExiste = ficheMetiers.some(
      (fiche) => fiche.code.toLowerCase() === code.trim().toLowerCase()
    );
    if (codeExiste) {
      setError('Ce code métier existe déjà.');
      return;
    }

    setLoading(true);

    try {
      await addDoc(collection(db, 'ficheMetiers'), {
        code: code.trim(),
        lienExterne: lienExterne.trim() || null,
        domaine,
        createdAt: serverTimestamp(),
      });

      setSuccess('Fiche métier ajoutée avec succès.');
      setCode('');
      setLienExterne('');
      setDomaine('');

      // Récupérer les fiches métiers mises à jour
      fetchFicheMetiers();
    } catch (err) {
      console.error("Erreur lors de l'ajout de la fiche métier:", err);
      setError("Une erreur est survenue lors de l'ajout de la fiche métier.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddDomaine = async (e) => {
    e.preventDefault();
    setErrorDomaine('');
    setSuccessDomaine('');

    // Validation de base
    if (!newDomaine.trim()) {
      setErrorDomaine('Le nom du domaine est requis.');
      return;
    }

    // Vérifier si le domaine existe déjà
    const domaineExiste = domaines.some(
      (dom) => dom.nom.toLowerCase() === newDomaine.trim().toLowerCase()
    );
    if (domaineExiste) {
      setErrorDomaine('Ce domaine de métier existe déjà.');
      return;
    }

    setLoadingDomaine(true);

    try {
      const docRef = await addDoc(collection(db, 'domainesMetier'), {
        nom: newDomaine.trim(),
        createdAt: serverTimestamp(),
      });

      setSuccessDomaine('Domaine de métier ajouté avec succès.');
      setNewDomaine('');

      // Récupérer les domaines mis à jour
      fetchDomaines();
    } catch (err) {
      console.error('Erreur lors de l\'ajout du domaine de métier:', err);
      setErrorDomaine('Une erreur est survenue lors de l\'ajout du domaine de métier.');
    } finally {
      setLoadingDomaine(false);
    }
  };

  // Fonction pour ouvrir le modal de modification avec les données de la fiche sélectionnée
  const handleEditClick = (fiche) => {
    setEditFicheId(fiche.codeMetiersId);
    setEditCode(fiche.code);
    setEditLienExterne(fiche.lienExterne || '');
    setEditDomaine(fiche.domaine);
    setEditError('');
    setEditSuccess('');
    onEditOpen();
  };

  // Fonction pour gérer la modification d'une fiche métier
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setEditError('');
    setEditSuccess('');

    // Validation des champs
    if (!editCode.trim()) {
      setEditError('Le code métier est requis.');
      return;
    }
    if (!editDomaine) {
      setEditError('Le domaine de métier est requis.');
      return;
    }

    // Vérifier si le code existe déjà (sauf pour la fiche en cours de modification)
    const codeExiste = ficheMetiers.some(
      (fiche) =>
        fiche.code.toLowerCase() === editCode.trim().toLowerCase() &&
        fiche.codeMetiersId !== editFicheId
    );
    if (codeExiste) {
      setEditError('Ce code métier existe déjà.');
      return;
    }

    setEditLoading(true);

    try {
      const ficheRef = doc(db, 'ficheMetiers', editFicheId);
      await updateDoc(ficheRef, {
        code: editCode.trim(),
        lienExterne: editLienExterne.trim() || null,
        domaine: editDomaine,
        // Optionnel : mise à jour de la date de modification
        updatedAt: serverTimestamp(),
      });

      setEditSuccess('Fiche métier mise à jour avec succès.');

      // Rafraîchir les fiches métiers
      fetchFicheMetiers();
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la fiche métier:", err);
      setEditError("Une erreur est survenue lors de la mise à jour de la fiche métier.");
    } finally {
      setEditLoading(false);
    }
  };

  return (
    <Box maxW="600px" mx="auto" mt={10} p={5} borderWidth="1px" borderRadius="lg">
      {/* Formulaire d'ajout */}
      <Box mb={6}>
        <FormLabel fontSize="lg" fontWeight="bold">
          Ajouter une Nouvelle Fiche Métier
        </FormLabel>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl isRequired isInvalid={error && !code}>
              <FormLabel>Code Métier</FormLabel>
              <Input
                placeholder="Entrez le code métier"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <FormErrorMessage>Le code métier est requis.</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={error && !domaine}>
              <FormLabel>Domaine de Métier</FormLabel>
              <Flex>
                <ChakraSelect
                  placeholder="Sélectionnez un domaine"
                  value={domaine}
                  onChange={(e) => setDomaine(e.target.value)}
                >
                  {domaines.map((dom) => (
                    <option key={dom.id} value={dom.nom}>
                      {dom.nom}
                    </option>
                  ))}
                </ChakraSelect>
                <Button
                  leftIcon={<MdAdd />}
                  colorScheme="green"
                  ml={2}
                  onClick={onOpen}
                  title="Ajouter un nouveau domaine de métier"
                >
                  Ajouter
                </Button>
              </Flex>
              {error && !domaine && (
                <FormErrorMessage>Le domaine de métier est requis.</FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Lien Externe</FormLabel>
              <Input
                placeholder="Entrez le lien externe (optionnel)"
                value={lienExterne}
                onChange={(e) => setLienExterne(e.target.value)}
              />
            </FormControl>

            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}

            {success && (
              <Alert status="success">
                <AlertIcon />
                {success}
              </Alert>
            )}

            <Button colorScheme="teal" type="submit" isLoading={loading}>
              Ajouter la Fiche Métier
            </Button>
          </VStack>
        </form>
      </Box>

      {/* Liste des fiches métiers */}
      <Box>
        <FormLabel fontSize="lg" fontWeight="bold">
          Fiches Métiers Existantes:
        </FormLabel>
        <VStack spacing={2} align="stretch">
          {ficheMetiers.length > 0 ? (
            ficheMetiers.map((fiche) => (
              <Box key={fiche.codeMetiersId} p={4} borderWidth="1px" borderRadius="md" position="relative">
                {/* Bouton de modification */}
                <IconButton
                  icon={<MdEdit />}
                  colorScheme="blue"
                  variant="ghost"
                  size="sm"
                  position="absolute"
                  top="4px"
                  right="4px"
                  onClick={() => handleEditClick(fiche)}
                  aria-label="Modifier Fiche Métier"
                />

                <Text>
                  <strong>Code :</strong> {fiche.code}
                </Text>
                <Text>
                  <strong>Domaine :</strong> {fiche.domaine}
                </Text>
                <Text>
                  <strong>Date de Création :</strong>{' '}
                  {fiche.createdAt
                    ? new Date(fiche.createdAt.seconds * 1000).toLocaleString('fr-FR')
                    : 'N/A'}
                </Text>
                {fiche.lienExterne && (
                  <Text>
                    <strong>Lien Externe :</strong>{' '}
                    <a href={fiche.lienExterne} target="_blank" rel="noopener noreferrer">
                      {fiche.lienExterne}
                    </a>
                  </Text>
                )}
              </Box>
            ))
          ) : (
            <Box p={4} borderWidth="1px" borderRadius="md">
              Aucune fiche métier n'est disponible actuellement.
            </Box>
          )}
        </VStack>
      </Box>

      {/* Modal pour ajouter un nouveau domaine de métier */}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setErrorDomaine('');
          setSuccessDomaine('');
          setNewDomaine('');
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Domaine de Métier</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleAddDomaine}>
            <ModalBody>
              <FormControl isRequired isInvalid={errorDomaine && !newDomaine}>
                <FormLabel>Nom du Domaine</FormLabel>
                <Input
                  placeholder="Entrez le nom du domaine de métier"
                  value={newDomaine}
                  onChange={(e) => setNewDomaine(e.target.value)}
                />
                {errorDomaine && !newDomaine && (
                  <FormErrorMessage>Le nom du domaine est requis.</FormErrorMessage>
                )}
              </FormControl>

              {errorDomaine && (
                <Alert status="error" mt={4}>
                  <AlertIcon />
                  {errorDomaine}
                </Alert>
              )}

              {successDomaine && (
                <Alert status="success" mt={4}>
                  <AlertIcon />
                  {successDomaine}
                </Alert>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                mr={3}
                type="submit"
                isLoading={loadingDomaine}
              >
                Ajouter
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  setErrorDomaine('');
                  setSuccessDomaine('');
                  setNewDomaine('');
                  onClose();
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* Modal pour modifier une fiche métier */}
      <Modal
        isOpen={isEditOpen}
        onClose={() => {
          setEditError('');
          setEditSuccess('');
          setEditCode('');
          setEditLienExterne('');
          setEditDomaine('');
          setEditFicheId(null);
          onEditClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier la Fiche Métier</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleEditSubmit}>
            <ModalBody>
              <FormControl isRequired isInvalid={editError && !editCode}>
                <FormLabel>Code Métier</FormLabel>
                <Input
                  placeholder="Entrez le code métier"
                  value={editCode}
                  onChange={(e) => setEditCode(e.target.value)}
                />
                <FormErrorMessage>Le code métier est requis.</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={editError && !editDomaine} mt={4}>
                <FormLabel>Domaine de Métier</FormLabel>
                <ChakraSelect
                  placeholder="Sélectionnez un domaine"
                  value={editDomaine}
                  onChange={(e) => setEditDomaine(e.target.value)}
                >
                  {domaines.map((dom) => (
                    <option key={dom.id} value={dom.nom}>
                      {dom.nom}
                    </option>
                  ))}
                </ChakraSelect>
                {editError && !editDomaine && (
                  <FormErrorMessage>Le domaine de métier est requis.</FormErrorMessage>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Lien Externe</FormLabel>
                <Input
                  placeholder="Entrez le lien externe (optionnel)"
                  value={editLienExterne}
                  onChange={(e) => setEditLienExterne(e.target.value)}
                />
              </FormControl>

              {editError && (
                <Alert status="error" mt={4}>
                  <AlertIcon />
                  {editError}
                </Alert>
              )}

              {editSuccess && (
                <Alert status="success" mt={4}>
                  <AlertIcon />
                  {editSuccess}
                </Alert>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                mr={3}
                type="submit"
                isLoading={editLoading}
              >
                Enregistrer
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  setEditError('');
                  setEditSuccess('');
                  setEditCode('');
                  setEditLienExterne('');
                  setEditDomaine('');
                  setEditFicheId(null);
                  onEditClose();
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FicheMetiers;
