// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDmiSnksZJvjFR4oqPexAzPChOfv0c31ME",
  authDomain: "maformation-27f98.firebaseapp.com",
  projectId: "maformation-27f98",
  storageBucket: "maformation-27f98.appspot.com",
  messagingSenderId: "95549261258",
  appId: "1:95549261258:web:17c568b2415d1d7edcbb33",
  measurementId: "G-L8PM5XF59E"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
