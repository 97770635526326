// src/public/pages/AidesView.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Text,
  SimpleGrid,
  Tag,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  Button,
  Tooltip,
  IconButton,
  useColorMode,
  useColorModeValue,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Avatar,
  Image,
} from '@chakra-ui/react';
import { MdWbSunny, MdNightsStay, MdWarning } from 'react-icons/md';
import { FaUniversity, FaRegLightbulb } from 'react-icons/fa';
import { db } from '../../firebase';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import { motion } from 'framer-motion';

// Composant animé avec Framer Motion
const MotionBox = motion(Box);

// Définition des couleurs pour les types d’aides
const typeColors = {
  Bourse: {
    light: 'yellow.100',
    dark: 'yellow.700',
    text: 'yellow.800',
  },
  Subvention: {
    light: 'green.100',
    dark: 'green.700',
    text: 'green.800',
  },
  Prêt: {
    light: 'red.100',
    dark: 'red.700',
    text: 'red.800',
  },
  Autre: {
    light: 'purple.100',
    dark: 'purple.700',
    text: 'purple.800',
  },
};

// Composant pour afficher les financeurs avec logo
const FinanceurTags = ({ financeurIds, financeurs }) => {
  const tagBg = useColorModeValue('green.100', 'green.700');
  const tagColor = useColorModeValue('green.800', 'white');

  return (
    <Flex wrap="wrap" gap="2">
      {financeurIds.map((financeurId) => {
        const financeur = financeurs.find((f) => f.id === financeurId);
        return financeur ? (
          <Tag
            key={financeur.id}
            borderRadius="full"
            px="3"
            py="1"
            colorScheme="green"
            bg={tagBg}
            color={tagColor}
            display="flex"
            alignItems="center"
            gap="2"
          >
            <Avatar
              size="xs"
              src={financeur.logo || ''}
              name={financeur.nom}
              bg="gray.200"
              icon={<FaUniversity />}
            />
            {financeur.nom}
          </Tag>
        ) : null;
      })}
    </Flex>
  );
};

// Composant pour la Modal d'Avertissement
const WarningModal = ({ isOpen, onClose, aide }) => {
  return (
    aide && (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aide Inaccessible</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex align="center">
              <MdWarning size="24px" color="red" />
              <Text ml="2">
                Cette aide est active mais aucune demande ne peut être faite par vous ou l'organisme de formation pour le moment.
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

// Composant pour afficher une seule aide sous forme de carte
const AideCard = ({ aide, financeurs, onAideClick, onWarningClick }) => {
  // Appel non conditionnel des hooks
  const cardBg = useColorModeValue(
    typeColors[aide.type]?.light || 'gray.100',
    typeColors[aide.type]?.dark || 'gray.600'
  );
  const textColor = useColorModeValue(
    typeColors[aide.type]?.text || 'gray.800',
    typeColors[aide.type]?.text || 'white'
  );
  const defaultIconColor = useColorModeValue('gray.400', 'gray.300');
  const fallbackBg = useColorModeValue('gray.100', 'gray.600');

  return (
    <MotionBox
      bg={cardBg}
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
      cursor="pointer"
      whileHover={{ scale: 1.02, boxShadow: 'xl' }}
      transition={{ duration: 0.3 }}
      onClick={() => onAideClick(aide.id)}
    >
      {/* Image de l'Aide */}
      {aide.imageURL ? (
        <Image src={aide.imageURL} alt={aide.title} objectFit="cover" height="200px" width="100%" />
      ) : (
        <Box
          bg={fallbackBg}
          height="200px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FaRegLightbulb size="50px" color={defaultIconColor} />
        </Box>
      )}

      {/* Contenu de la Carte */}
      <Box p="6">
        {/* Titre de l'Aide */}
        {aide.title && (
          <Heading as="h3" size="md" mb="2" color={textColor}>
            {aide.title}
          </Heading>
        )}

        {/* Financeurs Associés */}
        {aide.financeurs && aide.financeurs.length > 0 && (
          <FinanceurTags financeurIds={aide.financeurs} financeurs={financeurs} />
        )}

        {/* Statut et Budget */}
        <Flex align="center" justify="space-between" mt="4">
          <Tag colorScheme={aide.status === 'Actif' ? 'green' : 'red'}>
            {aide.status}
          </Tag>
          {!aide.hasBudget && (
            <Tag colorScheme="red">
              Pas de budget
            </Tag>
          )}
        </Flex>

        {/* Message d'Avertissement si pas de budget */}
        {aide.status === 'Actif' && !aide.hasBudget && (
          <Flex
            align="center"
            mt="4"
            onClick={(e) => { e.stopPropagation(); onWarningClick(aide); }}
          >
            <MdWarning color="red.500" size="24px" />
            <Text fontSize="sm" color="red.500" ml="2">
              Cette aide est active mais aucune demande ne peut être faite.
            </Text>
          </Flex>
        )}

        {/* Bouton pour Voir les Détails */}
        <Button
          mt="4"
          colorScheme="teal"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onAideClick(aide.id);
          }}
        >
          Voir les détails
        </Button>
      </Box>
    </MotionBox>
  );
};

const AidesView = () => {
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const [aides, setAides] = useState([]);
  const [filteredAides, setFilteredAides] = useState([]);
  const [financeurs, setFinanceurs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // États pour les filtres avancés
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedFinanceurs, setSelectedFinanceurs] = useState([]);

  // États pour la Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAide, setSelectedAide] = useState(null);

  // Appels non conditionnels des hooks
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  // Charger les financeurs existants depuis Firestore
  useEffect(() => {
    const fetchFinanceurs = async () => {
      try {
        const financeursCol = collection(db, 'financeurs');
        const snapshot = await getDocs(financeursCol);
        const financeursList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFinanceurs(financeursList);
      } catch (error) {
        console.error('Erreur lors du chargement des financeurs:', error);
      }
    };

    fetchFinanceurs();
  }, []);

  // Récupérer les aides en temps réel
  const fetchAides = useCallback(() => {
    setIsLoading(true);
    setError(null);
    try {
      const aidesCol = collection(db, 'aidesFormation');
      const unsubscribe = onSnapshot(
        aidesCol,
        (snapshot) => {
          const aidesList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAides(aidesList);
          setIsLoading(false);
        },
        (err) => {
          console.error('Erreur lors de la récupération des aides:', err);
          setError('Impossible de charger les aides. Veuillez réessayer plus tard.');
          setIsLoading(false);
        }
      );
      return () => unsubscribe();
    } catch (err) {
      console.error('Erreur:', err);
      setError('Une erreur est survenue. Veuillez réessayer plus tard.');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = fetchAides();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [fetchAides]);

  // Gestion des filtres
  useEffect(() => {
    let filtered = aides;

    if (selectedTypes.length > 0) {
      filtered = filtered.filter((aide) => selectedTypes.includes(aide.type));
    }

    if (selectedFinanceurs.length > 0) {
      filtered = filtered.filter(
        (aide) =>
          aide.financeurs && aide.financeurs.some((id) => selectedFinanceurs.includes(id))
      );
    }

    setFilteredAides(filtered);
  }, [aides, selectedTypes, selectedFinanceurs]);

  const handleAideClick = (id) => {
    navigate(`/aides-formation/${id}`);
  };

  const openWarningModal = (aide) => {
    setSelectedAide(aide);
    onOpen();
  };

  return (
    <Box
      maxW="7xl"
      mx="auto"
      mt="10"
      p={{ base: '4', md: '6' }}
      bg={bgColor}
    >
      {/* Bouton de Changement de Thème */}
      <Flex justify="flex-end" mb="4">
        <Tooltip
          label={colorMode === 'light' ? 'Passer en mode sombre' : 'Passer en mode clair'}
        >
          <IconButton
            aria-label="Toggle theme"
            icon={colorMode === 'light' ? <MdNightsStay /> : <MdWbSunny />}
            onClick={toggleColorMode}
            variant="ghost"
            size="lg"
          />
        </Tooltip>
      </Flex>

      {/* Header Animé avec Dégradé Coloré */}
      <MotionBox
        bgGradient={useColorModeValue(
          'linear(to-r, teal.400, blue.500)',
          'linear(to-r, teal.700, blue.800)'
        )}
        py="16"
        textAlign="center"
        color="white"
        mb="10"
        borderRadius="lg"
        boxShadow="xl"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Heading
          as="h1"
          size="2xl"
          mb="4"
          textShadow="2px 2px rgba(0,0,0,0.7)"
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="2"
          flexWrap="wrap"
        >
          <FaUniversity size="1.2em" /> Rechercher les Aides à la Formation
        </Heading>
        <Text fontSize="xl" textShadow="1px 1px rgba(0,0,0,0.5)">
          Trouvez les aides financières disponibles pour financer votre formation.
        </Text>
      </MotionBox>

      {/* Contenu Principal Simplifié */}
      {isLoading ? (
        <Center mt="10">
          <Spinner size="xl" color="teal.500" />
        </Center>
      ) : error ? (
        <Alert status="error" mt="10">
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <Box>
          {filteredAides.length === 0 ? (
            <Center mt="10">
              <Text fontSize="lg" color="gray.600">
                Aucune aide disponible.
              </Text>
            </Center>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="6">
              {filteredAides.map((aide) => (
                <AideCard
                  key={aide.id}
                  aide={aide}
                  financeurs={financeurs}
                  onAideClick={handleAideClick}
                  onWarningClick={openWarningModal}
                />
              ))}
            </SimpleGrid>
          )}
        </Box>
      )}

      {/* Modal pour le Message d'Avertissement */}
      <WarningModal isOpen={isOpen} onClose={onClose} aide={selectedAide} />
    </Box>
  );
};

export default AidesView;
