// src/admin/pages/Financeurs.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast,
  Flex,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  HStack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Image,
} from '@chakra-ui/react';
import {
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { getFirestore, collection, addDoc, updateDoc, deleteDoc, onSnapshot, doc } from 'firebase/firestore';
import { storage } from '../../firebase'; // Assurez-vous que Firebase est correctement configuré
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const Financeurs = () => {
  const [financeurs, setFinanceurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFinanceur, setSelectedFinanceur] = useState(null);

  const db = getFirestore();

  // Charger les financeurs existants depuis Firestore en temps réel
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'financeurs'),
      (snapshot) => {
        const financeursList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFinanceurs(financeursList);
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors du chargement des financeurs:', error);
        setError('Impossible de charger les financeurs. Veuillez réessayer plus tard.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db]);

  // Schéma de validation avec Yup
  const FinanceurSchema = Yup.object().shape({
    nom: Yup.string()
      .min(2, 'Le nom doit contenir au moins 2 caractères')
      .max(50, 'Le nom ne peut pas dépasser 50 caractères')
      .required('Le nom est requis'),
    description: Yup.string()
      .max(500, 'La description ne peut pas dépasser 500 caractères'),
    conditionsAcces: Yup.string()
      .required("Les conditions d'accès sont requises"),
    logo: Yup.mixed()
      .nullable()
      .test('fileSize', 'La taille du fichier est trop grande (max 2MB)', value => {
        if (!value) return true; // Le logo est optionnel
        return value.size <= 2 * 1024 * 1024; // 2MB
      })
      .test('fileType', 'Format de fichier invalide', value => {
        if (!value) return true;
        return ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(value.type);
      }),
  });

  // Fonction pour télécharger l'image et obtenir l'URL
  const uploadLogo = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `financeurs/${Date.now()}_${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  // Gestion de la soumission du formulaire pour ajouter un financeur
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      let logoUrl = null;
      if (values.logo) {
        logoUrl = await uploadLogo(values.logo);
      }

      const dataToSend = {
        nom: values.nom,
        description: values.description || '-', // Utiliser '-' si description est vide
        conditionsAcces: values.conditionsAcces,
        logo: logoUrl,
      };

      await addDoc(collection(db, 'financeurs'), dataToSend);
      toast({
        title: 'Financeur ajouté.',
        description: 'Le financeur a été ajouté avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      resetForm();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du financeur:', error);
      toast({
        title: 'Erreur.',
        description: 'Une erreur est survenue lors de l\'ajout du financeur.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Gestion de la soumission du formulaire pour modifier un financeur
  const handleUpdate = async (values, { setSubmitting }) => {
    try {
      let logoUrl = selectedFinanceur.logo; // Garder l'URL existante
      if (values.logo) {
        // Supprimer l'ancien logo si existe
        if (selectedFinanceur.logo) {
          const oldLogoRef = ref(storage, selectedFinanceur.logo);
          await deleteObject(oldLogoRef);
        }
        // Télécharger le nouveau logo
        logoUrl = await uploadLogo(values.logo);
      }

      const dataToSend = {
        nom: values.nom,
        description: values.description || '-', // Utiliser '-' si description est vide
        conditionsAcces: values.conditionsAcces,
        logo: logoUrl,
      };

      const financeurRef = doc(db, 'financeurs', selectedFinanceur.id);
      await updateDoc(financeurRef, dataToSend);
      toast({
        title: 'Financeur mis à jour.',
        description: 'Les informations du financeur ont été mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setSelectedFinanceur(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du financeur:', error);
      toast({
        title: 'Erreur.',
        description: 'Une erreur est survenue lors de la mise à jour du financeur.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Gestion de la suppression d'un financeur
  const handleDelete = async (financeur) => {
    const confirmDelete = window.confirm(`Êtes-vous sûr de vouloir supprimer le financeur "${financeur.nom}" ?`);
    if (!confirmDelete) return;

    try {
      // Supprimer le logo de Storage si existe
      if (financeur.logo) {
        const logoRef = ref(storage, financeur.logo);
        await deleteObject(logoRef);
      }

      // Supprimer le document de Firestore
      const financeurRef = doc(db, 'financeurs', financeur.id);
      await deleteDoc(financeurRef);

      toast({
        title: 'Financeur supprimé.',
        description: 'Le financeur a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du financeur:', error);
      toast({
        title: 'Erreur.',
        description: 'Une erreur est survenue lors de la suppression du financeur.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="7xl" mx="auto" mt="10" p="5">
      <Heading mb="6" color="teal.600" textAlign="center">
        Gestion des Financeurs
      </Heading>

      {/* Formulaire d'ajout de financeur */}
      <Box
        bg="white"
        p="6"
        borderRadius="lg"
        boxShadow="lg"
        mb="10"
        transition="all 0.3s ease"
        _hover={{ boxShadow: 'xl' }}
      >
        <Heading size="md" mb="4" color="blue.500">
          Ajouter un Financeur Potentiel
        </Heading>
        <Formik
          initialValues={{
            nom: '',
            description: '',
            conditionsAcces: 'Être demandeur d\'emploi', // Valeur par défaut
            logo: null, // Champ pour le logo
          }}
          validationSchema={FinanceurSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <VStack spacing="4" align="flex-start">
                <FormControl isRequired>
                  <FormLabel htmlFor="nom">Nom du Financeur</FormLabel>
                  <Field
                    as={Input}
                    id="nom"
                    name="nom"
                    placeholder="Entrez le nom du financeur"
                  />
                  <ErrorMessage name="nom">
                    {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Field
                    as={Textarea}
                    id="description"
                    name="description"
                    placeholder="Entrez une description (optionnel)"
                  />
                  <ErrorMessage name="description">
                    {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="conditionsAcces">Conditions d'Accès</FormLabel>
                  <Field
                    as={Textarea}
                    id="conditionsAcces"
                    name="conditionsAcces"
                    placeholder="Entrez les conditions d'accès"
                  />
                  <ErrorMessage name="conditionsAcces">
                    {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="logo">Logo</FormLabel>
                  <Input
                    id="logo"
                    name="logo"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue("logo", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage name="logo">
                    {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="teal"
                  isLoading={isSubmitting}
                  width="full"
                >
                  {isSubmitting ? 'En cours...' : 'Ajouter Financeur'}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>

      {/* Liste des financeurs existants */}
      <Box
        bg="white"
        p="6"
        borderRadius="lg"
        boxShadow="lg"
        transition="all 0.3s ease"
        _hover={{ boxShadow: 'xl' }}
      >
        <Heading size="md" mb="4" color="blue.500">
          Liste des Financeurs
        </Heading>
        {loading ? (
          <Flex justify="center" align="center" py="10">
            <Spinner size="xl" color="teal.500" />
          </Flex>
        ) : error ? (
          <Alert status="error" mb="4">
            <AlertIcon />
            {error}
          </Alert>
        ) : financeurs.length === 0 ? (
          <Text>Aucun financeur enregistré.</Text>
        ) : (
          <Table variant="simple">
            <Thead bg="teal.100">
              <Tr>
                <Th>Logo</Th>
                <Th>Nom</Th>
                <Th>Description</Th>
                <Th>Conditions d'Accès</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {financeurs.map((financeur) => (
                <Tr key={financeur.id} _hover={{ bg: 'teal.50' }}>
                  <Td>
                    {financeur.logo ? (
                      <Image
                        src={financeur.logo}
                        alt={`${financeur.nom} Logo`}
                        boxSize="50px"
                        objectFit="contain"
                        borderRadius="md"
                      />
                    ) : (
                      <Text> - </Text>
                    )}
                  </Td>
                  <Td>{financeur.nom}</Td>
                  <Td>{financeur.description || '-'}</Td>
                  <Td>{financeur.conditionsAcces}</Td>
                  <Td>
                    <HStack spacing="3">
                      <Tooltip label="Modifier" aria-label="Modifier">
                        <IconButton
                          icon={<EditIcon />}
                          colorScheme="yellow"
                          variant="outline"
                          aria-label="Modifier"
                          onClick={() => {
                            setSelectedFinanceur(financeur);
                            onOpen();
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Supprimer" aria-label="Supprimer">
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          variant="outline"
                          aria-label="Supprimer"
                          onClick={() => handleDelete(financeur)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      {/* Modal pour Modifier un Financeur */}
      {selectedFinanceur && (
        <Modal isOpen={isOpen} onClose={() => { onClose(); setSelectedFinanceur(null); }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modifier le Financeur</ModalHeader>
            <ModalCloseButton />
            <Formik
              initialValues={{
                nom: selectedFinanceur.nom,
                description: selectedFinanceur.description === '-' ? '' : selectedFinanceur.description,
                conditionsAcces: selectedFinanceur.conditionsAcces,
                logo: null, // Champ pour le logo (optionnel)
              }}
              validationSchema={FinanceurSchema}
              onSubmit={handleUpdate}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <ModalBody>
                    <VStack spacing="4" align="flex-start">
                      <FormControl isRequired>
                        <FormLabel htmlFor="nom">Nom du Financeur</FormLabel>
                        <Field
                          as={Input}
                          id="nom"
                          name="nom"
                          placeholder="Entrez le nom du financeur"
                        />
                        <ErrorMessage name="nom">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                      </FormControl>

                      <FormControl>
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <Field
                          as={Textarea}
                          id="description"
                          name="description"
                          placeholder="Entrez une description (optionnel)"
                        />
                        <ErrorMessage name="description">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                      </FormControl>

                      <FormControl isRequired>
                        <FormLabel htmlFor="conditionsAcces">Conditions d'Accès</FormLabel>
                        <Field
                          as={Textarea}
                          id="conditionsAcces"
                          name="conditionsAcces"
                          placeholder="Entrez les conditions d'accès"
                        />
                        <ErrorMessage name="conditionsAcces">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                      </FormControl>

                      <FormControl>
                        <FormLabel htmlFor="logo">Logo</FormLabel>
                        <Input
                          id="logo"
                          name="logo"
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            setFieldValue("logo", event.currentTarget.files[0]);
                          }}
                        />
                        <ErrorMessage name="logo">
                          {(msg) => <Text color="red.500" fontSize="sm">{msg}</Text>}
                        </ErrorMessage>
                        {selectedFinanceur.logo && (
                          <Box mt="2">
                            <Text fontSize="sm" mb="1">Logo actuel :</Text>
                            <Image
                              src={selectedFinanceur.logo}
                              alt={`${selectedFinanceur.nom} Logo`}
                              boxSize="100px"
                              objectFit="contain"
                              borderRadius="md"
                            />
                          </Box>
                        )}
                      </FormControl>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="submit"
                      colorScheme="teal"
                      mr={3}
                      isLoading={isSubmitting}
                    >
                      Sauvegarder
                    </Button>
                    <Button onClick={() => { onClose(); setSelectedFinanceur(null); }}>Annuler</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default Financeurs;
