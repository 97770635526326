import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import {
  getFirestore,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Heading,
  Flex,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  HStack,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Center,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorMode,
  useColorModeValue,
  Fade,
  Switch,
} from '@chakra-ui/react';
import {
  EditIcon,
  DeleteIcon,
  AddIcon,
  SunIcon,
  MoonIcon,
} from '@chakra-ui/icons';
import {
  MdExitToApp,
  MdLibraryBooks,
  MdAddCircleOutline,
  MdAccessTime,
  MdAssessment,
  MdVerified,
  MdHelpOutline,
  MdAccountBalance,
  MdSchool, // Ajout de l'icône pour les écoles
} from 'react-icons/md';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

// Enregistrement des composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const Dashboard = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();
  const { colorMode, toggleColorMode } = useColorMode();

  // États pour les Formations
  const [formations, setFormations] = useState([]);
  const [isLoadingFormations, setIsLoadingFormations] = useState(true);
  const [updatingFormationId, setUpdatingFormationId] = useState(null); // Pour gérer l'état de mise à jour

  // États pour les statistiques
  const [stats, setStats] = useState({
    totalFormations: 0,
    recentFormations: 0,
    totalCertificateurs: 0,
  });

  // Fonction de déconnexion
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/admin/login');
    } catch (error) {
      toast({
        title: 'Erreur de déconnexion.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction de récupération des Formations en temps réel
  const fetchFormations = useCallback(() => {
    setIsLoadingFormations(true);
    const unsubscribe = onSnapshot(
      collection(db, 'formations'),
      (snapshot) => {
        const formationsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFormations(formationsList);
        setIsLoadingFormations(false);
        // Mise à jour des statistiques
        setStats({
          totalFormations: formationsList.length,
          recentFormations: formationsList.filter(
            (f) =>
              f.createdAt &&
              new Date(f.createdAt.toDate()) >
                new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
          ).length,
          totalCertificateurs: 10, // Exemple statique, à remplacer par des données réelles
        });
      },
      (error) => {
        console.error(
          'Erreur lors de la récupération des formations:',
          error
        );
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les formations.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingFormations(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  useEffect(() => {
    const unsubscribeFormations = fetchFormations();

    // Nettoyage des listeners à la destruction du composant
    return () => {
      unsubscribeFormations();
    };
  }, [fetchFormations]);

  // Fonction de suppression d'une Formation
  const handleDeleteFormation = async (formationId) => {
    const confirmDelete = window.confirm(
      'Êtes-vous sûr de vouloir supprimer cette formation ?'
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'formations', formationId));
      toast({
        title: 'Formation supprimée.',
        description: 'La formation a été supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la formation:', error);
      toast({
        title: 'Erreur',
        description:
          'Impossible de supprimer la formation. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction de modification d'une Formation
  const handleEditFormation = (formationId) => {
    console.log('Navigating to edit formation with ID:', formationId); // Pour débogage
    navigate(`/admin/editFormation/${formationId}`); // Assurez-vous que le chemin correspond à App.js
  };

  // Fonction pour basculer l'état actif d'une formation
  const handleToggleActive = async (formationId, currentStatus) => {
    setUpdatingFormationId(formationId);
    try {
      await updateDoc(doc(db, 'formations', formationId), {
        isActive: !currentStatus,
      });
      toast({
        title: 'Statut mis à jour.',
        description: `La formation est maintenant ${
          !currentStatus ? 'active' : 'désactivée'
        }.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
      toast({
        title: 'Erreur',
        description:
          'Impossible de mettre à jour le statut de la formation. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUpdatingFormationId(null);
    }
  };

  // Données pour le graphique des formations récentes
  const chartData = {
    labels: [
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
      'Dimanche',
    ],
    datasets: [
      {
        label: 'Nouvelles Formations',
        data: [2, 4, 5, 3, 6, 2, 7], // Exemple de données statiques, à remplacer par des données réelles
        fill: false,
        backgroundColor: '#319795', // Teal.500
        borderColor: '#319795',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: useColorModeValue('gray.700', 'gray.200'),
        },
      },
      title: {
        display: true,
        text: 'Formations Récentes (Cette Semaine)',
        color: useColorModeValue('gray.700', 'gray.200'),
      },
    },
    scales: {
      x: {
        ticks: {
          color: useColorModeValue('gray.700', 'gray.200'),
        },
      },
      y: {
        ticks: {
          color: useColorModeValue('gray.700', 'gray.200'),
        },
      },
    },
  };

  return (
    <Box
      minH="100vh"
      bgGradient={useColorModeValue(
        'linear(to-tr, teal.50, blue.50)',
        'linear(to-tr, teal.900, blue.900)'
      )}
      p="10"
      transition="background 0.3s ease"
    >
      {/* Header avec Thème et Déconnexion */}
      <Flex
        w="full"
        maxW="6xl"
        mb="8"
        align="center"
        justify="space-between"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        borderRadius="lg"
        p="6"
        transition="all 0.3s ease"
        _hover={{ boxShadow: '2xl' }}
      >
        <Heading as="h2" size="lg" color="teal.600">
          Tableau de Bord Administrateur
        </Heading>
        <HStack spacing="4">
          <IconButton
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            variant="ghost"
            aria-label="Toggle Theme"
            size="lg"
          />
          <Button
            onClick={handleSignOut}
            colorScheme="red"
            leftIcon={<MdExitToApp />}
            variant="solid"
            size="lg"
            transition="all 0.3s ease"
            _hover={{ transform: 'scale(1.05)', bg: 'red.600' }}
          >
            Se Déconnecter
          </Button>
        </HStack>
      </Flex>

      {/* Section des Statistiques */}
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap={6}
        mb="8"
        maxW="6xl"
        mx="auto"
      >
        <Fade in>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="md"
            borderRadius="lg"
            p="4"
          >
            <Stat>
              <StatLabel>Total Formations</StatLabel>
              <StatNumber>{stats.totalFormations}</StatNumber>
              <StatHelpText>Depuis le lancement</StatHelpText>
            </Stat>
          </Card>
        </Fade>
        <Fade in delay={0.2}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="md"
            borderRadius="lg"
            p="4"
          >
            <Stat>
              <StatLabel>Formations Récentes</StatLabel>
              <StatNumber>{stats.recentFormations}</StatNumber>
              <StatHelpText>Cette semaine</StatHelpText>
            </Stat>
          </Card>
        </Fade>
        <Fade in delay={0.4}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="md"
            borderRadius="lg"
            p="4"
          >
            <Stat>
              <StatLabel>Certificateurs</StatLabel>
              <StatNumber>{stats.totalCertificateurs}</StatNumber>
              <StatHelpText>Total</StatHelpText>
            </Stat>
          </Card>
        </Fade>
      </Grid>

      {/* Section des Actions */}
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        gap={6}
        mb="8"
        maxW="6xl"
        mx="auto"
      >
        {/* Bouton Gérer les Examens */}
        <Fade in delay={0.6}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="blue"
                size="lg"
                width="full"
                leftIcon={<MdLibraryBooks />}
                onClick={() => navigate('/admin/exam')}
                variant="outline"
                _hover={{ bg: 'blue.50' }}
              >
                Gérer les Examens
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Examens et Tests */}
        <Fade in delay={0.8}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="cyan"
                size="lg"
                width="full"
                leftIcon={<MdAssessment />}
                onClick={() => navigate('/admin/examTests')}
                variant="outline"
                _hover={{ bg: 'cyan.50' }}
              >
                Examens et Tests
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Certificateurs */}
        <Fade in delay={1}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="green"
                size="lg"
                width="full"
                leftIcon={<MdVerified />}
                onClick={() => navigate('/admin/certificateurs')}
                variant="outline"
                _hover={{ bg: 'green.50' }}
              >
                Certificateurs
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Enregistrer une Formation */}
        <Fade in delay={1.2}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="teal"
                size="lg"
                width="full"
                leftIcon={<MdAddCircleOutline />}
                onClick={() => navigate('/admin/addFormation')}
                variant="outline"
                _hover={{ bg: 'teal.50' }}
              >
                Enregistrer une Formation
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Ajouter un Code Métier */}
        <Fade in delay={1.4}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="orange"
                size="lg"
                width="full"
                leftIcon={<AddIcon />}
                onClick={() => navigate('/admin/ficheMetier')}
                variant="outline"
                _hover={{ bg: 'orange.50' }}
              >
                Ajouter un Code Métier
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Gérer les Voies d'Accès */}
        <Fade in delay={1.6}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="purple"
                size="lg"
                width="full"
                leftIcon={<MdAccessTime />}
                onClick={() => navigate('/admin/acces')}
                variant="outline"
                _hover={{ bg: 'purple.50' }}
              >
                Gérer les Voies d'Accès
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Ajouter une Aide */}
        <Fade in delay={1.8}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="blue"
                size="lg"
                width="full"
                leftIcon={<MdHelpOutline />}
                onClick={() => navigate('/admin/addAides')}
                variant="outline"
                _hover={{ bg: 'blue.50' }}
              >
                Ajouter une Aide
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Bouton Ajouter un Financeur */}
        <Fade in delay={2}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="teal"
                size="lg"
                width="full"
                leftIcon={<MdAccountBalance />}
                onClick={() => navigate('/admin/financeurs')}
                variant="outline"
                _hover={{ bg: 'teal.50' }}
              >
                Ajouter un Financeur
              </Button>
            </CardBody>
          </Card>
        </Fade>

        {/* Nouveau bouton Ecole et centres de formations */}
        <Fade in delay={2.2}>
          <Card
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            borderRadius="lg"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
          >
            <CardBody>
              <Button
                colorScheme="blue"
                size="lg"
                width="full"
                leftIcon={<MdSchool />}
                onClick={() => navigate('/admin/ecoles')}
                variant="outline"
                _hover={{ bg: 'blue.50' }}
              >
                Ecole et centres de formations
              </Button>
            </CardBody>
          </Card>
        </Fade>
      </Grid>

      {/* Section du Graphique */}
      <Card
        maxW="6xl"
        mx="auto"
        mb="8"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        borderRadius="lg"
        p="6"
        transition="all 0.3s ease"
        _hover={{ boxShadow: '2xl' }}
      >
        <Line data={chartData} options={chartOptions} />
      </Card>

      {/* Liste des Formations */}
      <Card
        maxW="6xl"
        mx="auto"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        borderRadius="lg"
        p="6"
        transition="all 0.3s ease"
        _hover={{ boxShadow: '2xl' }}
      >
        <CardHeader>
          <Heading as="h3" size="md" color="gray.700">
            Liste des Formations
          </Heading>
        </CardHeader>
        <CardBody>
          {isLoadingFormations ? (
            <Center mt="10">
              <Spinner size="xl" color="teal.500" />
            </Center>
          ) : formations.length > 0 ? (
            <Table variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Titre</Th>
                  <Th>Date de création</Th>
                  <Th>Actif</Th> {/* Nouvelle colonne pour le commutateur */}
                  <Th isNumeric>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {formations.map((formation) => (
                  <Tr key={formation.id} _hover={{ bg: 'teal.50' }}>
                    <Td>{formation.title}</Td>
                    <Td>
                      {formation.createdAt
                        ? new Date(
                            formation.createdAt.toDate()
                          ).toLocaleDateString('fr-FR')
                        : 'N/A'}
                    </Td>
                    <Td>
                      <Switch
                        isChecked={formation.isActive}
                        onChange={() =>
                          handleToggleActive(
                            formation.id,
                            formation.isActive
                          )
                        }
                        isDisabled={updatingFormationId === formation.id}
                        colorScheme="teal"
                      />
                    </Td>
                    <Td isNumeric>
                      <HStack spacing="3">
                        <Tooltip label="Modifier" aria-label="Modifier">
                          <IconButton
                            icon={<EditIcon />}
                            onClick={() =>
                              handleEditFormation(formation.id)
                            }
                            colorScheme="yellow"
                            aria-label="Modifier"
                            variant="ghost"
                            size="sm"
                            _hover={{ bg: 'yellow.100' }}
                          />
                        </Tooltip>
                        <Tooltip label="Supprimer" aria-label="Supprimer">
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleDeleteFormation(formation.id)
                            }
                            colorScheme="red"
                            aria-label="Supprimer"
                            variant="ghost"
                            size="sm"
                            _hover={{ bg: 'red.100' }}
                          />
                        </Tooltip>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Center mt="10">
              <Text>Aucune formation disponible.</Text>
            </Center>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default Dashboard;
