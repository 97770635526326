// src/admin/context/AdminAuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword, 
  signOut, 
  setPersistence, 
  browserLocalPersistence, 
  browserSessionPersistence, 
  onAuthStateChanged 
} from 'firebase/auth';
import { auth } from '../../firebase'; // Importer l'instance auth depuis firebase.js

// Créer le contexte d'authentification
export const AdminAuthContext = createContext();

// Fournisseur de contexte
export const AdminAuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true); // Pour gérer l'état de chargement
  const [error, setError] = useState(null); // Pour gérer les erreurs

  useEffect(() => {
    // Écouter les changements d'état d'authentification
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Si l'utilisateur est connecté
        setAdmin({ uid: user.uid, email: user.email });
      } else {
        // Si l'utilisateur est déconnecté
        setAdmin(null);
      }
      setLoading(false);
    });

    // Nettoyer l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);

  // Fonction de connexion
  const login = async (email, password, rememberMe) => {
    setLoading(true);
    setError(null);
    try {
      // Définir la persistance en fonction de l'option "Se souvenir de moi"
      const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistence);

      // Connexion avec email et mot de passe
      await signInWithEmailAndPassword(auth, email, password);
      
      // L'écouteur onAuthStateChanged mettra à jour l'état `admin`
    } catch (err) {
      console.error('Erreur de connexion:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fonction de déconnexion
  const logout = async () => {
    setLoading(true);
    setError(null);
    try {
      await signOut(auth);
      // L'écouteur onAuthStateChanged mettra à jour l'état `admin`
    } catch (err) {
      console.error('Erreur de déconnexion:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <AdminAuthContext.Provider value={{ admin, loading, error, login, logout }}>
      {children}
    </AdminAuthContext.Provider>
  );
};
