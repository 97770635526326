// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AdminAuthProvider } from './admin/context/AdminAuthContext';
import Login from './admin/pages/Login';
import Dashboard from './admin/pages/Dashboard';
import ViewFormationFile from './admin/pages/ViewFormationFile';
import EditFormation from './admin/pages/EditFormation';
import Exam from './admin/pages/Exam';
import FicheMetiers from './admin/pages/FicheMetiers';
import Acces from './admin/pages/Acces';
import Financeurs from './admin/pages/Financeurs';
import HomePage from './public/pages/HomePage';
import FormationDetailView from './public/pages/FormationDetailView';
import ExamTest from './admin/pages/ExamTest';
import Certificateurs from './admin/pages/Certificateurs';
import ExamDetailView from './public/pages/ExamDetailView';
import EditExamTest from './admin/pages/EditExamTest';
import AddAides from './admin/pages/AddAides';
import EditAides from './admin/pages/EditAides';
import AidesView from './public/pages/AidesView';
import AidesViewDetail from './public/pages/AidesViewDetail';
import School from './admin/pages/School'; // Import du nouveau composant School
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

// Définition du thème avec les couleurs orange, rouge, jaune dégradé
const theme = extendTheme({
  colors: {
    brand: {
      100: '#FFA500', // Orange
      200: '#FF4500', // Rouge
      300: '#FFD700', // Jaune dégradé
    },
  },
});

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          {/* Routes Publiques */}
          <Route path="/" element={<HomePage />} />
          <Route path="/formation/:formationId" element={<FormationDetailView />} />
          <Route path="/exam/:examId" element={<ExamDetailView />} />
          <Route path="/aides-formation" element={<AidesView />} />
          <Route path="/aides-formation/:id" element={<AidesViewDetail />} />

          {/* Routes Administratives */}
          <Route
            path="/admin/*"
            element={
              <AdminAuthProvider>
                <Routes>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="addFormation" element={<ViewFormationFile />} />
                  <Route path="editFormation/:formationId" element={<EditFormation />} />
                  <Route path="exam" element={<Exam />} />
                  <Route path="examTests" element={<ExamTest />} />
                  <Route path="editExam/:examId" element={<EditExamTest />} />
                  <Route path="certificateurs" element={<Certificateurs />} />
                  <Route path="ficheMetier" element={<FicheMetiers />} />
                  <Route path="acces" element={<Acces />} />
                  <Route path="financeurs" element={<Financeurs />} />
                  <Route path="addAides" element={<AddAides />} />
                  <Route path="editAides/:id" element={<EditAides />} />
                  <Route path="login" element={<Login />} />
                  <Route path="ecoles" element={<School />} />
                  {/* Route 404 pour les routes administratives */}
                </Routes>
              </AdminAuthProvider>
            }
          />

          {/* Optionnel : Vous pouvez ajouter une route 404 pour les routes publiques si nécessaire */}
          {/* <Route path="*" element={<PublicNotFound />} /> */}
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
