// src/public/pages/FormationDetailView.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Flex,
  Spinner,
  useToast,
  Divider,
  Alert,
  AlertIcon,
  Icon,
  Button,
  Card,
  CardHeader,
  CardBody,
  useColorModeValue,
  Tag,
  Tooltip,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Badge,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as MdIcons from 'react-icons/md';
import { FaInfoCircle, FaSchool, FaCertificate } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { doc, getDoc, collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

// Importations pour la carte
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Importer l'icône de marqueur personnalisée
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Correction des chemins d'icône pour Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Composant MotionBox pour les animations subtiles
const MotionBox = motion(Box);

const FormationDetailView = () => {
  const { formationId } = useParams();
  const toast = useToast();

  const [formation, setFormation] = useState(null);
  const [certificateur, setCertificateur] = useState(null);
  const [exams, setExams] = useState([]);
  const [codeMetier, setCodeMetier] = useState(null);
  const [voiesAcces, setVoiesAcces] = useState([]);
  const [schools, setSchools] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [nomenclature, setNomenclature] = useState(null);
  const [codeNSFs, setCodeNSFs] = useState([]);
  const [formacodes, setFormacodes] = useState([]);
  const [blocsCompetences, setBlocsCompetences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Variables de thème
  const cardBg = useColorModeValue('white', 'gray.700');
  const alertBg = useColorModeValue('red.50', 'orange.900');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const subHeadingColor = useColorModeValue('teal.500', 'teal.200');
  const tagColor = useColorModeValue('teal.100', 'teal.800');
  const linkColor = useColorModeValue('teal.600', 'teal.300');
  const linkBg = useColorModeValue('teal.50', 'teal.800');
  const epreuveBg = useColorModeValue('gray.50', 'gray.600');
  const badgeBg = useColorModeValue('purple.100', 'purple.800');

  // Variables de couleur pour la section "Examens Associés"
  const examensCardBg = useColorModeValue('purple.50', 'gray.700');
  const examensCardHoverBg = useColorModeValue('purple.100', 'gray.600');
  const examensHeadingColor = useColorModeValue('purple.700', 'purple.300');
  const examensIconColor = useColorModeValue('purple.500', 'purple.300');

  // Fonction générique pour récupérer des entités par leurs IDs
  const fetchEntitiesByIds = useCallback(async (collectionName, ids) => {
    if (!Array.isArray(ids) || ids.length === 0) {
      return [];
    }

    const chunkSize = 10; // Firestore limite 'in' à 10 éléments
    const chunks = [];
    for (let i = 0; i < ids.length; i += chunkSize) {
      chunks.push(ids.slice(i, i + chunkSize));
    }

    const promises = chunks.map(async (chunk) => {
      const q = query(collection(db, collectionName), where('__name__', 'in', chunk));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    });

    const results = await Promise.all(promises);
    return results.flat();
  }, []);

  // Fonction pour récupérer les types de ressources
  const fetchResourceTypes = useCallback(() => {
    const resourceCollection = collection(db, 'resourceTypes');
    const unsubscribe = onSnapshot(
      resourceCollection,
      (snapshot) => {
        const resources = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResourceTypes(resources);
      },
      (err) => {
        console.error('Erreur lors de la récupération des types de ressources:', err);
      }
    );
    return unsubscribe;
  }, []);

  // Nouvelle fonction pour récupérer les examens associés à une formation
  const fetchExamsByFormationId = useCallback(
    async (formationId) => {
      if (!formationId) return [];

      try {
        const examsRef = collection(db, 'exams');
        const q = query(examsRef, where('formations', 'array-contains', formationId));
        const snapshot = await getDocs(q);
        const examsList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return examsList;
      } catch (error) {
        console.error('Erreur lors de la récupération des examens:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les examens associés.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return [];
      }
    },
    [toast]
  );

  // Fonction pour récupérer les écoles proposant la formation
  const fetchSchools = useCallback(async () => {
    try {
      const q = query(collection(db, 'schools'), where('formations', 'array-contains', formationId));
      const snapshot = await getDocs(q);
      const schoolsList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setSchools(schoolsList);
      console.log('Écoles récupérées:', schoolsList);
    } catch (error) {
      console.error('Erreur lors de la récupération des écoles:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les écoles proposant cette formation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [formationId, toast]);

  // Fonction de récupération des détails de la formation
  const fetchFormationDetails = useCallback(
    async () => {
      setLoading(true);
      try {
        const formationRef = doc(db, 'formations', formationId);
        const formationDoc = await getDoc(formationRef);
        if (formationDoc.exists()) {
          const formationData = { id: formationDoc.id, ...formationDoc.data() };
          setFormation(formationData);

          // Récupérer le Certificateur (singulier)
          if (formationData.certifierId) {
            try {
              const certRef = doc(db, 'certificateurs', formationData.certifierId);
              const certDoc = await getDoc(certRef);
              if (certDoc.exists()) {
                setCertificateur({
                  id: certDoc.id,
                  ...certDoc.data(),
                });
              } else {
                setCertificateur(null);
                toast({
                  title: 'Certificateur non trouvé.',
                  description: 'Le certificateur associé à cette formation n\'a pas été trouvé.',
                  status: 'warning',
                  duration: 5000,
                  isClosable: true,
                });
              }
            } catch (certError) {
              console.error('Erreur lors de la récupération du certificateur:', certError);
              toast({
                title: 'Erreur',
                description: 'Impossible de récupérer le certificateur.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            setCertificateur(null);
            // Vous pouvez choisir de ne pas afficher cette notification si cela n'est pas nécessaire
          }

          // Récupérer les Voies d'Accès
          if (formationData.accessIds && formationData.accessIds.length > 0) {
            try {
              const voiesAccesList = await fetchEntitiesByIds('acces', formationData.accessIds);
              setVoiesAcces(voiesAccesList);
            } catch (accessError) {
              console.error("Erreur lors de la récupération des voies d'accès:", accessError);
              toast({
                title: 'Erreur',
                description: "Impossible de récupérer les voies d'accès.",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }

          // Récupérer les Examens Associés
          try {
            const examsList = await fetchExamsByFormationId(formationId);
            setExams(examsList);
          } catch (examError) {
            console.error('Erreur lors de la récupération des examens:', examError);
            // L'erreur est déjà gérée dans fetchExamsByFormationId
          }

          // Récupérer le Code Métier
          if (formationData.codeMetierId) {
            try {
              const codeMetierRef = doc(db, 'ficheMetiers', formationData.codeMetierId);
              const codeMetierDoc = await getDoc(codeMetierRef);
              if (codeMetierDoc.exists()) {
                setCodeMetier({
                  id: codeMetierDoc.id,
                  code: codeMetierDoc.data().code || null,
                  domaine: codeMetierDoc.data().domaine || null,
                  lienExterne: codeMetierDoc.data().lienExterne || null,
                });
              } else {
                setCodeMetier(null);
              }
            } catch (codeError) {
              console.error('Erreur lors de la récupération du code métier:', codeError);
              setCodeMetier(null);
            }
          }

          // Récupérer la Nomenclature
          if (formationData.nomenclatureId) {
            try {
              const nomenclatureRef = doc(db, 'nomenclatures', formationData.nomenclatureId);
              const nomenclatureDoc = await getDoc(nomenclatureRef);
              if (nomenclatureDoc.exists()) {
                setNomenclature({
                  id: nomenclatureDoc.id,
                  nom: nomenclatureDoc.data().nom || null,
                });
              } else {
                setNomenclature(null);
              }
            } catch (nomenclatureError) {
              console.error('Erreur lors de la récupération de la nomenclature:', nomenclatureError);
              setNomenclature(null);
            }
          }

          // Récupérer les Codes NSF
          if (formationData.codeNSFIds && formationData.codeNSFIds.length > 0) {
            try {
              const codeNSFList = await fetchEntitiesByIds('codeNSFs', formationData.codeNSFIds);
              setCodeNSFs(codeNSFList);
            } catch (codeNSFError) {
              console.error('Erreur lors de la récupération des Codes NSF:', codeNSFError);
              toast({
                title: 'Erreur',
                description: 'Impossible de récupérer les Codes NSF.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }

          // Récupérer les Formacodes
          if (formationData.formacodeIds && formationData.formacodeIds.length > 0) {
            try {
              const formacodeList = await fetchEntitiesByIds('formacodes', formationData.formacodeIds);
              setFormacodes(formacodeList);
            } catch (formacodeError) {
              console.error('Erreur lors de la récupération des Formacodes:', formacodeError);
              toast({
                title: 'Erreur',
                description: 'Impossible de récupérer les Formacodes.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }

          // Récupérer les Blocs de Compétences
          if (formationData.blocsCompetences && formationData.blocsCompetences.length > 0) {
            setBlocsCompetences(formationData.blocsCompetences);
          } else {
            setBlocsCompetences([]);
          }

          // Récupérer les écoles proposant cette formation
          await fetchSchools();
        } else {
          throw new Error('Formation non trouvée.');
        }

        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération des détails de la formation:', err);
        setError(err.message);
        setLoading(false);
        toast({
          title: 'Erreur',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [formationId, toast, fetchEntitiesByIds, fetchExamsByFormationId, fetchSchools]
  );

  // Fonction pour obtenir les détails de la ressource
  const getResourceDetails = (resourceName) => {
    const resource = resourceTypes.find((res) => res.name === resourceName);
    return resource || {};
  };

  useEffect(() => {
    fetchFormationDetails();
    const unsubscribeResources = fetchResourceTypes();
    // Nettoyage des listeners
    return () => {
      unsubscribeResources();
    };
  }, [fetchFormationDetails, fetchResourceTypes]);

  if (loading) {
    return (
      <Flex
        justify="center"
        align="center"
        height="100vh"
        bgGradient="linear(to-r, teal.50, blue.50)"
      >
        <Spinner size="lg" color="teal.500" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        justify="center"
        align="center"
        height="100vh"
        bgGradient="linear(to-r, red.50, orange.50)"
      >
        <Alert status="error" borderRadius="md" bg={alertBg} boxShadow="md">
          <AlertIcon />
          {error}
        </Alert>
      </Flex>
    );
  }

  // Calcul du total des coefficients
  const totalCoefficient = exams.reduce((acc, examItem) => {
    if (examItem.epreuves && examItem.epreuves.length > 0) {
      return acc + examItem.epreuves.reduce((sum, epreuve) => sum + (parseFloat(epreuve.coefficient) || 0), 0);
    }
    return acc;
  }, 0);

  return (
    <Box
      p={4}
      bgGradient="linear(to-r, teal.50, blue.50)"
      minHeight="100vh"
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* En-tête */}
      <MotionBox
        bgGradient="linear(to-r, teal.400, blue.400)"
        p={4}
        borderRadius="md"
        mb={4}
        boxShadow="md"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Flex alignItems="center" mb={2}>
          <Icon as={FaSchool} w={8} h={8} color="white" mr={3} />
          <Heading size="lg" color="white">
            Détails de la Formation
          </Heading>
        </Flex>
        <Text fontSize="md" color="whiteAlpha.800">
          Explorez les détails de cette formation.
        </Text>
      </MotionBox>

      {/* Suppression de la Section Image de la Formation */}

      {/* Carte des Écoles */}
      <MotionBox
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        mb={4}
      >
        <Card bg={cardBg} borderRadius="md" boxShadow="sm">
          <CardHeader>
            <Flex alignItems="center">
              <Tooltip label="Carte des écoles proposant cette formation" aria-label="Carte Tooltip">
                <Icon as={MdIcons.MdMap} w={5} h={5} color="teal.500" mr={2} />
              </Tooltip>
              <Heading size="md" color="teal.700">
                Écoles proposant cette formation
              </Heading>
            </Flex>
          </CardHeader>
          <Divider />
          <CardBody>
            {schools.length > 0 ? (
              <Box height="400px" width="100%">
                <MapContainer
                  center={[46.2276, 2.2137]} // Centré sur la France
                  zoom={6}
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {schools.map((school) => {
                    const lat = parseFloat(school.latitude);
                    const lng = parseFloat(school.longitude);
                    if (isNaN(lat) || isNaN(lng)) {
                      console.warn(`Coordonnées invalides pour l'école: ${school.name}`, school);
                      return null;
                    }
                    return (
                      <Marker key={school.id} position={[lat, lng]}>
                        <Popup>
                          <Box textAlign="center">
                            {school.logoURL ? (
                              <Image
                                src={school.logoURL}
                                alt={`Logo de ${school.name}`}
                                boxSize="100px"
                                objectFit="contain"
                                mb={2}
                                mx="auto"
                              />
                            ) : (
                              <Icon as={MdIcons.MdImage} w={10} h={10} color="gray.500" mb={2} />
                            )}
                            {school.name && <Text fontWeight="bold">{school.name}</Text>}
                            {school.address && <Text>{school.address}</Text>}
                            {school.phone && <Text>Téléphone : {school.phone}</Text>}
                            {school.email && <Text>Email : {school.email}</Text>}
                            {school.website && (
                              <Button
                                as="a"
                                href={
                                  school.website.startsWith('http')
                                    ? school.website
                                    : `https://${school.website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                colorScheme="teal"
                                size="sm"
                                mt={2}
                              >
                                <MdIcons.MdLink mr={1} />
                                Visiter le site
                              </Button>
                            )}
                          </Box>
                        </Popup>
                      </Marker>
                    );
                  })}
                </MapContainer>
              </Box>
            ) : (
              <Flex align="center">
                <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                <Text>Aucune école ne propose cette formation pour le moment.</Text>
              </Flex>
            )}
          </CardBody>
        </Card>
      </MotionBox>

      {/* Grille en Onglets */}
      <Tabs variant="enclosed" colorScheme="teal" isFitted>
        <TabList mb="1em">
          <Tab>
            <Flex align="center">
              <FaInfoCircle style={{ marginRight: '8px' }} />
              Informations Générales
            </Flex>
          </Tab>
          <Tab>
            <Flex align="center">
              <FaSchool style={{ marginRight: '8px' }} />
              Voies d'Accès
            </Flex>
          </Tab>
          <Tab>
            <Flex align="center">
              <FaCertificate style={{ marginRight: '8px' }} />
              Certificateur & Code Métier
            </Flex>
          </Tab>
          <Tab>
            <Flex align="center">
              <MdIcons.MdAssignment style={{ marginRight: '8px' }} />
              Examens Associés
            </Flex>
          </Tab>
          {/* Nouveaux onglets avec icônes */}
          <Tab>
            <Flex align="center">
              <MdIcons.MdList style={{ marginRight: '8px' }} />
              Nomenclature & Codes
            </Flex>
          </Tab>
          <Tab>
            <Flex align="center">
              <MdIcons.MdAccountBalance style={{ marginRight: '8px' }} />
              Blocs de Compétences
            </Flex>
          </Tab>
        </TabList>
        <TabPanels>
          {/* Onglet 1: Informations Générales */}
          <TabPanel>
            <Card bg={cardBg} borderRadius="md" boxShadow="sm">
              <CardHeader>
                <Flex alignItems="center">
                  <Tooltip label="Informations générales de la formation" aria-label="Info Générales Tooltip">
                    <Icon as={FaInfoCircle} w={5} h={5} color="blue.500" mr={2} />
                  </Tooltip>
                  <Heading size="md" color="blue.700">
                    Informations Générales
                  </Heading>
                </Flex>
              </CardHeader>
              <Divider />
              <CardBody>
                <VStack align="start" spacing={2}>
                  {formation.title && (
                    <Flex align="center">
                      <Icon as={MdIcons.MdTitle} w={5} h={5} color="teal.500" mr={2} />
                      <Text>
                        <strong>Titre :</strong> {formation.title}
                      </Text>
                    </Flex>
                  )}
                  {formation.description && (
                    <Flex align="center">
                      <Icon as={MdIcons.MdDescription} w={5} h={5} color="teal.500" mr={2} />
                      <Text>
                        <strong>Description :</strong> {formation.description}
                      </Text>
                    </Flex>
                  )}
                  {formation.prerequisites && (
                    <Flex align="center">
                      <Icon as={MdIcons.MdFilterList} w={5} h={5} color="teal.500" mr={2} />
                      <Text>
                        <strong>Prérequis :</strong> {formation.prerequisites}
                      </Text>
                    </Flex>
                  )}
                  {formation.dueDate && (
                    <Flex align="center">
                      <Icon as={MdIcons.MdDateRange} w={5} h={5} color="teal.500" mr={2} />
                      <Text>
                        <strong>Date d'échéance :</strong>{' '}
                        {new Date(formation.dueDate.seconds * 1000).toLocaleDateString('fr-FR')}
                      </Text>
                    </Flex>
                  )}
                  {formation.codeRNCP && (
                    <Flex align="center">
                      <Icon as={MdIcons.MdConfirmationNumber} w={5} h={5} color="teal.500" mr={2} />
                      <Text>
                        <strong>Code RNCP :</strong> {formation.codeRNCP}
                      </Text>
                    </Flex>
                  )}
                </VStack>
              </CardBody>
            </Card>
          </TabPanel>

          {/* Onglet 2: Voies d'Accès */}
          <TabPanel>
            {voiesAcces.length > 0 ? (
              <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                <CardHeader>
                  <Flex alignItems="center">
                    <Tooltip label="Voies d'accès à la formation" aria-label="Voies d'Accès Tooltip">
                      <Icon as={FaSchool} w={5} h={5} color="green.500" mr={2} />
                    </Tooltip>
                    <Heading size="md" color="green.700">
                      Voies d'Accès
                    </Heading>
                  </Flex>
                </CardHeader>
                <Divider />
                <CardBody>
                  <VStack align="start" spacing={2}>
                    {voiesAcces.map((acces) => {
                      // Récupérer le composant d'icône dynamique
                      const IconComponent = MdIcons[acces.icon] || MdIcons.MdTrendingUp;
                      return (
                        <Flex
                          key={acces.id}
                          p={2}
                          borderWidth="1px"
                          borderRadius="md"
                          width="full"
                          bg={tagColor}
                          boxShadow="sm"
                          align="center"
                        >
                          {/* Afficher l'icône */}
                          {acces.icon && (
                            <Icon as={IconComponent} w={6} h={6} color="teal.500" mr={3} />
                          )}

                          {/* Afficher le nom et la description */}
                          <Box>
                            <Text fontSize="sm" fontWeight="bold">
                              {acces.path}
                            </Text>
                            {acces.description && (
                              <Text fontSize="xs" color="gray.600">
                                {acces.description}
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      );
                    })}
                  </VStack>
                </CardBody>
              </Card>
            ) : (
              <Flex align="center">
                <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                <Text>Aucune voie d'accès associée.</Text>
              </Flex>
            )}
          </TabPanel>

          {/* Onglet 3: Certificateur & Code Métier */}
          <TabPanel>
            {(certificateur || codeMetier) ? (
              <VStack align="stretch" spacing={4}>
                {/* Certificateur */}
                {certificateur && (
                  <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Tooltip label="Certificateur et code métier associés" aria-label="Certificateur Tooltip">
                          <Icon as={FaCertificate} w={5} h={5} color="orange.500" mr={2} />
                        </Tooltip>
                        <Heading size="md" color="orange.700">
                          Certificateur
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      <Flex
                        align="center"
                        p={2}
                        borderWidth="1px"
                        borderRadius="md"
                        width="full"
                        bg="orange.50"
                        boxShadow="sm"
                        _hover={{ bg: 'orange.100' }}
                        transition="background 0.2s"
                      >
                        {certificateur.logoURL ? (
                          <Image
                            src={certificateur.logoURL}
                            alt={`Logo de ${certificateur.name}`}
                            boxSize="40px"
                            objectFit="contain"
                            borderRadius="full"
                            mr={3}
                          />
                        ) : (
                          <Icon as={FaCertificate} w={10} h={10} color="orange.500" mr={3} />
                        )}
                        <Box>
                          {certificateur.name && (
                            <Flex align="center">
                              <Icon as={MdIcons.MdPerson} w={4} h={4} color="gray.600" mr={1} />
                              <Text fontSize="sm" fontWeight="bold">
                                {certificateur.name}
                              </Text>
                            </Flex>
                          )}
                          {certificateur.siret && (
                            <Flex align="center">
                              <Icon as={MdIcons.MdBusiness} w={4} h={4} color="gray.600" mr={1} />
                              <Text fontSize="xs" color="gray.600">
                                SIRET : {certificateur.siret}
                              </Text>
                            </Flex>
                          )}
                          {certificateur.createdAt && (
                            <Flex align="center">
                              <Icon as={MdIcons.MdCalendarToday} w={4} h={4} color="gray.600" mr={1} />
                              <Text fontSize="xs" color="gray.600">
                                Date de Création : {new Date(certificateur.createdAt.seconds * 1000).toLocaleString('fr-FR')}
                              </Text>
                            </Flex>
                          )}
                        </Box>
                      </Flex>
                    </CardBody>
                  </Card>
                )}

                {/* Code Métier */}
                {codeMetier && (
                  <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Icon as={MdIcons.MdConfirmationNumber} w={5} h={5} color="green.500" mr={2} />
                        <Heading size="md" color="green.700">
                          Code Métier
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      {codeMetier.code && (
                        <Flex align="center" mb="2">
                          <Icon as={MdIcons.MdLabel} w={5} h={5} color="green.500" mr={2} />
                          <Text fontSize="sm">
                            <strong>Code :</strong> {codeMetier.code}
                          </Text>
                        </Flex>
                      )}
                      {codeMetier.domaine && (
                        <Flex align="center" mb="2">
                          <Icon as={MdIcons.MdBusiness} w={5} h={5} color="green.500" mr={2} />
                          <Text fontSize="sm">
                            <strong>Domaine :</strong> {codeMetier.domaine}
                          </Text>
                        </Flex>
                      )}
                      {codeMetier.lienExterne && (
                        <Button
                          leftIcon={<MdIcons.MdLink />}
                          colorScheme="teal"
                          variant="link"
                          size="sm"
                          mt={1}
                          onClick={() => window.open(codeMetier.lienExterne, '_blank')}
                        >
                          Voir la fiche métier
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                )}
              </VStack>
            ) : (
              <Flex align="center">
                <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                <Text>Aucune information sur le Certificateur ou le Code Métier.</Text>
              </Flex>
            )}
          </TabPanel>

          {/* Onglet 4: Examens Associés */}
          <TabPanel>
            {exams.length > 0 ? (
              <VStack align="stretch" spacing={4}>
                {exams.map((examItem) => (
                  <Card key={examItem.id} bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Tooltip label="Examens associés à la formation" aria-label="Examens Tooltip">
                          <Icon as={MdIcons.MdAssignment} w={5} h={5} color={examensIconColor} mr={2} />
                        </Tooltip>
                        <Heading size="md" color={examensHeadingColor}>
                          {examItem.name}
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      <VStack align="start" spacing={4}>
                        {examItem.type && (
                          <Flex align="center">
                            <Icon as={MdIcons.MdCategory} w={5} h={5} color="purple.500" mr={2} />
                            <Text>
                              <strong>Type :</strong> {examItem.type}
                            </Text>
                          </Flex>
                        )}
                        {examItem.description && (
                          <Flex align="center">
                            <Icon as={MdIcons.MdDescription} w={5} h={5} color="purple.500" mr={2} />
                            <Text>
                              <strong>Description :</strong> {examItem.description}
                            </Text>
                          </Flex>
                        )}

                        {/* Épreuves */}
                        {examItem.epreuves && examItem.epreuves.length > 0 && (
                          <Box width="full">
                            <Flex align="center" mb="2">
                              <Icon as={MdIcons.MdEventNote} w={5} h={5} color="purple.500" mr={2} />
                              <Text fontWeight="bold" color={examensHeadingColor}>
                                Épreuves :
                              </Text>
                            </Flex>
                            <VStack align="start" spacing={4} pl={4}>
                              {examItem.epreuves.map((epreuve, index) => {
                                const resourceDetails = getResourceDetails(epreuve.resource);
                                const hasEpreuveData = epreuve.nom || epreuve.type || epreuve.description || epreuve.tempsHeures !== undefined || epreuve.tempsMinutes !== undefined || epreuve.forme || epreuve.resource || (epreuve.publics && epreuve.publics.length > 0) || epreuve.coefficient !== undefined || epreuve.modalite || epreuve.bareme || epreuve.notes || epreuve.observations || epreuve.date;
                                
                                if (!hasEpreuveData) return null; // Ne pas afficher l'épreuve si aucune donnée n'est présente

                                return (
                                  <Box
                                    key={index}
                                    p="6"
                                    bg={epreuveBg}
                                    borderRadius="md"
                                    boxShadow="sm"
                                    _hover={{ boxShadow: 'md', transform: 'scale(1.01)' }}
                                    transition="all 0.2s"
                                    width="full"
                                  >
                                    <Flex align="center" mb="3">
                                      {epreuve.nom && (
                                        <>
                                          <Icon as={MdIcons.MdDescription} w={5} h={5} color="teal.400" mr={2} />
                                          <Heading as="h4" size="md" color="teal.700">
                                            {epreuve.nom}
                                          </Heading>
                                        </>
                                      )}
                                    </Flex>

                                    {/* Détails de l'Épreuve */}
                                    <VStack align="start" spacing={2} mb="4">
                                      {epreuve.type && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdSettings} w={5} h={5} color="purple.400" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Type :
                                          </Text>
                                          <Text ml="2">{epreuve.type}</Text>
                                        </Flex>
                                      )}

                                      {(epreuve.tempsHeures !== undefined || epreuve.tempsMinutes !== undefined) && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdTimer} w={5} h={5} color="orange.400" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Temps :
                                          </Text>
                                          <Text ml="2">
                                            {epreuve.tempsHeures !== undefined ? `${epreuve.tempsHeures}h` : ''} {epreuve.tempsMinutes !== undefined ? `${epreuve.tempsMinutes}m` : ''}
                                          </Text>
                                        </Flex>
                                      )}

                                      {epreuve.forme && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdFolderOpen} w={5} h={5} color="green.400" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Forme :
                                          </Text>
                                          <Text ml="2">{epreuve.forme}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.resource && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdInsertDriveFile} w={5} h={5} color="red.400" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Ressource :
                                          </Text>
                                          <Text ml="2">{epreuve.resource}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.publics && epreuve.publics.length > 0 && (
                                        <Flex align="center" flexWrap="wrap">
                                          <Icon as={MdIcons.MdPeople} w={5} h={5} color="cyan.400" mr={2} />
                                          <Text fontWeight="bold" color="gray.600" mb="2">
                                            Public Concerné :
                                          </Text>
                                          <Flex wrap="wrap" gridGap="2" ml="2">
                                            {epreuve.publics.map((publicItem, pubIndex) => (
                                              <Tag
                                                key={pubIndex}
                                                colorScheme="cyan"
                                                variant="solid"
                                                borderRadius="full"
                                                px="3"
                                                py="1"
                                              >
                                                {publicItem}
                                              </Tag>
                                            ))}
                                          </Flex>
                                        </Flex>
                                      )}

                                      {epreuve.coefficient !== undefined && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdStar} w={5} h={5} color="yellow.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Coefficient :
                                          </Text>
                                          <Badge
                                            colorScheme="yellow"
                                            ml="2"
                                            borderRadius="full"
                                            px="3"
                                            py="1"
                                          >
                                            {epreuve.coefficient}
                                          </Badge>
                                        </Flex>
                                      )}

                                      {epreuve.modalite && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdAccessibility} w={5} h={5} color="purple.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Modalité :
                                          </Text>
                                          <Text ml="2">{epreuve.modalite}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.bareme && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdPieChart} w={5} h={5} color="green.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Barème :
                                          </Text>
                                          <Text ml="2">{epreuve.bareme}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.notes && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdRateReview} w={5} h={5} color="blue.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Notes :
                                          </Text>
                                          <Text ml="2">{epreuve.notes}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.observations && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdVisibility} w={5} h={5} color="orange.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Observations :
                                          </Text>
                                          <Text ml="2">{epreuve.observations}</Text>
                                        </Flex>
                                      )}

                                      {epreuve.date && (
                                        <Flex align="center">
                                          <Icon as={MdIcons.MdDateRange} w={5} h={5} color="teal.500" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Date :
                                          </Text>
                                          <Text ml="2">
                                            {epreuve.date.seconds
                                              ? new Date(epreuve.date.seconds * 1000).toLocaleDateString('fr-FR')
                                              : 'N/A'}
                                          </Text>
                                        </Flex>
                                      )}
                                    </VStack>

                                    {/* Détails de la Ressource */}
                                    {(resourceDetails.format || resourceDetails.subFormat || resourceDetails.numberOfPages) && (
                                      <Box mb="4">
                                        <Flex align="center" mb="1">
                                          <Icon as={MdIcons.MdDetails} w={5} h={5} color="gray.600" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Détails de la Ressource :
                                          </Text>
                                        </Flex>
                                        <VStack align="start" spacing={2}>
                                          {resourceDetails.format && (
                                            <Flex align="center">
                                              <Icon as={MdIcons.MdVideoLibrary} w={5} h={5} color="yellow.400" mr={2} />
                                              <Text>{resourceDetails.format}</Text>
                                            </Flex>
                                          )}
                                          {resourceDetails.subFormat && (
                                            <Flex align="center">
                                              <Icon as={MdIcons.MdFolderOpen} w={5} h={5} color="teal.400" mr={2} />
                                              <Text>{resourceDetails.subFormat}</Text>
                                            </Flex>
                                          )}
                                          {resourceDetails.numberOfPages && (
                                            <Flex align="center">
                                              <Icon as={MdIcons.MdAssignment} w={5} h={5} color="purple.400" mr={2} />
                                              <Text>Pages : {resourceDetails.numberOfPages}</Text>
                                            </Flex>
                                          )}
                                        </VStack>
                                      </Box>
                                    )}

                                    {/* Fichiers Associés à l'Épreuve */}
                                    {epreuve.files && epreuve.files.length > 0 && (
                                      <Box>
                                        <Flex align="center" mb="2">
                                          <Icon as={MdIcons.MdAttachFile} w={5} h={5} color="gray.600" mr={2} />
                                          <Text fontWeight="bold" color="gray.600">
                                            Fichiers Associés :
                                          </Text>
                                        </Flex>
                                        <VStack align="start" spacing={1}>
                                          {epreuve.files.map((file, fileIndex) => (
                                            <Link
                                              href={file.url}
                                              isExternal
                                              key={fileIndex}
                                              color={linkColor}
                                              fontWeight="bold"
                                              _hover={{ textDecoration: 'underline' }}
                                              display="flex"
                                              alignItems="center"
                                              p="2"
                                              bg={linkBg}
                                              borderRadius="md"
                                              boxShadow="sm"
                                            >
                                              <Icon as={MdIcons.MdLink} w={5} h={5} mr="2" />
                                              {file.name}
                                            </Link>
                                          ))}
                                        </VStack>
                                      </Box>
                                    )}
                                  </Box>
                                );
                              })}
                            </VStack>
                          </Box>
                        )}

                        {/* Fichiers Associés à l'Examen */}
                        {examItem.files && examItem.files.length > 0 && (
                          <Box mt={2}>
                            <Flex align="center" mb="2">
                              <Icon as={MdIcons.MdAttachFile} w={5} h={5} color="gray.600" mr={2} />
                              <Text fontWeight="bold" color={examensHeadingColor}>
                                Fichiers Associés à l'Examen :
                              </Text>
                            </Flex>
                            <VStack align="start" spacing={1} pl={4}>
                              {examItem.files.map((file, index) => (
                                <Link
                                  key={index}
                                  href={file.url}
                                  isExternal
                                  color={linkColor}
                                  fontWeight="bold"
                                  _hover={{ textDecoration: 'underline' }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Icon as={MdIcons.MdLink} w={4} h={4} mr="2" />
                                  {file.name}
                                </Link>
                              ))}
                            </VStack>
                          </Box>
                        )}

                        {/* Date de Création */}
                        {examItem.createdAt && (
                          <Box mt={2}>
                            <Flex align="center">
                              <Icon as={MdIcons.MdCalendarToday} w={5} h={5} color={examensHeadingColor} mr={2} />
                              <Text fontWeight="bold" color={examensHeadingColor}>
                                Date de création :
                              </Text>
                            </Flex>
                            <Text>
                              {new Date(examItem.createdAt.seconds * 1000).toLocaleDateString('fr-FR')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    </CardBody>
                  </Card>
                ))}
              </VStack>
            ) : (
              <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                <CardHeader>
                  <Flex align="center">
                    <Tooltip label="Examens associés à la formation" aria-label="Examens Tooltip">
                      <Icon as={MdIcons.MdAssignment} w={5} h={5} color={examensIconColor} mr={2} />
                    </Tooltip>
                    <Heading size="md" color={examensHeadingColor}>
                      Examens Associés
                    </Heading>
                  </Flex>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Flex align="center">
                    <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                    <Text>Aucun examen associé à cette formation.</Text>
                  </Flex>
                </CardBody>
              </Card>
            )}

            {/* Affichage du total des coefficients */}
            {exams.length > 0 && (
              <Box mt="6" p="4" bg={badgeBg} borderRadius="md" boxShadow="sm">
                <Flex align="center" justify="space-between">
                  <Text fontSize="lg" fontWeight="bold" color="gray.700">
                    Total des Coefficients :
                  </Text>
                  <Badge
                    colorScheme="yellow"
                    fontSize="lg"
                    px="4"
                    py="2"
                    borderRadius="full"
                  >
                    {totalCoefficient}
                  </Badge>
                </Flex>
              </Box>
            )}
          </TabPanel>

          {/* Nouvel Onglet 5: Nomenclature & Codes */}
          <TabPanel>
            {(nomenclature || codeNSFs.length > 0 || formacodes.length > 0) ? (
              <VStack align="stretch" spacing={4}>
                {/* Nomenclature */}
                {nomenclature && nomenclature.nom && (
                  <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Tooltip label="Nomenclature et Codes associés" aria-label="Nomenclature Tooltip">
                          <Icon as={MdIcons.MdCategory} w={5} h={5} color="cyan.500" mr={2} />
                        </Tooltip>
                        <Heading size="md" color="cyan.700">
                          Nomenclature
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      <Text>{nomenclature.nom}</Text>
                    </CardBody>
                  </Card>
                )}

                {/* Codes NSF */}
                {codeNSFs.length > 0 && (
                  <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Icon as={MdIcons.MdConfirmationNumber} w={5} h={5} color="purple.500" mr={2} />
                        <Heading size="md" color="purple.700">
                          Codes NSF
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      <Flex wrap="wrap" gridGap="2">
                        {codeNSFs.map((codeNSF) => (
                          codeNSF.code && (
                            <Tag
                              key={codeNSF.id}
                              colorScheme="purple"
                              variant="solid"
                              borderRadius="full"
                              px="3"
                              py="1"
                            >
                              {codeNSF.code}
                            </Tag>
                          )
                        ))}
                      </Flex>
                    </CardBody>
                  </Card>
                )}

                {/* Formacodes */}
                {formacodes.length > 0 && (
                  <Card bg={cardBg} borderRadius="md" boxShadow="sm">
                    <CardHeader>
                      <Flex alignItems="center">
                        <Icon as={MdIcons.MdBuild} w={5} h={5} color="green.500" mr={2} />
                        <Heading size="md" color="green.700">
                          Formacodes
                        </Heading>
                      </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                      <Flex wrap="wrap" gridGap="2">
                        {formacodes.map((formacode) => (
                          formacode.code && (
                            <Tag
                              key={formacode.id}
                              colorScheme="green"
                              variant="solid"
                              borderRadius="full"
                              px="3"
                              py="1"
                            >
                              {formacode.code}
                            </Tag>
                          )
                        ))}
                      </Flex>
                    </CardBody>
                  </Card>
                )}
              </VStack>
            ) : (
              <Flex align="center">
                <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                <Text>Aucune nomenclature ou code associé.</Text>
              </Flex>
            )}
          </TabPanel>

          {/* Nouvel Onglet 6: Blocs de Compétences */}
          <TabPanel>
            {blocsCompetences.length > 0 ? (
              <VStack align="stretch" spacing={4}>
                {blocsCompetences.map((bloc, index) => {
                  const hasBlocData = bloc.nomBloc || (bloc.competences && bloc.competences.length > 0) || (bloc.modalitesEvaluation && bloc.modalitesEvaluation.length > 0);

                  if (!hasBlocData) return null; // Ne pas afficher le bloc si aucune donnée n'est présente

                  return (
                    <Box key={index} p={4} bg="yellow.50" borderRadius="md" boxShadow="sm" width="full">
                      <Flex justify="space-between" align="center" mb="3">
                        <Flex align="center">
                          <Icon as={MdIcons.MdBuild} w={5} h={5} color="yellow.400" mr={2} />
                          <Heading size="sm" color="yellow.600">
                            Bloc de Compétences {index + 1}
                          </Heading>
                        </Flex>
                        <Icon as={MdIcons.MdExpandMore} w={5} h={5} color="yellow.400" />
                      </Flex>
                      <Divider mb={3} />
                      <VStack align="start" spacing={2}>
                        {/* Nom du Bloc */}
                        {bloc.nomBloc && (
                          <Flex align="center">
                            <Icon as={MdIcons.MdTitle} w={5} h={5} color="yellow.500" mr={2} />
                            <Box>
                              <Text fontWeight="bold">Nom du Bloc :</Text>
                              <Text>{bloc.nomBloc}</Text>
                            </Box>
                          </Flex>
                        )}

                        {/* Compétences */}
                        {bloc.competences && bloc.competences.length > 0 && (
                          <Flex align="center">
                            <Icon as={MdIcons.MdCheckCircle} w={5} h={5} color="yellow.500" mr={2} />
                            <Box>
                              <Text fontWeight="bold" mb="1">
                                Compétences :
                              </Text>
                              <VStack align="start" spacing={1} pl={4}>
                                {bloc.competences.map((competence, compIndex) => (
                                  competence && <Text key={compIndex}>- {competence}</Text>
                                ))}
                              </VStack>
                            </Box>
                          </Flex>
                        )}

                        {/* Modalités d'Évaluation */}
                        {bloc.modalitesEvaluation && bloc.modalitesEvaluation.length > 0 && (
                          <Flex align="center">
                            <Icon as={MdIcons.MdAssessment} w={5} h={5} color="yellow.500" mr={2} />
                            <Box>
                              <Text fontWeight="bold" mb="1">
                                Modalités d'Évaluation :
                              </Text>
                              <VStack align="start" spacing={1} pl={4}>
                                {bloc.modalitesEvaluation.map((modalite, modIndex) => (
                                  modalite && <Text key={modIndex}>- {modalite}</Text>
                                ))}
                              </VStack>
                            </Box>
                          </Flex>
                        )}
                      </VStack>
                    </Box>
                  );
                })}
              </VStack>
            ) : (
              <Flex align="center">
                <Icon as={MdIcons.MdInfo} w={5} h={5} color="gray.500" mr={2} />
                <Text>Aucun Bloc de Compétences associé.</Text>
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default FormationDetailView;
