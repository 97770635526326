// src/admin/pages/ExamTest.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Textarea,
  Image,
  useToast,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import {
  MdAdd,
  MdCloudUpload,
  MdEdit,
  MdDelete,
  MdArrowDropDown,
} from 'react-icons/md';

const ExamTest = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();
  const storage = getStorage();

  // États pour le formulaire d'examen/test
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [selectedFormations, setSelectedFormations] = useState([]);
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // États pour les formations
  const [formations, setFormations] = useState([]);
  const [isLoadingFormations, setIsLoadingFormations] = useState(true);

  // États pour les examens/tests existants
  const [exams, setExams] = useState([]);
  const [isLoadingExams, setIsLoadingExams] = useState(true);

  // États pour les types d'examens/tests
  const [examTypes, setExamTypes] = useState([]);
  const [isLoadingExamTypes, setIsLoadingExamTypes] = useState(true);

  // États pour les types d'épreuves (ex : UC1, UC2, E, U, ...)
  const [epreuveTypes, setEpreuveTypes] = useState([]);
  const [isLoadingEpreuveTypes, setIsLoadingEpreuveTypes] = useState(true);

  // États pour les épreuves ajoutées
  const [epreuves, setEpreuves] = useState([]);

  // États pour les modaux
  const { isOpen: isOpenAddType, onOpen: onOpenAddType, onClose: onCloseAddType } = useDisclosure();
  const { isOpen: isOpenAddFormation, onOpen: onOpenAddFormation, onClose: onCloseAddFormation } = useDisclosure();
  const { isOpen: isOpenAddEpreuveType, onOpen: onOpenAddEpreuveType, onClose: onCloseAddEpreuveType } = useDisclosure();

  // États pour le formulaire d'ajout de type d'examen/test
  const [newExamType, setNewExamType] = useState('');

  // États pour le formulaire d'ajout de nouvelle formation
  const [newFormationTitle, setNewFormationTitle] = useState('');
  const [newFormationDescription, setNewFormationDescription] = useState('');

  // États pour le formulaire d'ajout de type d'épreuve
  const [newEpreuveType, setNewEpreuveType] = useState('');

  // Fonction pour récupérer les formations
  const fetchFormations = useCallback(() => {
    setIsLoadingFormations(true);
    const unsubscribe = onSnapshot(
      collection(db, 'formations'),
      (snapshot) => {
        const formationsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFormations(formationsList);
        setIsLoadingFormations(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des formations:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les formations.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingFormations(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour récupérer les examens/tests
  const fetchExams = useCallback(() => {
    setIsLoadingExams(true);
    const unsubscribe = onSnapshot(
      collection(db, 'exams'),
      (snapshot) => {
        const examsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExams(examsList);
        setIsLoadingExams(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des examens/tests:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les examens/tests.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingExams(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour récupérer les types d'examens/tests
  const fetchExamTypes = useCallback(() => {
    setIsLoadingExamTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'examTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExamTypes(typesList);
        setIsLoadingExamTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types d\'examens/tests:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types d\'examens/tests.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingExamTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour récupérer les types d'épreuves
  const fetchEpreuveTypes = useCallback(() => {
    setIsLoadingEpreuveTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'epreuveTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEpreuveTypes(typesList);
        setIsLoadingEpreuveTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types d\'épreuves:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types d\'épreuves.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingEpreuveTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  useEffect(() => {
    const unsubscribeFormations = fetchFormations();
    const unsubscribeExams = fetchExams();
    const unsubscribeExamTypes = fetchExamTypes();
    const unsubscribeEpreuveTypes = fetchEpreuveTypes();

    // Nettoyage des listeners à la destruction du composant
    return () => {
      unsubscribeFormations();
      unsubscribeExams();
      unsubscribeExamTypes();
      unsubscribeEpreuveTypes();
    };
  }, [fetchFormations, fetchExams, fetchExamTypes, fetchEpreuveTypes]);

  // Gestionnaire de changement pour les fichiers
  const handleFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    const previews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFilePreviews(previews);
  };

  // Fonction pour ajouter une nouvelle épreuve
  const addEpreuve = () => {
    setEpreuves([...epreuves, { nom: '', type: '', coefficient: 1 }]);
  };

  // Fonction pour supprimer une épreuve
  const removeEpreuve = (index) => {
    const updatedEpreuves = [...epreuves];
    updatedEpreuves.splice(index, 1);
    setEpreuves(updatedEpreuves);
  };

  // Fonction de soumission du formulaire d'examen/test
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      // Upload des fichiers dans Firebase Storage
      const uploadedFilesURLs = [];
      if (files.length > 0) {
        for (const file of files) {
          const storageRef = ref(storage, `exams/${Date.now()}_${file.name}`);
          const snapshot = await uploadBytes(storageRef, file);
          const fileURL = await getDownloadURL(snapshot.ref);
          uploadedFilesURLs.push({
            name: file.name,
            url: fileURL,
          });
        }
      }

      // Ajout du document dans Firestore
      const docRef = await addDoc(collection(db, 'exams'), {
        name,
        type,
        formations: selectedFormations,
        description,
        files: uploadedFilesURLs,
        epreuves, // Ajout des épreuves avec coefficient
        createdAt: new Date(),
      });

      // Réinitialisation du formulaire
      setName('');
      setType('');
      setSelectedFormations([]);
      setDescription('');
      setFiles([]);
      setFilePreviews([]);
      setEpreuves([]);

      toast({
        title: 'Examen/Test créé.',
        description: `L'examen/test "${name}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création de l\'examen/test:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de créer l\'examen/test. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fonction de suppression d'un examen/test
  const handleDeleteExam = async (examId) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet examen/test ?');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'exams', examId));
      toast({
        title: 'Examen/Test supprimé.',
        description: 'L\'examen/test a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'examen/test:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer l\'examen/test. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction de soumission du formulaire d'ajout de type d'examen/test
  const handleAddExamType = async () => {
    if (!newExamType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type d\'examen/test.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'examTypes'), {
        name: newExamType,
      });
      toast({
        title: 'Type ajouté.',
        description: `Le type "${newExamType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewExamType('');
      onCloseAddType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type d\'examen/test:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction de soumission du formulaire d'ajout de nouvelle formation
  const handleAddFormation = async () => {
    if (!newFormationTitle) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer le titre de la formation.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'formations'), {
        title: newFormationTitle,
        description: newFormationDescription,
        createdAt: new Date(),
      });
      toast({
        title: 'Formation ajoutée.',
        description: `La formation "${newFormationTitle}" a été ajoutée avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewFormationTitle('');
      setNewFormationDescription('');
      onCloseAddFormation();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la formation:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter la formation. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction de soumission du formulaire d'ajout de type d'épreuve
  const handleAddEpreuveType = async () => {
    if (!newEpreuveType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type d\'épreuve.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'epreuveTypes'), {
        name: newEpreuveType,
      });
      toast({
        title: 'Type d\'épreuve ajouté.',
        description: `Le type d'épreuve "${newEpreuveType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewEpreuveType('');
      onCloseAddEpreuveType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type d\'épreuve:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type d\'épreuve. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="start" minH="100vh" bgGradient="linear(to-b, teal.50, blue.50)" p="10">
      {/* Header */}
      <Card
        w="full"
        maxW="6xl"
        mb="8"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        transition="all 0.3s ease"
        _hover={{ boxShadow: 'dark-lg' }}
      >
        <CardHeader p="6">
          <Flex justify="space-between" align="center">
            <Heading as="h2" size="lg" color="teal.600">
              Ajouter un Examen/Test
            </Heading>
            <Button
              onClick={() => navigate('/admin/dashboard')}
              colorScheme="blue"
              leftIcon={<MdAdd />}
              variant="outline"
            >
              Retour au Tableau de Bord
            </Button>
          </Flex>
        </CardHeader>
      </Card>

      {/* Formulaire d'ajout */}
      <Card
        w="full"
        maxW="6xl"
        mb="8"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        p="6"
      >
        <CardBody>
          <form onSubmit={handleSubmit}>
            <FormControl id="name" mb="4">
              <FormLabel>Nom de l'Examen/Test</FormLabel>
              <Input
                placeholder="Entrez le nom de l'examen/test"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl id="type" mb="4">
              <FormLabel>Type d'Examen/Test</FormLabel>
              <Flex align="center">
                <ChakraSelect
                  placeholder="Sélectionnez le type d'examen/test"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  {isLoadingExamTypes ? (
                    <option disabled>Chargement...</option>
                  ) : (
                    examTypes.map((examType) => (
                      <option key={examType.id} value={examType.name}>
                        {examType.name}
                      </option>
                    ))
                  )}
                </ChakraSelect>
                <Button
                  onClick={onOpenAddType}
                  colorScheme="green"
                  ml="2"
                  leftIcon={<MdAdd />}
                >
                  Ajouter Type
                </Button>
              </Flex>
            </FormControl>

            <FormControl id="formations" mb="4">
              <FormLabel>Formation(s) Associée(s)</FormLabel>
              {isLoadingFormations ? (
                <Spinner size="md" color="teal.500" />
              ) : (
                <Flex align="center">
                  <Select
                    isMulti
                    name="formations"
                    options={formations.map((formation) => ({
                      value: formation.id,
                      label: formation.title,
                    }))}
                    value={selectedFormations.map((id) => {
                      const formation = formations.find((f) => f.id === id);
                      return formation ? { value: formation.id, label: formation.title } : null;
                    }).filter(Boolean)}
                    onChange={(selectedOptions) => {
                      setSelectedFormations(selectedOptions.map((option) => option.value));
                    }}
                    placeholder="Sélectionnez des formations"
                    chakraStyles={{
                      control: (provided) => ({
                        ...provided,
                        width: '300px',
                      }),
                    }}
                  />
                  <Button
                    onClick={onOpenAddFormation}
                    colorScheme="green"
                    ml="2"
                    leftIcon={<MdAdd />}
                  >
                    Ajouter Formation
                  </Button>
                </Flex>
              )}
            </FormControl>

            {/* Section pour les Épreuves */}
            <Box mb="4" w="100%">
              <Flex justify="space-between" align="center" mb="2">
                <FormLabel>Épreuves</FormLabel>
                <Button
                  onClick={addEpreuve}
                  colorScheme="purple"
                  size="sm"
                  leftIcon={<MdAdd />}
                >
                  Ajouter Épreuve
                </Button>
              </Flex>
              {epreuves.map((epreuve, index) => (
                <Box key={index} p="4" mb="2" borderWidth="1px" borderRadius="md" bg="gray.50">
                  <Flex justify="space-between" align="center" mb="2">
                    <Heading as="h4" size="sm" color="gray.700">
                      Épreuve {index + 1}
                    </Heading>
                    <IconButton
                      icon={<MdDelete />}
                      colorScheme="red"
                      size="sm"
                      onClick={() => removeEpreuve(index)}
                      aria-label="Supprimer Épreuve"
                    />
                  </Flex>
                  <FormControl id={`epreuve-name-${index}`} mb="4">
                    <FormLabel>Nom de l'Épreuve</FormLabel>
                    <Input
                      placeholder="Entrez le nom de l'épreuve"
                      value={epreuve.nom}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].nom = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                    />
                  </FormControl>
                  <FormControl id={`epreuve-type-${index}`} mb="4">
                    <FormLabel>Type d'Épreuve</FormLabel>
                    <Flex align="center">
                      <ChakraSelect
                        placeholder="Sélectionnez le type d'épreuve"
                        value={epreuve.type}
                        onChange={(e) => {
                          const updatedEpreuves = [...epreuves];
                          updatedEpreuves[index].type = e.target.value;
                          setEpreuves(updatedEpreuves);
                        }}
                      >
                        {isLoadingEpreuveTypes ? (
                          <option disabled>Chargement...</option>
                        ) : (
                          epreuveTypes.map((etype) => (
                            <option key={etype.id} value={etype.name}>
                              {etype.name}
                            </option>
                          ))
                        )}
                      </ChakraSelect>
                      <Button
                        onClick={onOpenAddEpreuveType}
                        colorScheme="green"
                        ml="2"
                        size="sm"
                        leftIcon={<MdAdd />}
                      >
                        Ajouter Type
                      </Button>
                    </Flex>
                  </FormControl>
                  <FormControl id={`epreuve-coefficient-${index}`} mb="4">
                    <FormLabel>Coefficient</FormLabel>
                    <Input
                      type="number"
                      min="1"
                      placeholder="Entrez le coefficient de l'épreuve"
                      value={epreuve.coefficient}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        const value = parseInt(e.target.value, 10);
                        updatedEpreuves[index].coefficient = isNaN(value) ? 1 : value;
                        setEpreuves(updatedEpreuves);
                      }}
                    />
                  </FormControl>
                </Box>
              ))}
              {epreuves.length === 0 && (
                <Text color="gray.500">Aucune épreuve ajoutée.</Text>
              )}
            </Box>

            <FormControl id="description" mb="4">
              <FormLabel>Description (facultatif)</FormLabel>
              <Textarea
                placeholder="Entrez une description de l'examen/test"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl id="files" mb="4">
              <FormLabel>Fichiers Associés (facultatif)</FormLabel>
              <Input
                type="file"
                accept="application/pdf, image/*"
                multiple
                onChange={handleFilesChange}
              />
              {filePreviews.length > 0 && (
                <Box mt="4" display="flex" flexWrap="wrap">
                  {filePreviews.map((preview, index) => (
                    <Image
                      key={index}
                      src={preview}
                      alt={`Aperçu du fichier ${index + 1}`}
                      boxSize="100px"
                      objectFit="cover"
                      mr="2"
                      mb="2"
                      borderRadius="md"
                    />
                  ))}
                </Box>
              )}
            </FormControl>

            <Button
              type="submit"
              colorScheme="teal"
              leftIcon={<MdCloudUpload />}
              isLoading={isSubmitting}
              loadingText="Enregistrement..."
              width="full"
            >
              Enregistrer l'Examen/Test
            </Button>
          </form>
        </CardBody>
      </Card>

      {/* Liste des Examens/Tests */}
      <Card
        w="full"
        maxW="6xl"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        p="6"
      >
        <CardHeader>
          <Heading as="h3" size="md" color="gray.700">
            Liste des Examens/Tests
          </Heading>
        </CardHeader>
        <CardBody>
          {isLoadingExams ? (
            <Flex justify="center" align="center" mt="10">
              <Spinner size="xl" color="teal.500" />
            </Flex>
          ) : exams.length > 0 ? (
            <Table variant="simple" size="md">
              <Thead bg="teal.100">
                <Tr>
                  <Th>Nom</Th>
                  <Th>Type</Th>
                  <Th>Formations Associées</Th>
                  <Th>Date de Création</Th>
                  <Th isNumeric>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {exams.map((exam) => (
                  <Tr key={exam.id} _hover={{ bg: 'teal.50' }}>
                    <Td>{exam.name || 'N/A'}</Td>
                    <Td>{exam.type || 'N/A'}</Td>
                    <Td>
                      {exam.formations && exam.formations.length > 0
                        ? exam.formations.map((formationId) => {
                            const formation = formations.find((f) => f.id === formationId);
                            return formation ? formation.title : 'Inconnu';
                          }).join(', ')
                        : 'Aucune'}
                    </Td>
                    <Td>
                      {exam.createdAt
                        ? new Date(exam.createdAt.toDate()).toLocaleDateString('fr-FR')
                        : 'N/A'}
                    </Td>
                    <Td isNumeric>
                      <HStack spacing="3">
                        <Tooltip label="Modifier" aria-label="Modifier">
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => navigate(`/admin/editExam/${exam.id}`)}
                            colorScheme="yellow"
                            aria-label="Modifier"
                            _hover={{ transform: 'scale(1.1)', transition: 'all 0.2s ease-in-out' }}
                          />
                        </Tooltip>
                        <Tooltip label="Supprimer" aria-label="Supprimer">
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeleteExam(exam.id)}
                            colorScheme="red"
                            aria-label="Supprimer"
                            _hover={{ transform: 'scale(1.1)', transition: 'all 0.2s ease-in-out' }}
                          />
                        </Tooltip>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex justify="center" align="center" mt="10">
              <Box>
                <Heading size="md">Aucun examen/test disponible.</Heading>
              </Box>
            </Flex>
          )}
        </CardBody>
      </Card>

      {/* Modal pour ajouter un nouveau type d'examen/test */}
      <Modal isOpen={isOpenAddType} onClose={onCloseAddType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type d'Examen/Test</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newExamType">
              <FormLabel>Nom du Type</FormLabel>
              <Input
                placeholder="Entrez le nom du type d'examen/test"
                value={newExamType}
                onChange={(e) => setNewExamType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddExamType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewExamType(''); onCloseAddType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal pour ajouter une nouvelle formation */}
      <Modal isOpen={isOpenAddFormation} onClose={onCloseAddFormation}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une Nouvelle Formation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newFormationTitle" mb="4">
              <FormLabel>Titre de la Formation</FormLabel>
              <Input
                placeholder="Entrez le titre de la formation"
                value={newFormationTitle}
                onChange={(e) => setNewFormationTitle(e.target.value)}
              />
            </FormControl>
            <FormControl id="newFormationDescription" mb="4">
              <FormLabel>Description de la Formation (facultatif)</FormLabel>
              <Textarea
                placeholder="Entrez une description de la formation"
                value={newFormationDescription}
                onChange={(e) => setNewFormationDescription(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddFormation}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewFormationTitle(''); setNewFormationDescription(''); onCloseAddFormation(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal pour ajouter un nouveau type d'épreuve */}
      <Modal isOpen={isOpenAddEpreuveType} onClose={onCloseAddEpreuveType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type d'Épreuve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newEpreuveType">
              <FormLabel>Nom du Type d'Épreuve</FormLabel>
              <Input
                placeholder="Entrez le nom du type d'épreuve"
                value={newEpreuveType}
                onChange={(e) => setNewEpreuveType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddEpreuveType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewEpreuveType(''); onCloseAddEpreuveType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ExamTest;
