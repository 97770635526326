// src/public/pages/ExamDetailView.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  Tag,
  Icon,
  Button,
  VStack,
  Stack,
  Divider,
  Link,
  useColorModeValue,
  SimpleGrid,
  Badge,
  Tooltip,
  Image,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  MdArrowBack,
  MdDescription,
  MdAssignment,
  MdDateRange,
  MdLink,
  MdFolderOpen,
  MdVideoLibrary,
  MdInsertDriveFile,
  MdPeople,
  MdTimer,
  MdSettings,
} from 'react-icons/md';

const ExamDetailView = () => {
  const { examId } = useParams();
  const navigate = useNavigate();

  const [exam, setExam] = useState(null);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour récupérer les types de ressources
  const fetchResourceTypes = useCallback(() => {
    const resourceCollection = collection(db, 'resourceTypes');
    const unsubscribe = onSnapshot(
      resourceCollection,
      (snapshot) => {
        const resources = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResourceTypes(resources);
      },
      (err) => {
        console.error('Erreur lors de la récupération des types de ressources:', err);
      }
    );
    return unsubscribe;
  }, []);

  // Fonction pour récupérer les détails de l'examen
  const fetchExamDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const examRef = doc(db, 'exams', examId);
      const examSnap = await getDoc(examRef);
      if (examSnap.exists()) {
        setExam({ id: examSnap.id, ...examSnap.data() });
      } else {
        setError("Examen/Test non trouvé.");
      }
    } catch (err) {
      console.error("Erreur lors de la récupération de l'examen/test:", err);
      setError("Impossible de charger les détails de l'examen/test. Veuillez réessayer plus tard.");
    } finally {
      setIsLoading(false);
    }
  }, [examId]);

  useEffect(() => {
    fetchExamDetails();
    const unsubscribeResources = fetchResourceTypes();
    // Nettoyage des listeners
    return () => {
      unsubscribeResources();
    };
  }, [fetchExamDetails, fetchResourceTypes]);

  // Fonction pour naviguer vers la page précédente
  const handleBack = () => {
    navigate(-1);
  };

  // Couleurs adaptées au mode clair/sombre
  const cardBg = useColorModeValue('white', 'gray.700');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const subHeadingColor = useColorModeValue('teal.500', 'teal.200');
  const tagBg = useColorModeValue('teal.100', 'teal.800');
  const badgeBg = useColorModeValue('purple.100', 'purple.800');
  const epreuveBg = useColorModeValue('gray.50', 'gray.600'); // Couleur de fond des épreuves
  const linkColor = useColorModeValue('teal.600', 'teal.300');
  const linkBg = useColorModeValue('teal.50', 'teal.800'); // Nouvelle variable pour bg des liens

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center height="100vh">
        <Alert status="error" borderRadius="md" maxW="lg" textAlign="center">
          <AlertIcon />
          {error}
        </Alert>
      </Center>
    );
  }

  // Fonction pour obtenir les détails de la ressource
  const getResourceDetails = (resourceName) => {
    const resource = resourceTypes.find((res) => res.name === resourceName);
    return resource || {};
  };

  // Calcul du total des coefficients
  const totalCoefficient = exam.epreuves.reduce((acc, epreuve) => acc + (parseFloat(epreuve.coefficient) || 0), 0);

  return (
    <Box maxW="7xl" mx="auto" mt="10" p="6" bg={cardBg} boxShadow="lg" borderRadius="md">
      {/* Bouton de retour */}
      <Button
        leftIcon={<MdArrowBack />}
        variant="outline"
        colorScheme="teal"
        mb="6"
        onClick={handleBack}
      >
        Retour
      </Button>

      {/* Titre de l'Examen/Test */}
      <Heading
        as="h2"
        size="2xl"
        color={headingColor}
        mb="4"
        textAlign="center"
        textTransform="uppercase"
        letterSpacing="widest"
      >
        {exam.name || 'Nom non disponible'}
      </Heading>

      {/* Type de l'Examen/Test */}
      <Flex justifyContent="center" mb="6">
        <Tag
          colorScheme="teal"
          size="lg"
          variant="solid"
          borderRadius="full"
          px="4"
          py="2"
          fontWeight="bold"
        >
          {exam.type || 'Type non spécifié'}
        </Tag>
      </Flex>

      {/* Description */}
      <Box mb="6">
        <Flex align="center" mb="3">
          <Tooltip label="Description de l'épreuve" aria-label="Description Tooltip">
            <Icon as={MdDescription} w={6} h={6} color="teal.500" mr="2" />
          </Tooltip>
          <Heading as="h3" size="lg" color={subHeadingColor}>
            Description
          </Heading>
        </Flex>
        <Text color="gray.700" fontSize="lg" pl="8">
          {exam.description || 'Description non disponible.'}
        </Text>
      </Box>

      <Divider mb="6" />

      {/* Épreuves */}
      {exam.epreuves && exam.epreuves.length > 0 && (
        <Box mb="6">
          <Flex align="center" mb="4">
            <Tooltip label="Liste des épreuves" aria-label="Épreuves Tooltip">
              <Icon as={MdAssignment} w={6} h={6} color="purple.500" mr="2" />
            </Tooltip>
            <Heading as="h3" size="lg" color={subHeadingColor}>
              Épreuves
            </Heading>
          </Flex>
          <Stack direction="column" spacing={6}>
            {exam.epreuves.map((epreuve, index) => {
              const resourceDetails = getResourceDetails(epreuve.resource);
              return (
                <Box
                  key={index}
                  p="6"
                  bg={epreuveBg}
                  borderRadius="md"
                  boxShadow="sm"
                  _hover={{ boxShadow: 'md', transform: 'scale(1.01)' }}
                  transition="all 0.2s"
                >
                  <Flex align="center" mb="3">
                    <Icon as={MdDescription} w={5} h={5} color="teal.400" mr="2" />
                    <Heading as="h4" size="md" color="teal.700">
                      {epreuve.nom || `Épreuve ${index + 1}`}
                    </Heading>
                  </Flex>

                  {/* Détails de l'Épreuve */}
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb="4">
                    {/* Type */}
                    <Flex align="center">
                      <Icon as={MdSettings} w={5} h={5} color="purple.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Type :
                      </Text>
                      <Tag
                        colorScheme="purple"
                        ml="2"
                        variant="subtle"
                        borderRadius="full"
                        px="3"
                        py="1"
                      >
                        {epreuve.type || 'Non spécifié'}
                      </Tag>
                    </Flex>

                    {/* Description */}
                    <Flex align="center">
                      <Icon as={MdDescription} w={5} h={5} color="blue.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Description :
                      </Text>
                      <Text ml="2">{epreuve.description || 'Non disponible.'}</Text>
                    </Flex>

                    {/* Temps */}
                    <Flex align="center">
                      <Icon as={MdTimer} w={5} h={5} color="orange.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Temps :
                      </Text>
                      <Badge
                        colorScheme="orange"
                        ml="2"
                        borderRadius="full"
                        px="3"
                        py="1"
                      >
                        {epreuve.tempsHeures || 0}h {epreuve.tempsMinutes || 0}m
                      </Badge>
                    </Flex>

                    {/* Coefficient */}
                    <Flex align="center">
                      <Icon as={MdAssignment} w={5} h={5} color="yellow.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Coefficient :
                      </Text>
                      <Badge
                        colorScheme="yellow"
                        ml="2"
                        borderRadius="full"
                        px="3"
                        py="1"
                      >
                        {epreuve.coefficient || 1}
                      </Badge>
                    </Flex>

                    {/* Forme */}
                    <Flex align="center">
                      <Icon as={MdFolderOpen} w={5} h={5} color="green.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Forme :
                      </Text>
                      <Tag
                        colorScheme="green"
                        ml="2"
                        variant="subtle"
                        borderRadius="full"
                        px="3"
                        py="1"
                      >
                        {epreuve.forme || 'Non spécifié'}
                      </Tag>
                    </Flex>

                    {/* Ressource à Rendre */}
                    <Flex align="center">
                      <Icon as={MdInsertDriveFile} w={5} h={5} color="red.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600">
                        Ressource :
                      </Text>
                      <Tag
                        colorScheme="red"
                        ml="2"
                        variant="subtle"
                        borderRadius="full"
                        px="3"
                        py="1"
                      >
                        {epreuve.resource || 'Non spécifié'}
                      </Tag>
                    </Flex>

                    {/* Public Concerné */}
                    <Flex align="center" flexWrap="wrap">
                      <Icon as={MdPeople} w={5} h={5} color="cyan.400" mr="2" />
                      <Text fontWeight="bold" color="gray.600" mb="2">
                        Public Concerné :
                      </Text>
                      <Flex wrap="wrap" gridGap="2" ml="2">
                        {epreuve.publics && epreuve.publics.length > 0 ? (
                          epreuve.publics.map((publicItem, pubIndex) => (
                            <Tag key={pubIndex} colorScheme="cyan" variant="solid" borderRadius="full" px="3" py="1">
                              {publicItem}
                            </Tag>
                          ))
                        ) : (
                          <Text>Non spécifié</Text>
                        )}
                      </Flex>
                    </Flex>
                  </SimpleGrid>

                  {/* Ressources Détails */}
                  <Box mb="4">
                    <Text fontWeight="bold" mb="1" color="gray.600">
                      Détails de la Ressource :
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                      {/* Format */}
                      <Flex align="center">
                        <Icon as={MdVideoLibrary} w={5} h={5} color="yellow.400" mr="2" />
                        <Text>{resourceDetails.format || 'Format non spécifié'}</Text>
                      </Flex>
                      {/* Sous-Format */}
                      <Flex align="center">
                        <Icon as={MdFolderOpen} w={5} h={5} color="teal.400" mr="2" />
                        <Text>{resourceDetails.subFormat || 'Sous-format non spécifié'}</Text>
                      </Flex>
                      {/* Nombre de Pages (si applicable) */}
                      {resourceDetails.numberOfPages && (
                        <Flex align="center">
                          <Icon as={MdAssignment} w={5} h={5} color="purple.400" mr="2" />
                          <Text>Pages : {resourceDetails.numberOfPages}</Text>
                        </Flex>
                      )}
                    </SimpleGrid>
                  </Box>

                  {/* Liens et Ressources */}
                  {exam.files && exam.files.length > 0 && (
                    <Box>
                      <Text fontWeight="bold" mb="2" color="gray.600">
                        Fichiers Associés :
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                        {exam.files.map((file, fileIndex) => (
                          <Link
                            href={file.url}
                            isExternal
                            key={fileIndex}
                            color={linkColor}
                            fontWeight="bold"
                            _hover={{ textDecoration: 'underline' }}
                            display="flex"
                            alignItems="center"
                            p="2"
                            bg={linkBg} // Utilisation de la variable définie au sommet
                            borderRadius="md"
                            boxShadow="sm"
                          >
                            <Icon as={MdLink} w={5} h={5} mr="2" />
                            {file.name || `Fichier ${fileIndex + 1}`}
                          </Link>
                        ))}
                      </SimpleGrid>
                    </Box>
                  )}
                </Box>
              );
            })}

            {/* Affichage du total des coefficients */}
            <Box mt="6" p="4" bg={badgeBg} borderRadius="md" boxShadow="sm">
              <Flex align="center" justify="space-between">
                <Text fontSize="lg" fontWeight="bold" color="gray.700">
                  Total des Coefficients :
                </Text>
                <Badge
                  colorScheme="yellow"
                  fontSize="lg"
                  px="4"
                  py="2"
                  borderRadius="full"
                >
                  {totalCoefficient}
                </Badge>
              </Flex>
            </Box>
          </Stack>
        </Box>
      )}

      {/* Fichiers Associés Globaux */}
      {exam.files && exam.files.length > 0 && (
        <Box mb="6">
          <Flex align="center" mb="4">
            <Tooltip label="Accéder aux fichiers associés" aria-label="Fichiers Tooltip">
              <Icon as={MdFolderOpen} w={6} h={6} color="purple.500" mr="2" />
            </Tooltip>
            <Heading as="h3" size="lg" color={subHeadingColor}>
              Fichiers Associés
            </Heading>
          </Flex>
          <VStack align="start" spacing="3" pl="8">
            {exam.files.map((file, index) => (
              <Link
                href={file.url}
                isExternal
                key={index}
                color="teal.600"
                fontWeight="bold"
                _hover={{ textDecoration: 'underline' }}
                display="flex"
                alignItems="center"
              >
                <Icon as={MdLink} w={4} h={4} mr="2" />
                {file.name || `Fichier ${index + 1}`}
              </Link>
            ))}
          </VStack>
        </Box>
      )}

      <Divider mb="6" />

      {/* Date de Création */}
      <Box>
        <Flex align="center">
          <Tooltip label="Date de création de l'examen/test" aria-label="Date Tooltip">
            <Icon as={MdDateRange} w={6} h={6} color="blue.500" mr="2" />
          </Tooltip>
          <Text color="gray.600" fontSize="md">
            Date de création : {exam.createdAt ? exam.createdAt.toDate().toLocaleDateString('fr-FR') : 'N/A'}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default ExamDetailView;
