// src/admin/pages/Login.js

import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast,
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Checkbox,
  Text,
  Alert,
  AlertIcon,
  useColorModeValue,
  HStack,
  Circle,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdEmail, MdLock, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { motion } from 'framer-motion';

// Composant Motion pour les animations
const MotionBox = motion(Box);

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoginError('');
    try {
      // Connexion de l'utilisateur avec Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);

      // Option "Se Souvenir de Moi" (persistante)
      // Note : La persistance doit être configurée avant la connexion
      // Vous pouvez configurer la persistance selon vos besoins

      // Redirection vers Dashboard après une connexion réussie
      toast({
        title: 'Connexion réussie.',
        description: "Vous êtes maintenant connecté.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Erreur de connexion:', error);
      setLoginError(error.message);

      toast({
        title: 'Erreur de connexion.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Styles conditionnels pour le fond et les textes
  const cardBg = useColorModeValue('white', 'gray.700');
  const inputBg = useColorModeValue('gray.100', 'gray.600');
  const buttonBg = useColorModeValue('teal.400', 'teal.600');
  const buttonHoverBg = useColorModeValue('teal.500', 'teal.700');
  const headingColor = useColorModeValue('teal.600', 'teal.300');

  // Taille responsive des cercles (plus petits que le texte)
  const circleSize = useBreakpointValue({ base: '10px', md: '12px', lg: '14px' });

  return (
    <Flex
      align="center"
      justify="center"
      minH="100vh"
      bgGradient={useColorModeValue(
        'linear(to-r, teal.200, blue.400)',
        'linear(to-r, teal.700, blue.900)'
      )}
      p="4"
    >
      <MotionBox
        bg={cardBg}
        p="8"
        rounded="lg"
        boxShadow="lg"
        width={{ base: 'full', sm: 'md' }}
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Logo Centré et Agrandi */}
        <Flex justify="center" align="center" direction="column" mb="6">
          <Heading
            fontFamily="'Playlist Script', cursive"
            fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
            fontWeight="bold"
            color={headingColor}
            mb="4"
            textAlign="center"
          >
            Ma formation
          </Heading>
          <HStack spacing="4">
            <Circle size={circleSize} bg="#78b159" />
            <Circle size={circleSize} bg="#55acee" />
            <Circle size={circleSize} bg="#ff914d" />
          </HStack>
        </Flex>

        {/* Titre du Formulaire */}
        <Heading as="h2" size="lg" textAlign="center" mb="6" color="teal.600">
          Connexion Administrateur
        </Heading>

        {/* Affichage des Erreurs de Connexion */}
        {loginError && (
          <Alert status="error" mb="6" borderRadius="md">
            <AlertIcon />
            {loginError}
          </Alert>
        )}

        {/* Formulaire de Connexion */}
        <form onSubmit={handleSubmit}>
          <Stack spacing="4">
            {/* Champ Email */}
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdEmail color="gray.500" />
                </InputLeftElement>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez votre email"
                  bg={inputBg}
                  _focus={{ bg: 'white', borderColor: 'teal.400' }}
                />
              </InputGroup>
            </FormControl>

            {/* Champ Mot de Passe */}
            <FormControl id="password" isRequired>
              <FormLabel>Mot de passe</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdLock color="gray.500" />
                </InputLeftElement>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Entrez votre mot de passe"
                  bg={inputBg}
                  _focus={{ bg: 'white', borderColor: 'teal.400' }}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    aria-label={showPassword ? 'Masquer le mot de passe' : 'Afficher le mot de passe'}
                    icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {/* Option "Se Souvenir de Moi" */}
            <FormControl display="flex" alignItems="center">
              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                colorScheme="teal"
              >
                Se souvenir de moi
              </Checkbox>
            </FormControl>

            {/* Bouton de Connexion */}
            <Button
              colorScheme="teal"
              type="submit"
              width="full"
              isLoading={isSubmitting}
              loadingText="Connexion..."
              bg={buttonBg}
              _hover={{ bg: buttonHoverBg }}
            >
              Se connecter
            </Button>
          </Stack>
        </form>

        {/* Footer Optionnel */}
        <Flex mt="6" justify="center">
          <Text fontSize="sm" color="gray.600">
            © {new Date().getFullYear()} Ma formation. Tous droits réservés.
          </Text>
        </Flex>
      </MotionBox>
    </Flex>
  );
};

export default Login;
