// src/admin/pages/EditAides.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Stack,
  useToast,
  Spinner,
  Center,
  Checkbox,
  CheckboxGroup,
  VStack,
  HStack,
  Alert,           // Import ajouté
  AlertIcon,       // Import ajouté
  Text,
  Image,
} from '@chakra-ui/react'; // Assurez-vous d'importer Alert et AlertIcon
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getFirestore, collection, doc, getDoc, getDocs, updateDoc, Timestamp } from 'firebase/firestore'; // Import ajouté 'collection', 'getDocs', 'Timestamp'
import { storage } from '../../firebase'; // Assurez-vous que Firebase est correctement configuré
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const EditAides = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // ID de l'aide à modifier
  const toast = useToast();
  const db = getFirestore();

  const [financeurs, setFinanceurs] = useState([]);
  const [loadingFinanceurs, setLoadingFinanceurs] = useState(true);
  const [errorFinanceurs, setErrorFinanceurs] = useState(null);

  const [aide, setAide] = useState(null);
  const [loadingAide, setLoadingAide] = useState(true);
  const [errorAide, setErrorAide] = useState(null);

  // Charger les financeurs existants depuis Firestore
  useEffect(() => {
    const fetchFinanceurs = async () => {
      try {
        const financeursCol = collection(db, 'financeurs');
        const snapshot = await getDocs(financeursCol); // Utilisation de 'getDocs'
        const financeursList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFinanceurs(financeursList);
        setLoadingFinanceurs(false);
      } catch (error) {
        console.error('Erreur lors du chargement des financeurs:', error);
        setErrorFinanceurs('Impossible de charger les financeurs. Veuillez réessayer plus tard.');
        setLoadingFinanceurs(false);
      }
    };

    fetchFinanceurs();
  }, [db]);

  // Charger les détails de l'aide à modifier
  useEffect(() => {
    const fetchAide = async () => {
      try {
        const aideDocRef = doc(db, 'aidesFormation', id);
        const aideSnapshot = await getDoc(aideDocRef);
        if (aideSnapshot.exists()) {
          setAide({ id: aideSnapshot.id, ...aideSnapshot.data() });
        } else {
          setErrorAide('Aide non trouvée.');
        }
      } catch (error) {
        console.error('Erreur lors du chargement de l\'aide:', error);
        setErrorAide('Impossible de charger l\'aide. Veuillez réessayer plus tard.');
      } finally {
        setLoadingAide(false);
      }
    };

    fetchAide();
  }, [db, id]);

  // Schéma de validation avec Yup
  const AideSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Le titre doit contenir au moins 2 caractères')
      .max(100, 'Le titre ne peut pas dépasser 100 caractères')
      .required('Le titre est requis'),
    description: Yup.string()
      .max(1000, 'La description ne peut pas dépasser 1000 caractères')
      .required('La description est requise'),
    type: Yup.string()
      .oneOf(['Bourse', 'Subvention', 'Prêt', 'Autre'], 'Type invalide')
      .required('Le type est requis'),
    eligibility: Yup.string()
      .required('L\'éligibilité est requise'),
    criteria: Yup.string(),
    applicationProcess: Yup.string(),
    contactInfo: Yup.string(),
    imageUrl: Yup.string().url('URL invalide'),
    financeurs: Yup.array().of(Yup.string()),
  });

  // Fonction de soumission du formulaire
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSend = {
        title: values.title,
        description: values.description,
        type: values.type,
        eligibility: values.eligibility,
        criteria: values.criteria || '-',
        applicationProcess: values.applicationProcess || '-',
        contactInfo: values.contactInfo || '-',
        imageUrl: values.imageUrl || '-',
        financeurs: values.financeurs || [],
        updatedAt: Timestamp.now(), // Utilisation de 'Timestamp'
      };

      const aideDocRef = doc(db, 'aidesFormation', id);
      await updateDoc(aideDocRef, dataToSend);

      toast({
        title: 'Aide mise à jour.',
        description: 'Les informations de l\'aide ont été mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'aide:', error);
      toast({
        title: 'Erreur.',
        description: 'Une erreur est survenue lors de la mise à jour de l\'aide.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loadingFinanceurs || loadingAide) {
    return (
      <Center minH="100vh">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  if (errorFinanceurs || errorAide) {
    return (
      <Center minH="100vh">
        <Alert status="error">   {/* Utilisation de 'Alert' */}
          <AlertIcon />           {/* Utilisation de 'AlertIcon' */}
          {errorFinanceurs || errorAide}
        </Alert>
      </Center>
    );
  }

  return (
    <Flex direction="column" align="center" justify="center" minH="100vh" bgGradient="linear(to-b, teal.50, blue.50)" p="5">
      <Box
        bg="white"
        p="8"
        borderRadius="lg"
        boxShadow="2xl"
        maxW="700px"
        width="full"
      >
        <Heading as="h2" size="lg" color="teal.600" mb="6" textAlign="center">
          Modifier l'Aide à la Formation
        </Heading>
        <Formik
          initialValues={{
            title: aide.title || '',
            description: aide.description === '-' ? '' : aide.description,
            type: aide.type || '',
            eligibility: aide.eligibility || '',
            criteria: aide.criteria === '-' ? '' : aide.criteria,
            applicationProcess: aide.applicationProcess === '-' ? '' : aide.applicationProcess,
            contactInfo: aide.contactInfo === '-' ? '' : aide.contactInfo,
            imageUrl: aide.imageUrl || '',
            financeurs: aide.financeurs || [],
          }}
          validationSchema={AideSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <VStack spacing="4" align="flex-start">
                {/* Titre */}
                <FormControl isRequired>
                  <FormLabel htmlFor="title">Titre</FormLabel>
                  <Field
                    as={Input}
                    id="title"
                    name="title"
                    placeholder="Entrez le titre de l'aide"
                  />
                  <ErrorMessage name="title">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Description */}
                <FormControl isRequired>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Field
                    as={Textarea}
                    id="description"
                    name="description"
                    placeholder="Entrez la description de l'aide"
                  />
                  <ErrorMessage name="description">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Type */}
                <FormControl isRequired>
                  <FormLabel htmlFor="type">Type</FormLabel>
                  <Field as={Select} id="type" name="type" placeholder="Sélectionnez un type">
                    <option value="Bourse">Bourse</option>
                    <option value="Subvention">Subvention</option>
                    <option value="Prêt">Prêt</option>
                    <option value="Autre">Autre</option>
                  </Field>
                  <ErrorMessage name="type">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Éligibilité */}
                <FormControl isRequired>
                  <FormLabel htmlFor="eligibility">Éligibilité</FormLabel>
                  <Field
                    as={Textarea}
                    id="eligibility"
                    name="eligibility"
                    placeholder="Entrez les critères d'éligibilité"
                  />
                  <ErrorMessage name="eligibility">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Critères de Sélection */}
                <FormControl>
                  <FormLabel htmlFor="criteria">Critères de Sélection</FormLabel>
                  <Field
                    as={Textarea}
                    id="criteria"
                    name="criteria"
                    placeholder="Entrez les critères de sélection (optionnel)"
                  />
                  <ErrorMessage name="criteria">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Processus de Candidature */}
                <FormControl>
                  <FormLabel htmlFor="applicationProcess">Processus de Candidature</FormLabel>
                  <Field
                    as={Textarea}
                    id="applicationProcess"
                    name="applicationProcess"
                    placeholder="Entrez le processus de candidature (optionnel)"
                  />
                  <ErrorMessage name="applicationProcess">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Informations de Contact */}
                <FormControl>
                  <FormLabel htmlFor="contactInfo">Informations de Contact</FormLabel>
                  <Field
                    as={Input}
                    id="contactInfo"
                    name="contactInfo"
                    placeholder="Entrez les informations de contact (optionnel)"
                  />
                  <ErrorMessage name="contactInfo">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* URL de l'Image */}
                <FormControl>
                  <FormLabel htmlFor="imageUrl">URL de l'Image</FormLabel>
                  <Field
                    as={Input}
                    id="imageUrl"
                    name="imageUrl"
                    placeholder="Entrez l'URL de l'image (optionnel)"
                  />
                  <ErrorMessage name="imageUrl">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Sélection des Financeurs */}
                <FormControl>
                  <FormLabel htmlFor="financeurs">Financeurs Associés</FormLabel>
                  <CheckboxGroup
                    colorScheme="teal"
                    value={values.financeurs}
                    onChange={(values) => setFieldValue('financeurs', values)}
                  >
                    <HStack spacing="4" wrap="wrap">
                      {financeurs.map((financeur) => (
                        <Checkbox key={financeur.id} value={financeur.id}>
                          {financeur.nom}
                        </Checkbox>
                      ))}
                    </HStack>
                  </CheckboxGroup>
                  <ErrorMessage name="financeurs">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Bouton de Soumission */}
                <Button
                  type="submit"
                  colorScheme="teal"
                  isLoading={isSubmitting}
                  width="full"
                >
                  {isSubmitting ? 'En cours...' : 'Mettre à Jour l\'Aide'}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default EditAides;
