// src/admin/pages/EditExamTest.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Textarea,
  Image,
  useToast,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Tooltip,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Tag,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import {
  MdAdd,
  MdCloudUpload,
  MdDelete,
  MdArrowDropDown,
} from 'react-icons/md';
import { FaUniversity, FaRegLightbulb } from 'react-icons/fa'; // Additional Icons

const EditExamTest = () => {
  const navigate = useNavigate();
  const { examId } = useParams(); // Ensure the route includes :examId
  const toast = useToast();
  const db = getFirestore();
  const storage = getStorage();

  // States for the exam/test form
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [selectedFormations, setSelectedFormations] = useState([]);
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // States for formations
  const [formations, setFormations] = useState([]);
  const [isLoadingFormations, setIsLoadingFormations] = useState(true);

  // States for existing exams/tests
  const [exam, setExam] = useState(null);
  const [isLoadingExam, setIsLoadingExam] = useState(true);

  // States for exam/test types
  const [examTypes, setExamTypes] = useState([]);
  const [isLoadingExamTypes, setIsLoadingExamTypes] = useState(true);

  // States for epreuve types
  const [epreuveTypes, setEpreuveTypes] = useState([]);
  const [isLoadingEpreuveTypes, setIsLoadingEpreuveTypes] = useState(true);

  // States for resource types
  const [resourceTypes, setResourceTypes] = useState([]);
  const [isLoadingResourceTypes, setIsLoadingResourceTypes] = useState(true);

  // States for public types
  const [publicTypes, setPublicTypes] = useState([]);
  const [isLoadingPublicTypes, setIsLoadingPublicTypes] = useState(true);

  // States for forme types
  const [formeTypes, setFormeTypes] = useState([]);
  const [isLoadingFormeTypes, setIsLoadingFormeTypes] = useState(true);

  // States for mode types
  const [modesTypes, setModesTypes] = useState([]);
  const [isLoadingModesTypes, setIsLoadingModesTypes] = useState(true);

  // States for added epreuves
  const [epreuves, setEpreuves] = useState([]);

  // States for modals
  const {
    isOpen: isOpenAddType,
    onOpen: onOpenAddType,
    onClose: onCloseAddType,
  } = useDisclosure();
  const {
    isOpen: isOpenAddFormation,
    onOpen: onOpenAddFormation,
    onClose: onCloseAddFormation,
  } = useDisclosure();
  const {
    isOpen: isOpenAddEpreuveType,
    onOpen: onOpenAddEpreuveType,
    onClose: onCloseAddEpreuveType,
  } = useDisclosure();
  const {
    isOpen: isOpenAddResourceType,
    onOpen: onOpenAddResourceType,
    onClose: onCloseAddResourceType,
  } = useDisclosure();
  const {
    isOpen: isOpenAddPublicType,
    onOpen: onOpenAddPublicType,
    onClose: onCloseAddPublicType,
  } = useDisclosure();
  const {
    isOpen: isOpenAddFormeType,
    onOpen: onOpenAddFormeType,
    onClose: onCloseAddFormeType,
  } = useDisclosure();
  const {
    isOpen: isOpenAddModeType,
    onOpen: onOpenAddModeType,
    onClose: onCloseAddModeType,
  } = useDisclosure();

  // States for adding new types
  const [newExamType, setNewExamType] = useState('');
  const [newFormationTitle, setNewFormationTitle] = useState('');
  const [newFormationDescription, setNewFormationDescription] = useState('');
  const [newEpreuveType, setNewEpreuveType] = useState('');
  const [newResourceType, setNewResourceType] = useState('');
  const [newPublicType, setNewPublicType] = useState('');
  const [newFormeType, setNewFormeType] = useState('');
  const [newModeType, setNewModeType] = useState('');
  const [newResourceFormat, setNewResourceFormat] = useState('');
  const [newResourceSubFormat, setNewResourceSubFormat] = useState('');
  const [newResourceNumberOfPages, setNewResourceNumberOfPages] = useState('');

  // Function to fetch formations
  const fetchFormations = useCallback(() => {
    setIsLoadingFormations(true);
    const unsubscribe = onSnapshot(
      collection(db, 'formations'),
      (snapshot) => {
        const formationsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFormations(formationsList);
        setIsLoadingFormations(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des formations:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les formations.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingFormations(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch exam types
  const fetchExamTypes = useCallback(() => {
    setIsLoadingExamTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'examTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExamTypes(typesList);
        setIsLoadingExamTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types d\'examens/tests:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types d\'examens/tests.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingExamTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch epreuve types
  const fetchEpreuveTypes = useCallback(() => {
    setIsLoadingEpreuveTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'epreuveTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEpreuveTypes(typesList);
        setIsLoadingEpreuveTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types d\'épreuves:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types d\'épreuves.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingEpreuveTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch resource types
  const fetchResourceTypes = useCallback(() => {
    setIsLoadingResourceTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'resourceTypes'),
      (snapshot) => {
        const resourcesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResourceTypes(resourcesList);
        setIsLoadingResourceTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types de ressources:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types de ressources.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingResourceTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch public types
  const fetchPublicTypes = useCallback(() => {
    setIsLoadingPublicTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'publicTypes'),
      (snapshot) => {
        const publicsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPublicTypes(publicsList);
        setIsLoadingPublicTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types de publics:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types de publics.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingPublicTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch forme types
  const fetchFormeTypes = useCallback(() => {
    setIsLoadingFormeTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'formeTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFormeTypes(typesList);
        setIsLoadingFormeTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des types de forme:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les types de forme.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingFormeTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch modes types
  const fetchModesTypes = useCallback(() => {
    setIsLoadingModesTypes(true);
    const unsubscribe = onSnapshot(
      collection(db, 'modesTypes'),
      (snapshot) => {
        const typesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setModesTypes(typesList);
        setIsLoadingModesTypes(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des modes de passage:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les modes de passage.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingModesTypes(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Function to fetch the exam/test data to edit
  const fetchExam = useCallback(async () => {
    setIsLoadingExam(true);
    try {
      const examDoc = doc(db, 'exams', examId);
      const examSnapshot = await getDoc(examDoc);
      if (examSnapshot.exists()) {
        const examData = examSnapshot.data();
        setExam({ id: examSnapshot.id, ...examData });
        setName(examData.name || '');
        setType(examData.type || '');
        setSelectedFormations(examData.formations || []);
        setDescription(examData.description || '');
        setExistingFiles(examData.files || []);
        setEpreuves(epreuvesData(examData.epreuves || []));
      } else {
        toast({
          title: 'Examen/Test non trouvé.',
          description: 'L\'examen/test que vous essayez de modifier n\'existe pas.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        navigate('/admin/examTests'); // Redirect to the list if the exam does not exist
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'examen/test:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer l\'examen/test.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingExam(false);
    }
  }, [db, examId, navigate, toast]);

  // Function to structure epreuves with the new fields
  const epreuvesData = (existingEpreuves) => {
    return existingEpreuves.map((epreuve) => ({
      nom: epreuve.nom || '',
      type: epreuve.type || '',
      description: epreuve.description || '',
      resource: epreuve.resource || '',
      publics: epreuve.publics || [],
      tempsHeures: epreuve.tempsHeures || '', // New field for hours
      tempsMinutes: epreuve.tempsMinutes || '', // New field for minutes
      forme: epreuve.forme || '', // New field for form
      modes: epreuve.modes || [], // New field for modes of passage
      coefficient: epreuve.coefficient || 1, // New field for coefficient
    }));
  };

  useEffect(() => {
    fetchFormations();
    fetchExamTypes();
    fetchEpreuveTypes();
    fetchResourceTypes();
    fetchPublicTypes();
    fetchFormeTypes();
    fetchModesTypes();
    fetchExam();

    // Cleanup listeners if necessary
    return () => {
      // No persistent listeners to clean up here
    };
  }, [
    fetchFormations,
    fetchExamTypes,
    fetchEpreuveTypes,
    fetchResourceTypes,
    fetchPublicTypes,
    fetchFormeTypes,
    fetchModesTypes,
    fetchExam
  ]);

  // Handler for file changes
  const handleFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    const previews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFilePreviews(previews);
  };

  // Function to add a new epreuve
  const addEpreuve = () => {
    setEpreuves([
      ...epreuves,
      { 
        nom: '', 
        type: '', 
        description: '', 
        resource: '', 
        publics: [],
        tempsHeures: '', // Initialize hours field
        tempsMinutes: '', // Initialize minutes field
        forme: '', // Initialize form field
        modes: [], // Initialize modes field
        coefficient: 1, // Initialize coefficient field
      },
    ]);
  };

  // Function to remove an epreuve
  const removeEpreuve = (index) => {
    const updatedEpreuves = [...epreuves];
    updatedEpreuves.splice(index, 1);
    setEpreuves(updatedEpreuves);
  };

  // Function to delete an existing file
  const handleDeleteExistingFile = async (file, index) => {
    const confirmDelete = window.confirm(`Êtes-vous sûr de vouloir supprimer le fichier "${file.name}" ?`);
    if (!confirmDelete) return;

    try {
      // Delete the file from Firebase Storage
      const fileRef = ref(storage, file.url);
      await deleteObject(fileRef);

      // Update Firestore
      const updatedFiles = [...existingFiles];
      updatedFiles.splice(index, 1);
      await updateDoc(doc(db, 'exams', examId), {
        files: updatedFiles,
      });

      setExistingFiles(updatedFiles);

      toast({
        title: 'Fichier supprimé.',
        description: `Le fichier "${file.name}" a été supprimé avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du fichier:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le fichier. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Removed required fields validation
    // Now all fields are optional

    // Optionally, you can add validations for fields if they are filled
    // For example, validate that if tempsMinutes is filled, it's between 0 and 59

    // Validate epreuves only if they have any data
    for (let i = 0; i < epreuves.length; i++) {
      const epreuve = epreuves[i];
      
      // If all fields in an epreuve are empty, you might choose to skip it
      const isEmptyEpreuve = 
        !epreuve.nom && 
        !epreuve.type && 
        !epreuve.description && 
        !epreuve.resource && 
        epreuve.publics.length === 0 &&
        epreuve.tempsHeures === '' &&
        epreuve.tempsMinutes === '' &&
        !epreuve.forme &&
        epreuve.modes.length === 0 &&
        epreuve.coefficient === 1; // Assuming default is 1

      if (!isEmptyEpreuve) {
        // If any field is filled, perform validations

        // Optionally, validate that minutes are between 0 and 59
        if (epreuve.tempsMinutes !== '') {
          const minutes = parseInt(epreuve.tempsMinutes, 10);
          if (isNaN(minutes) || minutes < 0 || minutes > 59) {
            toast({
              title: 'Minutes invalides.',
              description: `Veuillez entrer des minutes valides (0-59) pour l'épreuve ${i + 1}.`,
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        }

        // Optionally, ensure that total time is positive if fields are filled
        if (epreuve.tempsHeures !== '' || epreuve.tempsMinutes !== '') {
          const hours = parseInt(epreuve.tempsHeures, 10) || 0;
          const minutes = parseInt(epreuve.tempsMinutes, 10) || 0;
          if (hours === 0 && minutes === 0) {
            toast({
              title: 'Temps invalide.',
              description: `Veuillez entrer un temps valide pour l'épreuve ${i + 1}.`,
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        }

        // Validate coefficient if it's been changed from default
        if (epreuve.coefficient !== '' && epreuve.coefficient !== 1) {
          const coefficient = parseFloat(epreuve.coefficient);
          if (isNaN(coefficient) || coefficient <= 0) {
            toast({
              title: 'Coefficient invalide.',
              description: `Veuillez entrer un coefficient valide (supérieur à 0) pour l'épreuve ${i + 1}.`,
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        }
      }
    }

    setIsSubmitting(true);

    try {
      // Handle new files
      const uploadedFilesURLs = [];
      if (files.length > 0) {
        for (const file of files) {
          const storageRef = ref(storage, `exams/${Date.now()}_${file.name}`);
          const snapshot = await uploadBytes(storageRef, file);
          const fileURL = await getDownloadURL(snapshot.ref);
          uploadedFilesURLs.push({
            name: file.name,
            url: fileURL,
          });
        }
      }

      // Optionally, convert hours and minutes to total minutes
      const epreuvesToStore = epreuves
        .filter(epreuve => {
          // Keep only epreuves that have at least one field filled
          return Object.values(epreuve).some(value => {
            if (Array.isArray(value)) return value.length > 0;
            return value !== '' && value !== 1;
          });
        })
        .map((epreuve) => ({
          ...epreuve,
          tempsHeures: epreuve.tempsHeures !== '' ? parseInt(epreuve.tempsHeures, 10) : null,
          tempsMinutes: epreuve.tempsMinutes !== '' ? parseInt(epreuve.tempsMinutes, 10) : null,
          coefficient: epreuve.coefficient !== '' ? parseFloat(epreuve.coefficient) : 1,
        }));

      // Update the document in Firestore
      await updateDoc(doc(db, 'exams', examId), {
        name,
        type,
        formations: selectedFormations,
        description,
        // Merge existing and new files
        files: [...existingFiles, ...uploadedFilesURLs],
        epreuves: epreuvesToStore, // Update epreuves with the new fields
        updatedAt: new Date(),
      });

      // Reset file states
      setFiles([]);
      setFilePreviews([]);

      toast({
        title: 'Examen/Test mis à jour.',
        description: `L'examen/test "${name}" a été mis à jour avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'examen/test:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de mettre à jour l\'examen/test. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to handle adding a new exam type
  const handleAddExamType = async () => {
    if (!newExamType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type d\'examen/test.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'examTypes'), {
        name: newExamType,
      });
      toast({
        title: 'Type ajouté.',
        description: `Le type "${newExamType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewExamType('');
      onCloseAddType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type d\'examen/test:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new formation
  const handleAddFormation = async () => {
    if (!newFormationTitle) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer le titre de la formation.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'formations'), {
        title: newFormationTitle,
        description: newFormationDescription,
        createdAt: new Date(),
      });
      toast({
        title: 'Formation ajoutée.',
        description: `La formation "${newFormationTitle}" a été ajoutée avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewFormationTitle('');
      setNewFormationDescription('');
      onCloseAddFormation();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la formation:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter la formation. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new epreuve type
  const handleAddEpreuveType = async () => {
    if (!newEpreuveType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type d\'épreuve.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'epreuveTypes'), {
        name: newEpreuveType,
      });
      toast({
        title: 'Type d\'épreuve ajouté.',
        description: `Le type d'épreuve "${newEpreuveType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewEpreuveType('');
      onCloseAddEpreuveType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type d\'épreuve:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type d\'épreuve. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new resource type
  const handleAddResourceType = async () => {
    if (!newResourceType || !newResourceFormat || !newResourceSubFormat) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez remplir tous les champs du type de ressource.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'resourceTypes'), {
        name: newResourceType,
        format: newResourceFormat,
        subFormat: newResourceSubFormat,
        numberOfPages: newResourceNumberOfPages || null, // null if not applicable
      });
      toast({
        title: 'Type de ressource ajouté.',
        description: `Le type de ressource "${newResourceType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Reset fields
      setNewResourceType('');
      setNewResourceFormat('');
      setNewResourceSubFormat('');
      setNewResourceNumberOfPages('');
      onCloseAddResourceType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type de ressource:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type de ressource. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new public type
  const handleAddPublicType = async () => {
    if (!newPublicType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type de public.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'publicTypes'), {
        name: newPublicType,
      });
      toast({
        title: 'Type de public ajouté.',
        description: `Le type de public "${newPublicType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewPublicType('');
      onCloseAddPublicType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type de public:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type de public. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new forme type
  const handleAddFormeType = async () => {
    if (!newFormeType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type de forme.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'formeTypes'), {
        name: newFormeType,
      });
      toast({
        title: 'Type de forme ajouté.',
        description: `Le type de forme "${newFormeType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewFormeType('');
      onCloseAddFormeType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du type de forme:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le type de forme. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle adding a new mode type
  const handleAddModeType = async () => {
    if (!newModeType) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez entrer un type de mode de passage.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await addDoc(collection(db, 'modesTypes'), {
        name: newModeType,
      });
      toast({
        title: 'Mode de passage ajouté.',
        description: `Le mode de passage "${newModeType}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewModeType('');
      onCloseAddModeType();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du mode de passage:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le mode de passage. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to add a public to an epreuve
  const addPublicToEpreuve = (epreuveIndex, publicOption) => {
    const updatedEpreuves = [...epreuves];
    if (!updatedEpreuves[epreuveIndex].publics.includes(publicOption.value)) {
      updatedEpreuves[epreuveIndex].publics.push(publicOption.value);
      setEpreuves(updatedEpreuves);
    }
  };

  // Function to remove a public from an epreuve
  const removePublicFromEpreuve = (epreuveIndex, publicOption) => {
    const updatedEpreuves = [...epreuves];
    updatedEpreuves[epreuveIndex].publics = updatedEpreuves[epreuveIndex].publics.filter(
      (publicItem) => publicItem !== publicOption
    );
    setEpreuves(updatedEpreuves);
  };

  // Function to handle adding a public via select component (if needed)
  // Not used in this implementation since we use the Select's built-in multi-select

  if (
    isLoadingExam ||
    isLoadingFormations ||
    isLoadingExamTypes ||
    isLoadingEpreuveTypes ||
    isLoadingResourceTypes ||
    isLoadingPublicTypes ||
    isLoadingFormeTypes ||
    isLoadingModesTypes
  ) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  return (
    <Flex direction="column" align="center" justify="start" minH="100vh" bgGradient="linear(to-b, teal.50, blue.50)" p="10">
      {/* Header */}
      <Card
        w="full"
        maxW="6xl"
        mb="8"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        transition="all 0.3s ease"
        _hover={{ boxShadow: 'dark-lg' }}
      >
        <CardHeader p="6">
          <Flex justify="space-between" align="center">
            <Heading as="h2" size="lg" color="teal.600">
              Modifier l'Examen/Test
            </Heading>
            <Button
              onClick={() => navigate('/admin/dashboard')}
              colorScheme="blue"
              leftIcon={<MdAdd />}
              variant="outline"
            >
              Retour au Tableau de Bord
            </Button>
          </Flex>
        </CardHeader>
      </Card>

      {/* Edit Form */}
      <Card
        w="full"
        maxW="6xl"
        mb="8"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        p="6"
      >
        <CardBody>
          <form onSubmit={handleSubmit}>
            {/* Exam/Test Name */}
            <FormControl id="name" mb="4">
              <FormLabel>Nom de l'Examen/Test</FormLabel>
              <Input
                placeholder="Entrez le nom de l'examen/test"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            {/* Exam/Test Type */}
            <FormControl id="type" mb="4">
              <FormLabel>Type d'Examen/Test</FormLabel>
              <Flex align="center">
                <ChakraSelect
                  placeholder="Sélectionnez le type d'examen/test"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  {examTypes.map((examType) => (
                    <option key={examType.id} value={examType.name}>
                      {examType.name}
                    </option>
                  ))}
                </ChakraSelect>
                <Button
                  onClick={onOpenAddType}
                  colorScheme="green"
                  ml="2"
                  leftIcon={<MdAdd />}
                >
                  Ajouter Type
                </Button>
              </Flex>
            </FormControl>

            {/* Associated Formations */}
            <FormControl id="formations" mb="4">
              <FormLabel>Formation(s) Associée(s)</FormLabel>
              <Flex align="center">
                <Select
                  isMulti
                  name="formations"
                  options={formations.map((formation) => ({
                    value: formation.id,
                    label: formation.title,
                  }))}
                  value={selectedFormations.map((id) => {
                    const formation = formations.find((f) => f.id === id);
                    return formation ? { value: formation.id, label: formation.title } : null;
                  }).filter(Boolean)}
                  onChange={(selectedOptions) => {
                    setSelectedFormations(selectedOptions.map((option) => option.value));
                  }}
                  placeholder="Sélectionnez des formations"
                  chakraStyles={{
                    control: (provided) => ({
                      ...provided,
                      width: '300px',
                    }),
                  }}
                />
                <Button
                  onClick={onOpenAddFormation}
                  colorScheme="green"
                  ml="2"
                  leftIcon={<MdAdd />}
                >
                  Ajouter Formation
                </Button>
              </Flex>
            </FormControl>

            {/* Epreuves Section */}
            <Box mb="4" w="100%">
              <Flex justify="space-between" align="center" mb="2">
                <FormLabel>Épreuves</FormLabel>
                <Button
                  onClick={addEpreuve}
                  colorScheme="purple"
                  size="sm"
                  leftIcon={<MdAdd />}
                >
                  Ajouter Épreuve
                </Button>
              </Flex>
              {epreuves.map((epreuve, index) => (
                <Box key={index} p="4" mb="2" borderWidth="1px" borderRadius="md" bg="gray.50">
                  <Flex justify="space-between" align="center" mb="2">
                    <Heading as="h4" size="sm" color="gray.700">
                      Épreuve {index + 1}
                    </Heading>
                    <IconButton
                      icon={<MdDelete />}
                      colorScheme="red"
                      size="sm"
                      onClick={() => removeEpreuve(index)}
                      aria-label="Supprimer Épreuve"
                    />
                  </Flex>

                  {/* Epreuve Name */}
                  <FormControl id={`epreuve-name-${index}`} mb="4">
                    <FormLabel>Nom de l'Épreuve</FormLabel>
                    <Input
                      placeholder="Entrez le nom de l'épreuve"
                      value={epreuve.nom}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].nom = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                    />
                  </FormControl>

                  {/* Epreuve Type */}
                  <FormControl id={`epreuve-type-${index}`} mb="4">
                    <FormLabel>Type d'Épreuve</FormLabel>
                    <Flex align="center">
                      <ChakraSelect
                        placeholder="Sélectionnez le type d'épreuve"
                        value={epreuve.type}
                        onChange={(e) => {
                          const updatedEpreuves = [...epreuves];
                          updatedEpreuves[index].type = e.target.value;
                          setEpreuves(updatedEpreuves);
                        }}
                      >
                        {epreuveTypes.map((etype) => (
                          <option key={etype.id} value={etype.name}>
                            {etype.name}
                          </option>
                        ))}
                      </ChakraSelect>
                      <Button
                        onClick={onOpenAddEpreuveType}
                        colorScheme="green"
                        ml="2"
                        size="sm"
                        leftIcon={<MdAdd />}
                      >
                        Ajouter Type
                      </Button>
                    </Flex>
                  </FormControl>

                  {/* Epreuve Description */}
                  <FormControl id={`epreuve-description-${index}`} mb="4">
                    <FormLabel>Description de l'Épreuve</FormLabel>
                    <Textarea
                      placeholder="Entrez une description de l'épreuve"
                      value={epreuve.description}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].description = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                    />
                  </FormControl>

                  {/* Epreuve Temps - Heures */}
                  <FormControl id={`epreuve-temps-heures-${index}`} mb="4">
                    <FormLabel>Temps - Heures</FormLabel>
                    <Input
                      type="number"
                      placeholder="Heures"
                      value={epreuve.tempsHeures}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].tempsHeures = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                      min="0"
                    />
                  </FormControl>

                  {/* Epreuve Temps - Minutes */}
                  <FormControl id={`epreuve-temps-minutes-${index}`} mb="4">
                    <FormLabel>Temps - Minutes</FormLabel>
                    <Input
                      type="number"
                      placeholder="Minutes"
                      value={epreuve.tempsMinutes}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].tempsMinutes = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                      min="0"
                      max="59"
                    />
                  </FormControl>

                  {/* Epreuve Forme */}
                  <FormControl id={`epreuve-forme-${index}`} mb="4">
                    <FormLabel>Forme de l'Épreuve</FormLabel>
                    <Flex align="center">
                      <ChakraSelect
                        placeholder="Sélectionnez la forme de l'épreuve"
                        value={epreuve.forme}
                        onChange={(e) => {
                          const updatedEpreuves = [...epreuves];
                          updatedEpreuves[index].forme = e.target.value;
                          setEpreuves(updatedEpreuves);
                        }}
                      >
                        {formeTypes.map((forme) => (
                          <option key={forme.id} value={forme.name}>
                            {forme.name}
                          </option>
                        ))}
                      </ChakraSelect>
                      <Button
                        onClick={onOpenAddFormeType}
                        colorScheme="green"
                        ml="2"
                        size="sm"
                        leftIcon={<MdAdd />}
                      >
                        Ajouter Forme
                      </Button>
                    </Flex>
                  </FormControl>

                  {/* Epreuve Resource */}
                  <FormControl id={`epreuve-resource-${index}`} mb="4">
                    <FormLabel>Ressource à Rendre</FormLabel>
                    <Flex align="center">
                      <ChakraSelect
                        placeholder="Sélectionnez une ressource"
                        value={epreuve.resource}
                        onChange={(e) => {
                          const updatedEpreuves = [...epreuves];
                          updatedEpreuves[index].resource = e.target.value;
                          setEpreuves(updatedEpreuves);
                        }}
                      >
                        {resourceTypes.map((rtype) => (
                          <option key={rtype.id} value={rtype.name}>
                            {rtype.name}
                          </option>
                        ))}
                      </ChakraSelect>
                      <Button
                        onClick={onOpenAddResourceType}
                        colorScheme="green"
                        ml="2"
                        size="sm"
                        leftIcon={<MdAdd />}
                      >
                        Ajouter Ressource
                      </Button>
                    </Flex>
                  </FormControl>

                  {/* Epreuve Publics */}
                  <FormControl id={`epreuve-publics-${index}`} mb="4">
                    <FormLabel>Public Concerné</FormLabel>
                    <Select
                      isMulti
                      name="publics"
                      options={publicTypes.map((ptype) => ({
                        value: ptype.name,
                        label: ptype.name,
                      }))}
                      value={epreuve.publics.map((publicItem) => ({
                        value: publicItem,
                        label: publicItem,
                      }))}
                      onChange={(selectedOptions) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].publics = selectedOptions.map((option) => option.value);
                        setEpreuves(updatedEpreuves);
                      }}
                      placeholder="Sélectionnez des publics concernés"
                      chakraStyles={{
                        control: (provided) => ({
                          ...provided,
                          width: '300px',
                        }),
                      }}
                      components={{
                        DropdownIndicator: (props) => (
                          <MdArrowDropDown {...props} />
                        ),
                      }}
                      closeMenuOnSelect={false}
                    />
                    <Button
                      onClick={onOpenAddPublicType}
                      colorScheme="green"
                      mt="2"
                      size="sm"
                      leftIcon={<MdAdd />}
                    >
                      Ajouter Public
                    </Button>
                  </FormControl>

                  {/* Epreuve Modes of Passage */}
                  <FormControl id={`epreuve-modes-${index}`} mb="4">
                    <FormLabel>Mode de Passage</FormLabel>
                    <Select
                      isMulti
                      name="modes"
                      options={modesTypes.map((mode) => ({
                        value: mode.name,
                        label: mode.name,
                      }))}
                      value={epreuve.modes.map((modeItem) => ({
                        value: modeItem,
                        label: modeItem,
                      }))}
                      onChange={(selectedOptions) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].modes = selectedOptions.map((option) => option.value);
                        setEpreuves(updatedEpreuves);
                      }}
                      placeholder="Sélectionnez les modes de passage"
                      chakraStyles={{
                        control: (provided) => ({
                          ...provided,
                          width: '300px',
                        }),
                      }}
                      components={{
                        DropdownIndicator: (props) => (
                          <MdArrowDropDown {...props} />
                        ),
                      }}
                      closeMenuOnSelect={false}
                    />
                    <Button
                      onClick={onOpenAddModeType}
                      colorScheme="green"
                      mt="2"
                      size="sm"
                      leftIcon={<MdAdd />}
                    >
                      Ajouter Mode
                    </Button>
                  </FormControl>

                  {/* Epreuve Coefficient */}
                  <FormControl id={`epreuve-coefficient-${index}`} mb="4">
                    <FormLabel>Coefficient</FormLabel>
                    <Input
                      type="number"
                      placeholder="Entrez le coefficient de l'épreuve"
                      value={epreuve.coefficient}
                      onChange={(e) => {
                        const updatedEpreuves = [...epreuves];
                        updatedEpreuves[index].coefficient = e.target.value;
                        setEpreuves(updatedEpreuves);
                      }}
                      min="0"
                      step="0.5"
                    />
                  </FormControl>
                </Box>
              ))}
              {epreuves.length === 0 && (
                <Text color="gray.500">Aucune épreuve ajoutée.</Text>
              )}
            </Box>

            {/* Description (Optional) */}
            <FormControl id="description" mb="4">
              <FormLabel>Description (facultatif)</FormLabel>
              <Textarea
                placeholder="Entrez une description de l'examen/test"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            {/* Associated Files (Optional) */}
            <FormControl id="files" mb="4">
              <FormLabel>Fichiers Associés (facultatif)</FormLabel>
              <Input
                type="file"
                accept="application/pdf, image/*"
                multiple
                onChange={handleFilesChange}
              />
              {existingFiles.length > 0 && (
                <Box mt="4">
                  <Text fontWeight="bold" mb="2">Fichiers Existants :</Text>
                  <Box display="flex" flexWrap="wrap">
                    {existingFiles.map((file, index) => (
                      <Box key={index} position="relative" mr="2" mb="2">
                        {file.url.endsWith('.pdf') ? (
                          <Box
                            width="100px"
                            height="100px"
                            bg="gray.200"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="md"
                          >
                            <Text>PDF</Text>
                          </Box>
                        ) : (
                          <Image
                            src={file.url}
                            alt={`Fichier ${index + 1}`}
                            boxSize="100px"
                            objectFit="cover"
                            borderRadius="md"
                          />
                        )}
                        <IconButton
                          icon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          position="absolute"
                          top="0"
                          right="0"
                          onClick={() => handleDeleteExistingFile(file, index)}
                          aria-label="Supprimer Fichier"
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              {filePreviews.length > 0 && (
                <Box mt="4">
                  <Text fontWeight="bold" mb="2">Nouveaux Fichiers :</Text>
                  <Box display="flex" flexWrap="wrap">
                    {filePreviews.map((preview, index) => (
                      <Image
                        key={index}
                        src={preview}
                        alt={`Aperçu du fichier ${index + 1}`}
                        boxSize="100px"
                        objectFit="cover"
                        mr="2"
                        mb="2"
                        borderRadius="md"
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </FormControl>

            {/* Submit Button */}
            <Button
              type="submit"
              colorScheme="teal"
              leftIcon={<MdCloudUpload />}
              isLoading={isSubmitting}
              loadingText="Mise à jour..."
              width="full"
            >
              Mettre à Jour l'Examen/Test
            </Button>
          </form>
        </CardBody>
      </Card>

      {/* Modals for Adding New Types and Formations */}
      
      {/* Modal to add a new exam/test type */}
      <Modal isOpen={isOpenAddType} onClose={onCloseAddType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type d'Examen/Test</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newExamType">
              <FormLabel>Nom du Type</FormLabel>
              <Input
                placeholder="Entrez le nom du type d'examen/test"
                value={newExamType}
                onChange={(e) => setNewExamType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddExamType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewExamType(''); onCloseAddType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new formation */}
      <Modal isOpen={isOpenAddFormation} onClose={onCloseAddFormation}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une Nouvelle Formation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newFormationTitle" mb="4">
              <FormLabel>Titre de la Formation</FormLabel>
              <Input
                placeholder="Entrez le titre de la formation"
                value={newFormationTitle}
                onChange={(e) => setNewFormationTitle(e.target.value)}
              />
            </FormControl>
            <FormControl id="newFormationDescription" mb="4">
              <FormLabel>Description de la Formation (facultatif)</FormLabel>
              <Textarea
                placeholder="Entrez une description de la formation"
                value={newFormationDescription}
                onChange={(e) => setNewFormationDescription(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddFormation}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewFormationTitle(''); setNewFormationDescription(''); onCloseAddFormation(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new epreuve type */}
      <Modal isOpen={isOpenAddEpreuveType} onClose={onCloseAddEpreuveType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type d'Épreuve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newEpreuveType">
              <FormLabel>Nom du Type d'Épreuve</FormLabel>
              <Input
                placeholder="Entrez le nom du type d'épreuve"
                value={newEpreuveType}
                onChange={(e) => setNewEpreuveType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddEpreuveType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewEpreuveType(''); onCloseAddEpreuveType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new resource type */}
      <Modal isOpen={isOpenAddResourceType} onClose={onCloseAddResourceType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type de Ressource</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newResourceType" mb="4">
              <FormLabel>Nom de la Ressource</FormLabel>
              <Input
                placeholder="Entrez le nom de la ressource"
                value={newResourceType}
                onChange={(e) => setNewResourceType(e.target.value)}
              />
            </FormControl>
            <FormControl id="newResourceFormat" mb="4">
              <FormLabel>Format</FormLabel>
              <Input
                placeholder="Exemple : Vidéo, Projet"
                value={newResourceFormat}
                onChange={(e) => setNewResourceFormat(e.target.value)}
              />
            </FormControl>
            <FormControl id="newResourceSubFormat" mb="4">
              <FormLabel>Sous-Format</FormLabel>
              <Input
                placeholder="Exemple : Dossier, Création/Conception, Rapport"
                value={newResourceSubFormat}
                onChange={(e) => setNewResourceSubFormat(e.target.value)}
              />
            </FormControl>
            <FormControl id="newResourceNumberOfPages" mb="4">
              <FormLabel>Nombre de Pages (pour rapports écrits)</FormLabel>
              <Input
                type="number"
                placeholder="Entrez le nombre de pages"
                value={newResourceNumberOfPages}
                onChange={(e) => setNewResourceNumberOfPages(e.target.value)}
                min="1"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddResourceType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewResourceType(''); setNewResourceFormat(''); setNewResourceSubFormat(''); setNewResourceNumberOfPages(''); onCloseAddResourceType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new public type */}
      <Modal isOpen={isOpenAddPublicType} onClose={onCloseAddPublicType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type de Public</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newPublicType">
              <FormLabel>Nom du Type de Public</FormLabel>
              <Input
                placeholder="Entrez le nom du type de public"
                value={newPublicType}
                onChange={(e) => setNewPublicType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddPublicType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewPublicType(''); onCloseAddPublicType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new forme type */}
      <Modal isOpen={isOpenAddFormeType} onClose={onCloseAddFormeType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Type de Forme</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newFormeType">
              <FormLabel>Nom du Type de Forme</FormLabel>
              <Input
                placeholder="Entrez le nom du type de forme"
                value={newFormeType}
                onChange={(e) => setNewFormeType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddFormeType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewFormeType(''); onCloseAddFormeType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to add a new mode type */}
      <Modal isOpen={isOpenAddModeType} onClose={onCloseAddModeType}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Mode de Passage</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="newModeType">
              <FormLabel>Nom du Mode de Passage</FormLabel>
              <Input
                placeholder="Entrez le nom du mode de passage"
                value={newModeType}
                onChange={(e) => setNewModeType(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddModeType}>
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => { setNewModeType(''); onCloseAddModeType(); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EditExamTest;
