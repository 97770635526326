// src/admin/pages/ViewFormationFile.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast,
  Flex,
  Textarea,
  Spinner,
  Divider,
  Icon,
  HStack,
  Image,
  Text,
  Center,
} from '@chakra-ui/react';
import { FaInfoCircle, FaPlus, FaTrash } from 'react-icons/fa';
import { Select as ReactSelect, CreatableSelect } from 'chakra-react-select'; // Import correct
import { components } from 'react-select';
import * as MdIcons from 'react-icons/md'; // Import des icônes Material Design pour les voies d'accès

const ViewFormationFile = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();

  // États pour la création de Formation
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [prerequisites, setPrerequisites] = useState('');
  const [programme, setProgramme] = useState('');
  const [entranceTests, setEntranceTests] = useState('');
  const [certifierId, setCertifierId] = useState('');
  const [codeMetierId, setCodeMetierId] = useState('');
  const [competences, setCompetences] = useState(['']);

  // Nouveaux états ajoutés
  const [dueDate, setDueDate] = useState(''); // Date d'échéance
  const [codeRNCP, setCodeRNCP] = useState(''); // Code RNCP

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingCertifiers, setIsLoadingCertifiers] = useState(true);
  const [isLoadingCodeMetiers, setIsLoadingCodeMetiers] = useState(true);
  const [isLoadingAccess, setIsLoadingAccess] = useState(true); // Nouvel état pour le chargement des voies d'accès

  // États pour les Certificateurs, Codes Métiers, Voies d'Accès, Nomenclatures, Code NSF, Formacodes
  const [certifiers, setCertifiers] = useState([]);
  const [codeMetiers, setCodeMetiers] = useState([]);
  const [accessList, setAccessList] = useState([]); // Liste des voies d'accès

  // Nouveaux états pour Nomenclatures, Code NSF, Formacodes
  const [nomenclatures, setNomenclatures] = useState([]);
  const [selectedNomenclature, setSelectedNomenclature] = useState(null);

  const [codeNSFs, setCodeNSFs] = useState([]);
  const [selectedCodeNSFs, setSelectedCodeNSFs] = useState([]);

  const [formacodes, setFormacodes] = useState([]);
  const [selectedFormacodes, setSelectedFormacodes] = useState([]);

  // États pour la gestion des Examens
  const [exams, setExams] = useState(['']);
  const [isSubmittingExams, setIsSubmittingExams] = useState(false);

  // États pour les Voies d'Accès sélectionnées
  const [accessIds, setAccessIds] = useState([]);

  // États pour Blocs de Compétences
  const [blocsCompetences, setBlocsCompetences] = useState([
    {
      nomBloc: '',
      competences: [''],
      modalitesEvaluation: [''],
    },
  ]);

  // Fonction pour charger les Certificateurs
  const fetchCertifiers = useCallback(() => {
    setIsLoadingCertifiers(true);
    const unsubscribe = onSnapshot(
      collection(db, 'certificateurs'),
      (snapshot) => {
        const certifiersList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCertifiers(certifiersList);
        setIsLoadingCertifiers(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des certificateurs:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les certificateurs.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingCertifiers(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour charger les Codes Métiers
  const fetchCodeMetiers = useCallback(() => {
    setIsLoadingCodeMetiers(true);
    const unsubscribe = onSnapshot(
      collection(db, 'ficheMetiers'),
      (snapshot) => {
        const codeMetiersList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCodeMetiers(codeMetiersList);
        setIsLoadingCodeMetiers(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des codes métiers:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les codes métiers.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingCodeMetiers(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour charger les Voies d'Accès
  const fetchAccess = useCallback(() => {
    setIsLoadingAccess(true);
    const unsubscribe = onSnapshot(
      collection(db, 'acces'),
      (snapshot) => {
        const accessData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAccessList(accessData);
        setIsLoadingAccess(false);
      },
      (error) => {
        console.error('Erreur lors de la récupération des voies d\'accès:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les voies d\'accès.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingAccess(false);
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour charger les Nomenclatures
  const fetchNomenclatures = useCallback(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'nomenclatures'),
      (snapshot) => {
        const nomenclaturesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNomenclatures(nomenclaturesList);
      },
      (error) => {
        console.error('Erreur lors de la récupération des nomenclatures:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les nomenclatures.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour charger les Code NSFs
  const fetchCodeNSFs = useCallback(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'codeNSFs'),
      (snapshot) => {
        const codeNSFsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCodeNSFs(codeNSFsList);
      },
      (error) => {
        console.error('Erreur lors de la récupération des Code NSFs:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les Code NSFs.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  // Fonction pour charger les Formacodes
  const fetchFormacodes = useCallback(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'formacodes'),
      (snapshot) => {
        const formacodesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFormacodes(formacodesList);
      },
      (error) => {
        console.error('Erreur lors de la récupération des Formacodes:', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les Formacodes.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );

    return () => unsubscribe();
  }, [db, toast]);

  useEffect(() => {
    fetchCertifiers();
    fetchCodeMetiers();
    fetchAccess(); // Charger les voies d'accès au montage du composant
    fetchNomenclatures();
    fetchCodeNSFs();
    fetchFormacodes();
  }, [fetchCertifiers, fetchCodeMetiers, fetchAccess, fetchNomenclatures, fetchCodeNSFs, fetchFormacodes]);

  // Fonction de soumission du formulaire de formation
  const handleSubmitFormation = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Valider les champs requis
    if (!title.trim() || !description.trim()) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    // Valider les blocs de compétences
    for (let i = 0; i < blocsCompetences.length; i++) {
      const bloc = blocsCompetences[i];
      if (!bloc.nomBloc.trim()) {
        toast({
          title: 'Erreur',
          description: `Veuillez remplir le nom du bloc de compétences n°${i + 1}.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsSubmitting(false);
        return;
      }
      for (let j = 0; j < bloc.competences.length; j++) {
        if (!bloc.competences[j].trim()) {
          toast({
            title: 'Erreur',
            description: `Veuillez remplir la compétence n°${j + 1} du bloc de compétences n°${i + 1}.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setIsSubmitting(false);
          return;
        }
      }
      for (let k = 0; k < bloc.modalitesEvaluation.length; k++) {
        if (!bloc.modalitesEvaluation[k].trim()) {
          toast({
            title: 'Erreur',
            description: `Veuillez remplir la modalité d'évaluation n°${k + 1} du bloc de compétences n°${i + 1}.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setIsSubmitting(false);
          return;
        }
      }
    }

    const formationData = {
      title: title.trim(),
      description: description.trim(),
      prerequisites: prerequisites.trim(),
      programme: programme.trim(),
      entranceTests: entranceTests.trim(),
      certifierId,
      codeMetierId,
      competences,
      accessIds, // Inclusion des voies d'accès sélectionnées
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),

      // Ajout des nouveaux champs
      dueDate: dueDate ? new Date(dueDate) : null,
      codeRNCP: codeRNCP.trim(),
      nomenclatureId: selectedNomenclature ? selectedNomenclature.value : null,
      codeNSFIds: selectedCodeNSFs.map((codeNSF) => codeNSF.value),
      formacodeIds: selectedFormacodes.map((formacode) => formacode.value),
      blocsCompetences: blocsCompetences.map((bloc) => ({
        nomBloc: bloc.nomBloc.trim(),
        competences: bloc.competences.map((comp) => comp.trim()),
        modalitesEvaluation: bloc.modalitesEvaluation.map((mod) => mod.trim()),
      })),
    };

    try {
      // Ajout d'une nouvelle formation
      const formationRef = await addDoc(collection(db, 'formations'), formationData);
      toast({
        title: 'Formation ajoutée.',
        description: 'La formation a été ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Redirection vers la gestion des examens pour cette formation
      navigate(`/admin/formations/${formationRef.id}/exams`);
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de soumettre le formulaire. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Préparer les options pour react-select avec logo
  const certifierOptions = certifiers.map((cert) => ({
    value: cert.id,
    label: cert.name,
    logoURL: cert.logoURL,
  }));

  // Préparer les options pour les voies d'accès avec icônes
  const accessOptions = accessList.map((access) => ({
    value: access.id,
    label: access.path,
    icon: access.icon, // Nom de l'icône stocké dans Firestore
  }));

  // Préparer les options pour Nomenclatures
  const nomenclatureOptions = nomenclatures.map((nom) => ({
    value: nom.id,
    label: nom.nom,
  }));

  // Préparer les options pour Code NSFs
  const codeNSFOptions = codeNSFs.map((codeNSF) => ({
    value: codeNSF.id,
    label: codeNSF.code,
  }));

  // Préparer les options pour Formacodes
  const formacodeOptions = formacodes.map((formacode) => ({
    value: formacode.id,
    label: formacode.code,
  }));

  // Custom Option avec logo pour les Certificateurs
  const CustomCertifierOption = (props) => (
    <components.Option {...props}>
      <Flex align="center">
        {props.data.logoURL && (
          <Image
            src={props.data.logoURL}
            alt={`${props.data.label} Logo`}
            boxSize="30px"
            objectFit="contain"
            mr={2}
          />
        )}
        <Text>{props.data.label}</Text>
      </Flex>
    </components.Option>
  );

  // Custom SingleValue avec logo pour les Certificateurs
  const CustomCertifierSingleValue = (props) => (
    <components.SingleValue {...props}>
      <Flex align="center">
        {props.data.logoURL && (
          <Image
            src={props.data.logoURL}
            alt={`${props.data.label} Logo`}
            boxSize="30px"
            objectFit="contain"
            mr={2}
          />
        )}
        <Text>{props.data.label}</Text>
      </Flex>
    </components.SingleValue>
  );

  // Custom Option avec icône pour les Voies d'Accès
  const CustomAccessOption = (props) => {
    const IconComponent = MdIcons[props.data.icon] || MdIcons.MdTrendingUp; // Icône par défaut
    return (
      <components.Option {...props}>
        <Flex align="center">
          <Icon as={IconComponent} w={5} h={5} color="teal.500" mr={2} />
          <Text>{props.data.label}</Text>
        </Flex>
      </components.Option>
    );
  };

  // Custom MultiValue avec icône pour les Voies d'Accès
  const CustomAccessMultiValue = (props) => {
    const IconComponent = MdIcons[props.data.icon] || MdIcons.MdTrendingUp; // Icône par défaut
    return (
      <components.MultiValue {...props}>
        <Flex align="center">
          <Icon as={IconComponent} w={4} h={4} color="teal.500" mr="1" />
          <Text>{props.data.label}</Text>
        </Flex>
      </components.MultiValue>
    );
  };

  // Custom Option pour les creatable selects (Nomenclature, Code NSF, Formacode)
  const CustomCreatableOption = (props) => (
    <components.Option {...props}>
      <Text>{props.data.label}</Text>
    </components.Option>
  );

  // Fonction pour gérer la création d'une nouvelle Nomenclature
  const handleCreateNomenclature = async (inputValue) => {
    try {
      const docRef = await addDoc(collection(db, 'nomenclatures'), {
        nom: inputValue,
        createdAt: serverTimestamp(),
      });
      const newOption = { value: docRef.id, label: inputValue };
      setNomenclatures((prev) => [...prev, { id: docRef.id, nom: inputValue }]);
      setSelectedNomenclature(newOption);
      toast({
        title: 'Nomenclature ajoutée.',
        description: `La nomenclature "${inputValue}" a été ajoutée avec succès.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création de la nomenclature:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de créer la nomenclature. Veuillez réessayer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour gérer la création d'un nouveau Code NSF
  const handleCreateCodeNSF = async (inputValue) => {
    try {
      const docRef = await addDoc(collection(db, 'codeNSFs'), {
        code: inputValue,
        createdAt: serverTimestamp(),
      });
      const newOption = { value: docRef.id, label: inputValue };
      setCodeNSFs((prev) => [...prev, { id: docRef.id, code: inputValue }]);
      setSelectedCodeNSFs((prev) => [...prev, newOption]);
      toast({
        title: 'Code NSF ajouté.',
        description: `Le Code NSF "${inputValue}" a été ajouté avec succès.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création du Code NSF:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de créer le Code NSF. Veuillez réessayer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour gérer la création d'un nouveau Formacode
  const handleCreateFormacode = async (inputValue) => {
    try {
      const docRef = await addDoc(collection(db, 'formacodes'), {
        code: inputValue,
        createdAt: serverTimestamp(),
      });
      const newOption = { value: docRef.id, label: inputValue };
      setFormacodes((prev) => [...prev, { id: docRef.id, code: inputValue }]);
      setSelectedFormacodes((prev) => [...prev, newOption]);
      toast({
        title: 'Formacode ajouté.',
        description: `Le Formacode "${inputValue}" a été ajouté avec succès.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création du Formacode:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de créer le Formacode. Veuillez réessayer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter un bloc de compétences
  const addBlocCompetence = () => {
    setBlocsCompetences([
      ...blocsCompetences,
      {
        nomBloc: '',
        competences: [''],
        modalitesEvaluation: [''],
      },
    ]);
  };

  // Fonction pour supprimer un bloc de compétences
  const removeBlocCompetence = (index) => {
    setBlocsCompetences(blocsCompetences.filter((_, i) => i !== index));
  };

  // Fonction pour mettre à jour le nom d'un bloc de compétences
  const updateNomBloc = (index, value) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[index].nomBloc = value;
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour ajouter une compétence à un bloc
  const addCompetenceToBloc = (index) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[index].competences.push('');
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour supprimer une compétence d'un bloc
  const removeCompetenceFromBloc = (blocIndex, competenceIndex) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[blocIndex].competences = updatedBlocs[blocIndex].competences.filter(
      (_, i) => i !== competenceIndex
    );
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour mettre à jour une compétence d'un bloc
  const updateCompetenceInBloc = (blocIndex, competenceIndex, value) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[blocIndex].competences[competenceIndex] = value;
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour ajouter une modalité d'évaluation à un bloc
  const addModaliteToBloc = (index) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[index].modalitesEvaluation.push('');
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour supprimer une modalité d'évaluation d'un bloc
  const removeModaliteFromBloc = (blocIndex, modaliteIndex) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[blocIndex].modalitesEvaluation = updatedBlocs[blocIndex].modalitesEvaluation.filter(
      (_, i) => i !== modaliteIndex
    );
    setBlocsCompetences(updatedBlocs);
  };

  // Fonction pour mettre à jour une modalité d'évaluation d'un bloc
  const updateModaliteInBloc = (blocIndex, modaliteIndex, value) => {
    const updatedBlocs = [...blocsCompetences];
    updatedBlocs[blocIndex].modalitesEvaluation[modaliteIndex] = value;
    setBlocsCompetences(updatedBlocs);
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="start"
      minH="100vh"
      bgGradient="linear(to-r, blue.100, green.100)"
      p="10"
    >
      <Box
        w="full"
        maxW="6xl"
        bg="white"
        p="8"
        borderRadius="lg"
        boxShadow="2xl"
        transform="scale(1.05)"
        transition="transform 0.2s ease-in-out"
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Heading as="h2" size="lg" mb="6" color="teal.600">
          Enregistrer une Formation
        </Heading>
        {(isLoadingCertifiers || isLoadingCodeMetiers || isLoadingAccess) ? (
          <Center>
            <Spinner size="xl" color="teal.500" />
          </Center>
        ) : (
          <form onSubmit={handleSubmitFormation}>
            <Stack spacing="6">
              <Flex direction={['column', 'row']} gap="6">
                {/* Colonne de Gauche : Informations Générales */}
                <Box flex="1">
                  <Heading as="h3" size="md" mb="4" color="blue.500">
                    Informations Générales{' '}
                    <Icon as={FaInfoCircle} ml="2" color="gray.500" />
                  </Heading>
                  <Stack spacing="4">
                    <FormControl id="title" isRequired>
                      <FormLabel>Titre de la formation</FormLabel>
                      <Input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Entrez le titre de la formation"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                    <FormControl id="description" isRequired>
                      <FormLabel>Description</FormLabel>
                      <Textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Entrez la description de la formation"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                    <FormControl id="prerequisites">
                      <FormLabel>Prérequis</FormLabel>
                      <Textarea
                        value={prerequisites}
                        onChange={(e) => setPrerequisites(e.target.value)}
                        placeholder="Entrez les prérequis de la formation"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>

                    {/* Nouveaux champs ajoutés */}
                    <FormControl id="dueDate">
                      <FormLabel>Date d'échéance</FormLabel>
                      <Input
                        type="date"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                        placeholder="Sélectionnez la date d'échéance"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                    <FormControl id="codeRNCP">
                      <FormLabel>Code RNCP</FormLabel>
                      <Input
                        type="text"
                        value={codeRNCP}
                        onChange={(e) => setCodeRNCP(e.target.value)}
                        placeholder="Entrez le code RNCP"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                  </Stack>
                </Box>

                {/* Colonne du Milieu : Aspects Pédagogiques */}
                <Box flex="1">
                  <Heading as="h3" size="md" mb="4" color="green.500">
                    Aspects Pédagogiques{' '}
                    <Icon as={FaInfoCircle} ml="2" color="gray.500" />
                  </Heading>
                  <Stack spacing="4">
                    <FormControl id="programme" isRequired>
                      <FormLabel>Programme</FormLabel>
                      <Textarea
                        value={programme}
                        onChange={(e) => setProgramme(e.target.value)}
                        placeholder="Entrez le programme de la formation"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                    <FormControl id="entranceTests" isRequired>
                      <FormLabel>Tests d'entrée</FormLabel>
                      <Textarea
                        value={entranceTests}
                        onChange={(e) => setEntranceTests(e.target.value)}
                        placeholder="Entrez les tests d'entrée requis"
                        bg="gray.100"
                        _hover={{ bg: 'gray.200' }}
                      />
                    </FormControl>
                  </Stack>
                </Box>

                {/* Colonne de Droite : Certificateurs, Codes Métiers & Voies d'Accès */}
                <Box flex="1">
                  <Heading as="h3" size="md" mb="4" color="purple.500">
                    Certificateurs, Codes Métiers & Voies d'Accès{' '}
                    <Icon as={FaInfoCircle} ml="2" color="gray.500" />
                  </Heading>
                  <Stack spacing="4">
                    {/* Certificateur */}
                    <FormControl id="certifierId" isRequired>
                      <FormLabel>Certificateur</FormLabel>
                      <ReactSelect
                        isClearable
                        isDisabled={isLoadingCertifiers}
                        placeholder="Sélectionnez un certificateur"
                        value={certifierOptions.find((option) => option.value === certifierId)}
                        onChange={(selectedOption) =>
                          setCertifierId(selectedOption ? selectedOption.value : '')
                        }
                        options={certifierOptions}
                        components={{
                          Option: CustomCertifierOption,
                          SingleValue: CustomCertifierSingleValue,
                        }}
                        chakraStyles={{
                          container: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                          }),
                          control: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                            '&:hover': {
                              bg: 'gray.200',
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            bg: 'white',
                          }),
                        }}
                      />
                    </FormControl>

                    {/* Affichage du Logo et du SIRET du Certificateur Sélectionné */}
                    {certifierId && (
                      <Box mt="2" p="2" bg="gray.50" borderRadius="md" boxShadow="sm">
                        <Flex align="center" gap="4">
                          {certifiers.find((cert) => cert.id === certifierId)?.logoURL && (
                            <Image
                              src={certifiers.find((cert) => cert.id === certifierId).logoURL}
                              alt={`${certifiers.find((cert) => cert.id === certifierId).name} Logo`}
                              boxSize="50px"
                              objectFit="contain"
                              borderRadius="md"
                            />
                          )}
                          <Box>
                            <Text fontWeight="bold">
                              {certifiers.find((cert) => cert.id === certifierId)?.name || 'Nom non disponible'}
                            </Text>
                            <Text>
                              SIRET: {certifiers.find((cert) => cert.id === certifierId)?.siret || 'Non fourni'}
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                              Créé le:{' '}
                              {certifiers.find((cert) => cert.id === certifierId)?.createdAt
                                ? new Date(certifiers.find((cert) => cert.id === certifierId).createdAt.seconds * 1000).toLocaleString()
                                : 'Date non disponible'}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    )}

                    {/* Code Métier */}
                    <FormControl id="codeMetierId" isRequired>
                      <FormLabel>Code Métier</FormLabel>
                      <ReactSelect
                        isClearable
                        isDisabled={isLoadingCodeMetiers}
                        placeholder="Sélectionnez un code métier"
                        value={
                          codeMetiers
                            .map((codeMetier) => ({
                              value: codeMetier.id,
                              label: `${codeMetier.code} - ${codeMetier.formation || 'N/A'}`,
                            }))
                            .find((option) => option.value === codeMetierId) || null
                        }
                        onChange={(selectedOption) =>
                          setCodeMetierId(selectedOption ? selectedOption.value : '')
                        }
                        options={codeMetiers.map((codeMetier) => ({
                          value: codeMetier.id,
                          label: `${codeMetier.code} - ${codeMetier.formation || 'N/A'}`,
                        }))}
                        chakraStyles={{
                          container: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                          }),
                          control: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                            '&:hover': {
                              bg: 'gray.200',
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            bg: 'white',
                          }),
                        }}
                      />
                    </FormControl>

                    {/* Voies d'Accès */}
                    <FormControl id="accessIds" isRequired>
                      <FormLabel>Voies d'Accès</FormLabel>
                      <ReactSelect
                        isMulti
                        isClearable
                        isDisabled={isLoadingAccess}
                        components={{
                          Option: CustomAccessOption,
                          MultiValue: CustomAccessMultiValue,
                        }}
                        placeholder="Sélectionnez les voies d'accès"
                        value={accessOptions.filter((option) => accessIds.includes(option.value))}
                        onChange={(selectedOptions) =>
                          setAccessIds(selectedOptions ? selectedOptions.map((option) => option.value) : [])
                        }
                        options={accessOptions}
                        chakraStyles={{
                          container: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                          }),
                          control: (provided) => ({
                            ...provided,
                            bg: 'gray.100',
                            '&:hover': {
                              bg: 'gray.200',
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            bg: 'white',
                          }),
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Box>
              </Flex>

              {/* Section : Nomenclature, Code NSF, Formacodes */}
              <Divider />
              <Box mt="6">
                <Heading as="h3" size="md" mb="4" color="cyan.500">
                  Informations Complémentaires{' '}
                  <Icon as={FaInfoCircle} ml="2" color="gray.500" />
                </Heading>
                <Stack spacing="4">
                  {/* Nomenclature du niveau de qualification */}
                  <FormControl id="nomenclatureId" isRequired>
                    <FormLabel>Nomenclature du niveau de qualification</FormLabel>
                    <CreatableSelect
                      isClearable
                      onCreateOption={handleCreateNomenclature}
                      placeholder="Sélectionnez ou créez une nomenclature"
                      value={selectedNomenclature}
                      onChange={(selectedOption) => setSelectedNomenclature(selectedOption)}
                      options={nomenclatureOptions}
                      components={{
                        Option: CustomCreatableOption,
                        SingleValue: CustomCreatableOption,
                      }}
                      chakraStyles={{
                        container: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                        }),
                        control: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                          '&:hover': {
                            bg: 'gray.200',
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          bg: 'white',
                        }),
                      }}
                    />
                  </FormControl>

                  {/* Code NSF */}
                  <FormControl id="codeNSFs" isRequired>
                    <FormLabel>Code NSF</FormLabel>
                    <CreatableSelect
                      isMulti
                      isClearable
                      onCreateOption={handleCreateCodeNSF}
                      placeholder="Sélectionnez ou créez des Code NSF"
                      value={selectedCodeNSFs}
                      onChange={(selectedOptions) => setSelectedCodeNSFs(selectedOptions)}
                      options={codeNSFOptions}
                      components={{
                        Option: CustomCreatableOption,
                        MultiValue: CustomCreatableOption,
                      }}
                      chakraStyles={{
                        container: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                        }),
                        control: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                          '&:hover': {
                            bg: 'gray.200',
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          bg: 'white',
                        }),
                      }}
                    />
                  </FormControl>

                  {/* Formacode(s) */}
                  <FormControl id="formacodes" isRequired>
                    <FormLabel>Formacode(s)</FormLabel>
                    <CreatableSelect
                      isMulti
                      isClearable
                      onCreateOption={handleCreateFormacode}
                      placeholder="Sélectionnez ou créez des Formacodes"
                      value={selectedFormacodes}
                      onChange={(selectedOptions) => setSelectedFormacodes(selectedOptions)}
                      options={formacodeOptions}
                      components={{
                        Option: CustomCreatableOption,
                        MultiValue: CustomCreatableOption,
                      }}
                      chakraStyles={{
                        container: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                        }),
                        control: (provided) => ({
                          ...provided,
                          bg: 'gray.100',
                          '&:hover': {
                            bg: 'gray.200',
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          bg: 'white',
                        }),
                      }}
                    />
                  </FormControl>
                </Stack>
              </Box>

              {/* Section : Blocs de Compétences */}
              <Divider />
              <Box mt="6">
                <Heading as="h3" size="md" mb="4" color="yellow.500">
                  Blocs de Compétences{' '}
                  <Icon as={FaInfoCircle} ml="2" color="gray.500" />
                </Heading>
                <Stack spacing="6">
                  {blocsCompetences.map((bloc, blocIndex) => (
                    <Box key={blocIndex} p="4" bg="yellow.50" borderRadius="md" boxShadow="sm">
                      <Flex justify="space-between" align="center" mb="4">
                        <Text fontSize="lg" fontWeight="bold">
                          Bloc de Compétences {blocIndex + 1}
                        </Text>
                        {blocsCompetences.length > 1 && (
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => removeBlocCompetence(blocIndex)}
                            leftIcon={<FaTrash />}
                          >
                            Supprimer
                          </Button>
                        )}
                      </Flex>
                      <Stack spacing="4">
                        <FormControl id={`nomBloc-${blocIndex}`} isRequired>
                          <FormLabel>Nom du Bloc</FormLabel>
                          <Input
                            type="text"
                            value={bloc.nomBloc}
                            onChange={(e) => updateNomBloc(blocIndex, e.target.value)}
                            placeholder="Entrez le nom du bloc de compétences"
                            bg="gray.100"
                            _hover={{ bg: 'gray.200' }}
                          />
                        </FormControl>

                        {/* Compétences */}
                        <FormControl id={`competences-${blocIndex}`} isRequired>
                          <FormLabel>Compétences</FormLabel>
                          <Stack spacing="2">
                            {bloc.competences.map((competence, compIndex) => (
                              <HStack key={compIndex}>
                                <Input
                                  type="text"
                                  value={competence}
                                  onChange={(e) =>
                                    updateCompetenceInBloc(blocIndex, compIndex, e.target.value)
                                  }
                                  placeholder={`Compétence ${compIndex + 1}`}
                                  bg="gray.100"
                                  _hover={{ bg: 'gray.200' }}
                                />
                                {bloc.competences.length > 1 && (
                                  <Button
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => removeCompetenceFromBloc(blocIndex, compIndex)}
                                    leftIcon={<FaTrash />}
                                  >
                                    Supprimer
                                  </Button>
                                )}
                              </HStack>
                            ))}
                            <Button
                              leftIcon={<FaPlus />}
                              colorScheme="green"
                              size="sm"
                              onClick={() => addCompetenceToBloc(blocIndex)}
                            >
                              Ajouter une compétence
                            </Button>
                          </Stack>
                        </FormControl>

                        {/* Modalités d'Évaluation */}
                        <FormControl id={`modalitesEvaluation-${blocIndex}`} isRequired>
                          <FormLabel>Modalités d'Évaluation</FormLabel>
                          <Stack spacing="2">
                            {bloc.modalitesEvaluation.map((modalite, modIndex) => (
                              <HStack key={modIndex}>
                                <Input
                                  type="text"
                                  value={modalite}
                                  onChange={(e) =>
                                    updateModaliteInBloc(blocIndex, modIndex, e.target.value)
                                  }
                                  placeholder={`Modalité d'évaluation ${modIndex + 1}`}
                                  bg="gray.100"
                                  _hover={{ bg: 'gray.200' }}
                                />
                                {bloc.modalitesEvaluation.length > 1 && (
                                  <Button
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => removeModaliteFromBloc(blocIndex, modIndex)}
                                    leftIcon={<FaTrash />}
                                  >
                                    Supprimer
                                  </Button>
                                )}
                              </HStack>
                            ))}
                            <Button
                              leftIcon={<FaPlus />}
                              colorScheme="green"
                              size="sm"
                              onClick={() => addModaliteToBloc(blocIndex)}
                            >
                              Ajouter une modalité d'évaluation
                            </Button>
                          </Stack>
                        </FormControl>
                      </Stack>
                    </Box>
                  ))}
                  <Button
                    leftIcon={<FaPlus />}
                    colorScheme="green"
                    onClick={addBlocCompetence}
                  >
                    Ajouter un Bloc de Compétences
                  </Button>
                </Stack>
              </Box>

              {/* Submit Button */}
              <Divider />

              <Button
                colorScheme="teal"
                type="submit"
                width="full"
                isLoading={isSubmitting}
                size="lg"
                _hover={{ bg: 'teal.400', transform: 'scale(1.05)' }}
              >
                Enregistrer la Formation
              </Button>
            </Stack>
          </form>
        )}
      </Box>
    </Flex>
  );
};

// Custom Option pour les creatable selects (Nomenclature, Code NSF, Formacode)
const CustomCreatableOption = (props) => (
  <components.Option {...props}>
    <Text>{props.data.label}</Text>
  </components.Option>
);

export default ViewFormationFile;
