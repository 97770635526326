import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  useColorModeValue,
  Text,
  IconButton,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Image,
  Link, // Importer Link
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
  getDocs,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'; // Importer l'authentification
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

const School = () => {
  const [schoolData, setSchoolData] = useState({
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    phone: '',
    email: '',
    website: '', // Nouveau champ
    logoURL: '',
  });
  const [logoFile, setLogoFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSchoolId, setCurrentSchoolId] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const db = getFirestore();
  const storage = getStorage(); // Initialiser le stockage Firebase
  const auth = getAuth(); // Initialiser l'authentification Firebase
  const [user, setUser] = useState(null); // État pour l'utilisateur authentifié

  // États pour les écoles
  const [schools, setSchools] = useState([]);
  const [isLoadingSchools, setIsLoadingSchools] = useState(true);

  // États pour les formations associées
  const [formations, setFormations] = useState([]);
  const [availableFormations, setAvailableFormations] = useState([]);
  const [isLoadingFormations, setIsLoadingFormations] = useState(true);

  // États pour le modal d'ajout de formation
  const {
    isOpen: isOpenAddFormation,
    onOpen: onOpenAddFormation,
    onClose: onCloseAddFormation,
  } = useDisclosure();
  const [selectedFormationId, setSelectedFormationId] = useState('');
  const [newFormationTitle, setNewFormationTitle] = useState('');
  const [newFormationDescription, setNewFormationDescription] = useState('');

  // Vérifier l'authentification de l'utilisateur
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // L'utilisateur est authentifié
        setUser(user);
      } else {
        // L'utilisateur n'est pas authentifié, on le connecte anonymement
        signInAnonymously(auth)
          .then(() => {
            console.log('Utilisateur connecté anonymement');
          })
          .catch((error) => {
            console.error('Erreur lors de la connexion anonyme :', error);
            toast({
              title: 'Erreur',
              description: 'Impossible de vous authentifier.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          });
      }
    });

    return () => unsubscribe();
  }, [auth, toast]);

  // Fonction pour récupérer les écoles existantes
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'schools'));
        const schoolsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSchools(schoolsList);
        setIsLoadingSchools(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des écoles :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les écoles.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingSchools(false);
      }
    };

    fetchSchools();
  }, [db, toast]);

  // Fonction pour récupérer les formations existantes
  useEffect(() => {
    const fetchFormations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'formations'));
        const formationsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
        }));
        setAvailableFormations(formationsList);
        setIsLoadingFormations(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des formations :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les formations.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsLoadingFormations(false);
      }
    };

    fetchFormations();
  }, [db, toast]);

  // Fonction pour gérer l'ajout d'une formation existante
  const handleAddFormation = () => {
    const formation = availableFormations.find(
      (f) => f.id === selectedFormationId
    );
    if (formation) {
      setFormations((prevFormations) => [...prevFormations, formation]);
      onCloseAddFormation();
      setSelectedFormationId('');
    }
  };

  // Fonction pour créer une nouvelle formation et l'ajouter
  const handleCreateFormation = async () => {
    if (!newFormationTitle) {
      toast({
        title: 'Erreur',
        description: 'Veuillez entrer le titre de la formation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'formations'), {
        title: newFormationTitle,
        description: newFormationDescription,
        createdAt: Timestamp.now(),
      });
      const newFormation = {
        id: docRef.id,
        title: newFormationTitle,
        description: newFormationDescription,
      };
      setAvailableFormations((prev) => [...prev, newFormation]);
      setFormations((prev) => [...prev, newFormation]);
      onCloseAddFormation();
      setNewFormationTitle('');
      setNewFormationDescription('');
      toast({
        title: 'Succès',
        description: 'Formation créée et ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la création de la formation:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de créer la formation. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer une formation de la liste
  const handleRemoveFormation = (index) => {
    setFormations((prev) => prev.filter((_, i) => i !== index));
  };

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Gestion du changement de logo
  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!user) {
      toast({
        title: 'Erreur',
        description: 'Vous devez être authentifié pour effectuer cette action.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    // Validation basique
    if (
      !schoolData.name ||
      !schoolData.address ||
      !schoolData.latitude ||
      !schoolData.longitude ||
      !schoolData.phone ||
      !schoolData.email ||
      !schoolData.website // Ajout du site web
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    let logoURL = schoolData.logoURL || '';

    // Upload du logo si un nouveau fichier est sélectionné
    if (logoFile) {
      try {
        const storageRef = ref(
          storage,
          `schoolLogos/${Date.now()}_${logoFile.name}`
        );
        const snapshot = await uploadBytes(storageRef, logoFile);
        logoURL = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Erreur lors de l'upload du logo :", error);
        toast({
          title: 'Erreur',
          description: "Impossible d'uploader le logo. Veuillez réessayer.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsSubmitting(false);
        return;
      }
    }

    try {
      if (isEditing && currentSchoolId) {
        // Mise à jour de l'école existante
        await updateDoc(doc(db, 'schools', currentSchoolId), {
          ...schoolData,
          logoURL,
          formations: formations.map((f) => f.id),
          updatedAt: Timestamp.now(),
        });
        toast({
          title: 'Succès',
          description: 'École mise à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Mise à jour de la liste des écoles
        setSchools((prevSchools) =>
          prevSchools.map((school) =>
            school.id === currentSchoolId
              ? {
                  id: currentSchoolId,
                  ...schoolData,
                  logoURL,
                  formations: formations.map((f) => f.id),
                }
              : school
          )
        );
      } else {
        // Ajout d'une nouvelle école
        const docRef = await addDoc(collection(db, 'schools'), {
          ...schoolData,
          logoURL,
          formations: formations.map((f) => f.id),
          createdAt: Timestamp.now(),
        });
        toast({
          title: 'Succès',
          description: 'École créée avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Ajout de la nouvelle école à la liste
        setSchools((prevSchools) => [
          ...prevSchools,
          {
            id: docRef.id,
            ...schoolData,
            logoURL,
            formations: formations.map((f) => f.id),
          },
        ]);
      }
      // Réinitialisation du formulaire
      setSchoolData({
        name: '',
        address: '',
        latitude: '',
        longitude: '',
        phone: '',
        email: '',
        website: '', // Réinitialiser le site web
        logoURL: '',
      });
      setLogoFile(null);
      setFormations([]);
      setIsEditing(false);
      setCurrentSchoolId(null);
    } catch (error) {
      console.error("Erreur lors de la création/mise à jour de l'école :", error);
      toast({
        title: 'Erreur',
        description:
          "Une erreur est survenue lors de la création/mise à jour de l'école. Veuillez réessayer.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  // Fonction pour gérer la modification d'une école
  const handleEditSchool = (school) => {
    setSchoolData({
      name: school.name || '',
      address: school.address || '',
      latitude: school.latitude || '',
      longitude: school.longitude || '',
      phone: school.phone || '',
      email: school.email || '',
      website: school.website || '', // Charger le site web
      logoURL: school.logoURL || '',
    });
    setFormations(
      school.formations && school.formations.length > 0
        ? availableFormations.filter((f) => school.formations.includes(f.id))
        : []
    );
    setLogoFile(null);
    setIsEditing(true);
    setCurrentSchoolId(school.id);
  };

  return (
    <Box
      minH="100vh"
      bgGradient={useColorModeValue(
        'linear(to-tr, teal.50, blue.50)',
        'linear(to-tr, teal.900, blue.900)'
      )}
      p="10"
    >
      <Box
        maxW="md"
        mx="auto"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        borderRadius="lg"
        p="8"
        mb="8"
      >
        <Heading as="h2" size="lg" mb="6" textAlign="center">
          {isEditing ? 'Modifier une École' : 'Ajouter une École'}
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing="4">
            <FormControl isRequired>
              <FormLabel>Nom de l'école</FormLabel>
              <Input
                name="name"
                value={schoolData.name}
                onChange={handleChange}
                placeholder="Entrez le nom de l'école"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Adresse</FormLabel>
              <Input
                name="address"
                value={schoolData.address}
                onChange={handleChange}
                placeholder="Entrez l'adresse"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Latitude</FormLabel>
              <Input
                name="latitude"
                value={schoolData.latitude}
                onChange={handleChange}
                placeholder="Entrez la latitude"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Longitude</FormLabel>
              <Input
                name="longitude"
                value={schoolData.longitude}
                onChange={handleChange}
                placeholder="Entrez la longitude"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Numéro de téléphone</FormLabel>
              <Input
                name="phone"
                value={schoolData.phone}
                onChange={handleChange}
                placeholder="Entrez le numéro de téléphone"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Adresse e-mail</FormLabel>
              <Input
                name="email"
                value={schoolData.email}
                onChange={handleChange}
                placeholder="Entrez l'adresse e-mail"
                type="email" // Type email pour une validation de base
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Site Web</FormLabel>
              <Input
                name="website"
                value={schoolData.website}
                onChange={handleChange}
                placeholder="https://www.exemple.com"
                type="url"
              />
            </FormControl>

            {/* Afficher le logo actuel si en mode édition */}
            {isEditing && schoolData.logoURL && (
              <FormControl>
                <FormLabel>Logo actuel</FormLabel>
                <Image
                  src={schoolData.logoURL}
                  alt="Logo de l'école"
                  boxSize="100px"
                  objectFit="contain"
                  borderRadius="md"
                />
              </FormControl>
            )}

            {/* Champ pour uploader le logo */}
            <FormControl>
              <FormLabel>Logo de l'école</FormLabel>
              <Input type="file" accept="image/*" onChange={handleLogoChange} />
            </FormControl>

            {/* Formations associées */}
            <FormControl>
              <FormLabel>Formations associées</FormLabel>
              {formations.length > 0 ? (
                <VStack align="start" spacing={2}>
                  {formations.map((formation, index) => (
                    <Box key={index} p="2" borderWidth="1px" borderRadius="md" w="full">
                      <Flex justify="space-between" align="center">
                        <Text>{formation.title}</Text>
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleRemoveFormation(index)}
                          aria-label="Supprimer la formation"
                        />
                      </Flex>
                    </Box>
                  ))}
                </VStack>
              ) : (
                <Text>Aucune formation associée.</Text>
              )}
              <Button
                colorScheme="teal"
                mt="4"
                leftIcon={<AddIcon />}
                onClick={onOpenAddFormation}
              >
                Ajouter une Formation
              </Button>
            </FormControl>

            <Button
              colorScheme="teal"
              type="submit"
              isFullWidth
              isLoading={isSubmitting}
              loadingText={isEditing ? 'Mise à jour...' : 'Enregistrement...'}
            >
              {isEditing ? 'Mettre à Jour l\'École' : 'Enregistrer l\'École'}
            </Button>
          </VStack>
        </form>
      </Box>

      {/* Liste des écoles existantes */}
      <Box
        maxW="6xl"
        mx="auto"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        borderRadius="lg"
        p="8"
      >
        <Heading as="h2" size="lg" mb="6" textAlign="center">
          Liste des Écoles
        </Heading>
        {isLoadingSchools ? (
          <Flex justify="center" align="center">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Logo</Th>
                <Th>Nom</Th>
                <Th>Adresse</Th>
                <Th>Site Web</Th> {/* Nouvelle colonne */}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {schools.map((school) => (
                <Tr key={school.id}>
                  <Td>
                    {school.logoURL ? (
                      <Image
                        src={school.logoURL}
                        alt="Logo de l'école"
                        boxSize="50px"
                        objectFit="contain"
                        borderRadius="md"
                      />
                    ) : (
                      <Text>Aucun logo</Text>
                    )}
                  </Td>
                  <Td>{school.name}</Td>
                  <Td>{school.address}</Td>
                  <Td>
                    {school.website ? (
                      <Link href={school.website} color="teal.500" isExternal>
                        Visiter le site
                      </Link>
                    ) : (
                      <Text>Aucun site</Text>
                    )}
                  </Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      colorScheme="blue"
                      mr="2"
                      onClick={() => handleEditSchool(school)}
                      aria-label="Modifier l'école"
                    />
                    {/* Vous pouvez ajouter un bouton de suppression ici si nécessaire */}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      {/* Modal pour ajouter une formation */}
      <Modal isOpen={isOpenAddFormation} onClose={onCloseAddFormation}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une Formation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Sélectionner une formation existante</FormLabel>
              <Select
                placeholder="Sélectionnez une formation"
                value={selectedFormationId}
                onChange={(e) => setSelectedFormationId(e.target.value)}
              >
                {availableFormations.map((formation) => (
                  <option key={formation.id} value={formation.id}>
                    {formation.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Text textAlign="center" my="4">
              ou
            </Text>
            <FormControl>
              <FormLabel>Titre de la nouvelle formation</FormLabel>
              <Input
                placeholder="Entrez le titre de la formation"
                value={newFormationTitle}
                onChange={(e) => setNewFormationTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2">
              <FormLabel>Description de la formation</FormLabel>
              <Textarea
                placeholder="Entrez la description de la formation"
                value={newFormationDescription}
                onChange={(e) => setNewFormationDescription(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={selectedFormationId ? handleAddFormation : handleCreateFormation}
            >
              {selectedFormationId ? 'Ajouter' : 'Créer et Ajouter'}
            </Button>
            <Button variant="ghost" onClick={onCloseAddFormation}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default School;
