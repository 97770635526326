// src/admin/pages/AddAides.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  useToast,
  Spinner,
  Center,
  VStack,
  HStack,
  Alert,
  AlertIcon,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  IconButton,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  Timestamp,
  doc,
  updateDoc,
} from 'firebase/firestore';
import {
  MdAttachMoney,
  MdDescription,
  MdImage,
  MdContacts,
  MdCheckCircle,
  MdDelete,
  MdEdit,
} from 'react-icons/md';
import { FaUniversity, FaEnvelope, FaRegLightbulb } from 'react-icons/fa';
import { motion } from 'framer-motion';
import CreatableSelect from 'react-select/creatable';

// Composant animé
const MotionBox = motion(Box);

const AddAides = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();

  const [financeurs, setFinanceurs] = useState([]);
  const [aides, setAides] = useState([]);
  const [loadingFinanceurs, setLoadingFinanceurs] = useState(true);
  const [loadingAides, setLoadingAides] = useState(true);
  const [errorFinanceurs, setErrorFinanceurs] = useState(null);
  const [errorAides, setErrorAides] = useState(null);

  // Gestion de la modale pour ajouter un nouveau financeur
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newFinanceurName, setNewFinanceurName] = useState('');

  // Gestion de la modale pour éditer une aide
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [currentAide, setCurrentAide] = useState(null); // Aide en cours de modification

  // Couleurs basées sur le mode de couleur
  const tagColorScheme = useColorModeValue('blue', 'teal');
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const inputBg = useColorModeValue('gray.100', 'gray.600');
  const inputHoverBg = useColorModeValue('gray.200', 'gray.500');
  const focusBorderColor = useColorModeValue('teal.500', 'teal.300');
  const buttonColorScheme = useColorModeValue('teal', 'blue');

  // Définition des couleurs pour les labels avec icônes
  const labelColor = useColorModeValue('gray.700', 'gray.300');

  // Charger les financeurs existants depuis Firestore
  useEffect(() => {
    const fetchFinanceurs = async () => {
      try {
        const financeursCol = collection(db, 'financeurs');
        const snapshot = await getDocs(financeursCol);
        const financeursList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFinanceurs(financeursList);
        setLoadingFinanceurs(false);
      } catch (error) {
        console.error('Erreur lors du chargement des financeurs:', error);
        setErrorFinanceurs(
          'Impossible de charger les financeurs. Veuillez réessayer plus tard.'
        );
        setLoadingFinanceurs(false);
      }
    };

    fetchFinanceurs();
  }, [db]);

  // Charger les aides existantes depuis Firestore
  useEffect(() => {
    const fetchAides = async () => {
      try {
        const aidesCol = collection(db, 'aidesFormation');
        const snapshot = await getDocs(aidesCol);
        const aidesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAides(aidesList);
        setLoadingAides(false);
      } catch (error) {
        console.error('Erreur lors du chargement des aides:', error);
        setErrorAides('Impossible de charger les aides. Veuillez réessayer plus tard.');
        setLoadingAides(false);
      }
    };

    fetchAides();
  }, [db]);

  // Schéma de validation avec Yup
  const AideSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Le titre doit contenir au moins 2 caractères')
      .max(100, 'Le titre ne peut pas dépasser 100 caractères')
      .required('Le titre est requis'),
    description: Yup.string()
      .max(1000, 'La description ne peut pas dépasser 1000 caractères')
      .required('La description est requise'),
    type: Yup.string()
      .oneOf(['Bourse', 'Subvention', 'Prêt', 'Autre'], 'Type invalide')
      .required('Le type est requis'),
    eligibility: Yup.string().required('L\'éligibilité est requise'),
    criteria: Yup.string(),
    applicationProcess: Yup.string(),
    contactName: Yup.string()
      .min(2, 'Le nom/service doit contenir au moins 2 caractères')
      .max(100, 'Le nom/service ne peut pas dépasser 100 caractères'),
    contactPhone: Yup.string()
      .matches(
        /^(\+\d{1,3}[- ]?)?\d{10}$/,
        'Numéro de téléphone invalide'
      ),
    contactEmail: Yup.string()
      .email('Adresse mail invalide'),
    imageUrl: Yup.string().url('URL invalide'),
    simulationUrl: Yup.string().url('URL invalide').nullable(),
    submissionUrl: Yup.string().url('URL invalide').nullable(),
    financeurs: Yup.array().of(Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })),
    hasBudget: Yup.boolean(), // Ajout de hasBudget
  });

  // Préparer les couleurs pour react-select
  const controlBg = useColorModeValue('gray.100', 'gray.600');
  const multiValueBg = useColorModeValue('teal.100', 'teal.700');
  const multiValueLabelColor = useColorModeValue('gray.800', 'white');
  const multiValueRemoveColor = useColorModeValue('gray.800', 'white');
  const multiValueRemoveHoverBg = useColorModeValue('red.500', 'red.600');

  // Définir les styles personnalisés pour react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: controlBg,
      borderColor: state.isFocused ? 'teal' : 'gray',
      boxShadow: state.isFocused ? '0 0 0 1px teal' : 'none',
      '&:hover': {
        borderColor: 'teal',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: multiValueBg,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: multiValueLabelColor,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: multiValueRemoveColor,
      ':hover': {
        backgroundColor: multiValueRemoveHoverBg,
        color: 'white',
      },
    }),
  };

  // Fonction de soumission du formulaire d'ajout
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const financeursIds = values.financeurs.map(f => f.value);

      const dataToSend = {
        title: values.title,
        description: values.description,
        type: values.type,
        eligibility: values.eligibility,
        criteria: values.criteria || '-',
        applicationProcess: values.applicationProcess || '-',
        contactName: values.contactName || '-',
        contactPhone: values.contactPhone || '-',
        contactEmail: values.contactEmail || '-',
        imageUrl: values.imageUrl || '-',
        simulationUrl: values.simulationUrl || null,
        submissionUrl: values.submissionUrl || null,
        financeurs: financeursIds,
        createdAt: Timestamp.now(),
        status: 'Actif', // Définir le statut par défaut à 'Actif'
        hasBudget: values.hasBudget, // Définir hasBudget
      };

      await addDoc(collection(db, 'aidesFormation'), dataToSend);

      toast({
        title: 'Aide ajoutée.',
        description: 'La nouvelle aide à la formation a été ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      resetForm();
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'aide:', error);
      toast({
        title: 'Erreur.',
        description: 'Une erreur est survenue lors de l\'ajout de l\'aide.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Fonction de soumission du formulaire d'édition
  const handleEditSubmit = async (values, { setSubmitting }) => {
    try {
      const financeursIds = values.financeurs.map(f => f.value);
      const aideDocRef = doc(db, 'aidesFormation', currentAide.id);

      const dataToUpdate = {
        title: values.title,
        description: values.description,
        type: values.type,
        eligibility: values.eligibility,
        criteria: values.criteria || '-',
        applicationProcess: values.applicationProcess || '-',
        contactName: values.contactName || '-',
        contactPhone: values.contactPhone || '-',
        contactEmail: values.contactEmail || '-',
        imageUrl: values.imageUrl || '-',
        simulationUrl: values.simulationUrl || null,
        submissionUrl: values.submissionUrl || null,
        financeurs: financeursIds,
        hasBudget: values.hasBudget,
        // Note: Nous ne modifions pas le statut ici
      };

      await updateDoc(aideDocRef, dataToUpdate);

      // Mettre à jour l'état local sans recharger la page
      setAides((prevAides) =>
        prevAides.map((aide) =>
          aide.id === currentAide.id ? { ...aide, ...dataToUpdate } : aide
        )
      );

      toast({
        title: 'Aide mise à jour.',
        description: 'Les informations de l\'aide ont été mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onEditClose();
      setCurrentAide(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'aide:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de mettre à jour l\'aide. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Fonction pour définir le statut d'une aide
  const setAideStatus = async (aideId, newStatus) => {
    try {
      const aideDocRef = doc(db, 'aidesFormation', aideId);

      await updateDoc(aideDocRef, { status: newStatus });

      // Mettre à jour l'état local sans recharger la page
      setAides((prevAides) =>
        prevAides.map((aide) =>
          aide.id === aideId ? { ...aide, status: newStatus } : aide
        )
      );

      toast({
        title: 'Statut mis à jour.',
        description: `Le statut de l'aide a été mis à jour en "${newStatus}".`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de mettre à jour le statut de l\'aide.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Nouvelle fonction pour mettre à jour hasBudget
  const toggleAideBudget = async (aideId, currentHasBudget) => {
    try {
      const aideDocRef = doc(db, 'aidesFormation', aideId);
      const newHasBudget = !currentHasBudget;

      await updateDoc(aideDocRef, { hasBudget: newHasBudget });

      // Mettre à jour l'état local sans recharger la page
      setAides((prevAides) =>
        prevAides.map((aide) =>
          aide.id === aideId ? { ...aide, hasBudget: newHasBudget } : aide
        )
      );

      toast({
        title: 'Budget mis à jour.',
        description: `Le budget de l'aide a été ${newHasBudget ? 'restauré' : 'épuisé'}.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du budget:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de mettre à jour le budget de l\'aide.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter un nouveau financeur
  const handleAddFinanceur = async () => {
    if (!newFinanceurName.trim()) {
      toast({
        title: 'Nom invalide.',
        description: 'Veuillez entrer un nom de financeur valide.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Ajouter le nouveau financeur à Firestore
      const docRef = await addDoc(collection(db, 'financeurs'), {
        nom: newFinanceurName.trim(),
        createdAt: Timestamp.now(),
      });

      const newFinanceur = { id: docRef.id, nom: newFinanceurName.trim() };
      setFinanceurs([...financeurs, newFinanceur]);

      toast({
        title: 'Financeur ajouté.',
        description: `Le financeur "${newFinanceurName.trim()}" a été ajouté avec succès.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Fermer la modale et réinitialiser le champ
      onClose();
      setNewFinanceurName('');
    } catch (error) {
      console.error('Erreur lors de l\'ajout du financeur:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible d\'ajouter le financeur. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ouvrir la modale d'édition avec l'aide sélectionnée
  const handleEditClick = (aide) => {
    setCurrentAide(aide);
    onEditOpen();
  };

  if (loadingFinanceurs || loadingAides) {
    return (
      <Center minH="100vh" bgGradient="linear(to-b, teal.50, blue.50)">
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  if (errorFinanceurs || errorAides) {
    return (
      <Center minH="100vh" bgGradient="linear(to-b, red.50, orange.50)">
        <Alert status="error" borderRadius="md" boxShadow="md">
          <AlertIcon />
          {errorFinanceurs || errorAides}
        </Alert>
      </Center>
    );
  }

  // Préparer les options pour react-select
  const financeursOptions = financeurs.map((financeur) => ({
    value: financeur.id,
    label: financeur.nom,
  }));

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minH="100vh"
      bgGradient="linear(to-b, teal.50, blue.50)"
      p="5"
    >
      {/* Formulaire d'Ajout d'Aide */}
      <MotionBox
        bg={cardBg}
        p={{ base: '6', md: '8' }}
        borderRadius="lg"
        boxShadow="2xl"
        maxW="800px"
        width="full"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Heading
          as="h2"
          size="lg"
          color="teal.600"
          mb="6"
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={MdAttachMoney} w={6} h={6} mr="2" />
          Ajouter une Nouvelle Aide à la Formation
        </Heading>
        <Formik
          initialValues={{
            title: '',
            description: '',
            type: '',
            eligibility: '',
            criteria: '',
            applicationProcess: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            imageUrl: '',
            simulationUrl: '',
            submissionUrl: '',
            financeurs: [],
            hasBudget: true, // Valeur par défaut
          }}
          validationSchema={AideSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <VStack spacing="6" align="stretch">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="6">
                  {/* Titre */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="title" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdDescription} color="teal.500" />
                        <Text>Titre</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="title"
                      name="title"
                      placeholder="Entrez le titre de l'aide"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    />
                    <ErrorMessage name="title">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>

                  {/* Type */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="type" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdAttachMoney} color="teal.500" />
                        <Text>Type</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Select}
                      id="type"
                      name="type"
                      placeholder="Sélectionnez un type"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    >
                      <option value="Bourse">Bourse</option>
                      <option value="Subvention">Subvention</option>
                      <option value="Prêt">Prêt</option>
                      <option value="Autre">Autre</option>
                    </Field>
                    <ErrorMessage name="type">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="6">
                  {/* Éligibilité */}
                  <FormControl isRequired>
                    <FormLabel htmlFor="eligibility" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={FaUniversity} color="teal.500" />
                        <Text>Éligibilité</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="eligibility"
                      name="eligibility"
                      placeholder="Entrez les critères d'éligibilité"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                      resize="vertical"
                    />
                    <ErrorMessage name="eligibility">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>

                  {/* URL de l'Image */}
                  <FormControl>
                    <FormLabel htmlFor="imageUrl" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdImage} color="teal.500" />
                        <Text>URL de l'Image</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="imageUrl"
                      name="imageUrl"
                      placeholder="Entrez l'URL de l'image (optionnel)"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    />
                    <ErrorMessage name="imageUrl">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>

                {/* Description */}
                <FormControl isRequired>
                  <FormLabel htmlFor="description" color={labelColor}>
                    <HStack spacing="2">
                      <Icon as={MdDescription} color="teal.500" />
                      <Text>Description</Text>
                    </HStack>
                  </FormLabel>
                  <Field
                    as={Textarea}
                    id="description"
                    name="description"
                    placeholder="Entrez la description de l'aide"
                    bg={inputBg}
                    _hover={{ bg: inputHoverBg }}
                    focusBorderColor={focusBorderColor}
                    resize="vertical"
                  />
                  <ErrorMessage name="description">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="6">
                  {/* Critères de Sélection */}
                  <FormControl>
                    <FormLabel htmlFor="criteria" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={FaUniversity} color="teal.500" />
                        <Text>Critères de Sélection</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="criteria"
                      name="criteria"
                      placeholder="Entrez les critères de sélection (optionnel)"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                      resize="vertical"
                    />
                    <ErrorMessage name="criteria">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>

                  {/* Processus de Candidature */}
                  <FormControl>
                    <FormLabel htmlFor="applicationProcess" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={FaRegLightbulb} color="teal.500" />
                        <Text>Processus de Candidature</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="applicationProcess"
                      name="applicationProcess"
                      placeholder="Entrez le processus de candidature (optionnel)"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                      resize="vertical"
                    />
                    <ErrorMessage name="applicationProcess">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>

                {/* Informations de Contact */}
                <Heading
                  as="h4"
                  size="md"
                  color="teal.500"
                  mb="2"
                >
                  Informations de Contact
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
                  {/* Nom/Service */}
                  <FormControl>
                    <FormLabel htmlFor="contactName" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdContacts} color="teal.500" />
                        <Text>Nom/Service</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="contactName"
                      name="contactName"
                      placeholder="Entrez le nom ou le service"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    />
                    <ErrorMessage name="contactName">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>

                  {/* Numéro de Téléphone */}
                  <FormControl>
                    <FormLabel htmlFor="contactPhone" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdContacts} color="teal.500" />
                        <Text>Numéro de Téléphone</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="contactPhone"
                      name="contactPhone"
                      placeholder="Entrez le numéro de téléphone"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    />
                    <ErrorMessage name="contactPhone">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>

                  {/* Adresse Mail */}
                  <FormControl>
                    <FormLabel htmlFor="contactEmail" color={labelColor}>
                      <HStack spacing="2">
                        <Icon as={MdContacts} color="teal.500" />
                        <Text>Adresse Mail</Text>
                      </HStack>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="contactEmail"
                      name="contactEmail"
                      placeholder="Entrez l'adresse mail"
                      bg={inputBg}
                      _hover={{ bg: inputHoverBg }}
                      focusBorderColor={focusBorderColor}
                    />
                    <ErrorMessage name="contactEmail">
                      {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>

                {/* Sélection des Financeurs */}
                <FormControl>
                  <FormLabel htmlFor="financeurs">Financeurs Associés</FormLabel>
                  <CreatableSelect
                    isMulti
                    name="financeurs"
                    options={financeursOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) => setFieldValue('financeurs', selectedOptions)}
                    value={values.financeurs}
                    placeholder="Sélectionnez ou créez des financeurs..."
                    styles={customStyles}
                  />
                  <Button
                    mt="2"
                    size="sm"
                    onClick={onOpen}
                    colorScheme="teal"
                  >
                    Ajouter un Financeur
                  </Button>
                  <ErrorMessage name="financeurs">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* URL de Simulation */}
                <FormControl>
                  <FormLabel htmlFor="simulationUrl" color={labelColor}>
                    <HStack spacing="2">
                      <Icon as={FaRegLightbulb} color="teal.500" />
                      <Text>URL de Simulation</Text>
                    </HStack>
                  </FormLabel>
                  <Field
                    as={Input}
                    id="simulationUrl"
                    name="simulationUrl"
                    placeholder="Entrez l'URL de simulation (optionnel)"
                    bg={inputBg}
                    _hover={{ bg: inputHoverBg }}
                    focusBorderColor={focusBorderColor}
                  />
                  <ErrorMessage name="simulationUrl">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* URL de Dépôt */}
                <FormControl>
                  <FormLabel htmlFor="submissionUrl" color={labelColor}>
                    <HStack spacing="2">
                      <Icon as={FaEnvelope} color="teal.500" />
                      <Text>URL de Dépôt</Text>
                    </HStack>
                  </FormLabel>
                  <Field
                    as={Input}
                    id="submissionUrl"
                    name="submissionUrl"
                    placeholder="Entrez l'URL de dépôt (optionnel)"
                    bg={inputBg}
                    _hover={{ bg: inputHoverBg }}
                    focusBorderColor={focusBorderColor}
                  />
                  <ErrorMessage name="submissionUrl">
                    {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                  </ErrorMessage>
                </FormControl>

                {/* Indicateur Booléen pour Budget */}
                <FormControl display="flex" alignItems="center">
                  <Field
                    as={Switch}
                    id="hasBudget"
                    name="hasBudget"
                    colorScheme="teal"
                  />
                  <FormLabel htmlFor="hasBudget" mb="0" ml="3" cursor="pointer">
                    Budget Disponible
                  </FormLabel>
                </FormControl>

                {/* Bouton de Soumission */}
                <Button
                  type="submit"
                  colorScheme={buttonColorScheme}
                  leftIcon={<MdCheckCircle />}
                  isLoading={isSubmitting}
                  width="full"
                  size="lg"
                >
                  {isSubmitting ? 'En cours...' : 'Ajouter l\'Aide'}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </MotionBox>

      {/* Section des Aides Existantes */}
      <Box
        mt="10"
        w="full"
        maxW="800px"
      >
        <Heading
          as="h3"
          size="lg"
          color="teal.600"
          mb="6"
          textAlign="center"
        >
          Aides Existantes
        </Heading>

        {aides.length === 0 ? (
          <Center>
            <Text>Aucune aide disponible.</Text>
          </Center>
        ) : (
          <Box
            bg={cardBg}
            p="6"
            borderRadius="lg"
            boxShadow="lg"
          >
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Titre</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                  <Th>Budget</Th>
                  <Th>Contact</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {aides.map((aide) => (
                  <Tr key={aide.id}>
                    <Td>{aide.title || 'Non renseigné'}</Td>
                    <Td>{aide.type || 'Non spécifié'}</Td>
                    <Td>
                      <Tag
                        colorScheme={aide.status === 'Actif' ? 'green' : 'red'}
                      >
                        {aide.status || 'Non spécifié'}
                      </Tag>
                    </Td>
                    <Td>
                      <FormControl display="flex" alignItems="center">
                        <Switch
                          id={`hasBudget-${aide.id}`}
                          isChecked={aide.hasBudget}
                          onChange={() => toggleAideBudget(aide.id, aide.hasBudget)}
                          colorScheme="teal"
                        />
                        <FormLabel htmlFor={`hasBudget-${aide.id}`} mb="0" ml="3" cursor="pointer">
                          {aide.hasBudget ? 'Budget Disponible' : 'Pas de budget'}
                        </FormLabel>
                      </FormControl>
                    </Td>
                    <Td>
                      <Box>
                        <Text><strong>Nom/Service:</strong> {aide.contactName && aide.contactName !== '-' ? aide.contactName : 'Non renseigné'}</Text>
                        <Text><strong>Téléphone:</strong> {aide.contactPhone && aide.contactPhone !== '-' ? aide.contactPhone : 'Non renseigné'}</Text>
                        <Text><strong>Email:</strong> {aide.contactEmail && aide.contactEmail !== '-' ? aide.contactEmail : 'Non renseigné'}</Text>
                      </Box>
                    </Td>
                    <Td>
                      <HStack spacing="2" flexWrap="wrap">
                        {/* Bouton "Actif" */}
                        <Button
                          size="sm"
                          colorScheme="green"
                          leftIcon={<MdCheckCircle />}
                          onClick={() => setAideStatus(aide.id, 'Actif')}
                          isDisabled={aide.status === 'Actif'}
                          flexShrink={0}
                        >
                          Actif
                        </Button>
                        {/* Bouton "Inactif" */}
                        <Button
                          size="sm"
                          colorScheme="red"
                          leftIcon={<MdAttachMoney />}
                          onClick={() => setAideStatus(aide.id, 'Inactif')}
                          isDisabled={aide.status === 'Inactif'}
                          flexShrink={0}
                        >
                          Inactif
                        </Button>
                        {/* Bouton Modifier */}
                        <Button
                          size="sm"
                          colorScheme="blue"
                          leftIcon={<MdEdit />}
                          onClick={() => handleEditClick(aide)}
                          flexShrink={0}
                        >
                          Modifier
                        </Button>
                        {/* Bouton Supprimer (optionnel) */}
                        <IconButton
                          aria-label="Supprimer l'aide"
                          icon={<MdDelete />}
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => {
                            // Fonction de suppression à implémenter si nécessaire
                            // Par exemple, ouvrir une modale de confirmation
                            toast({
                              title: 'Fonctionnalité en développement.',
                              description: 'La suppression des aides est en cours de développement.',
                              status: 'info',
                              duration: 3000,
                              isClosable: true,
                            });
                          }}
                          flexShrink={0}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Box>

      {/* Modale pour ajouter un nouveau financeur */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Nouveau Financeur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Nom du Financeur</FormLabel>
              <Input
                placeholder="Entrez le nom du financeur"
                value={newFinanceurName}
                onChange={(e) => setNewFinanceurName(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="teal" onClick={handleAddFinanceur}>
              Ajouter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modale pour éditer une aide */}
      {currentAide && (
        <Modal isOpen={isEditOpen} onClose={() => { onEditClose(); setCurrentAide(null); }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modifier l'Aide</ModalHeader>
            <ModalCloseButton />
            <Formik
              initialValues={{
                title: currentAide.title || '',
                description: currentAide.description || '',
                type: currentAide.type || '',
                eligibility: currentAide.eligibility || '',
                criteria: currentAide.criteria === '-' ? '' : currentAide.criteria,
                applicationProcess: currentAide.applicationProcess === '-' ? '' : currentAide.applicationProcess,
                contactName: currentAide.contactName && currentAide.contactName !== '-' ? currentAide.contactName : '',
                contactPhone: currentAide.contactPhone && currentAide.contactPhone !== '-' ? currentAide.contactPhone : '',
                contactEmail: currentAide.contactEmail && currentAide.contactEmail !== '-' ? currentAide.contactEmail : '',
                imageUrl: currentAide.imageUrl === '-' ? '' : currentAide.imageUrl,
                simulationUrl: currentAide.simulationUrl || '',
                submissionUrl: currentAide.submissionUrl || '',
                financeurs: financeurs
                  .filter(f => currentAide.financeurs && currentAide.financeurs.includes(f.id))
                  .map(f => ({ value: f.id, label: f.nom })),
                hasBudget: currentAide.hasBudget || false,
              }}
              validationSchema={AideSchema}
              onSubmit={handleEditSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <ModalBody>
                    <VStack spacing="4" align="stretch">
                      {/* Titre */}
                      <FormControl isRequired>
                        <FormLabel htmlFor="title">Titre</FormLabel>
                        <Field
                          as={Input}
                          id="title"
                          name="title"
                          placeholder="Entrez le titre de l'aide"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                        />
                        <ErrorMessage name="title">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Type */}
                      <FormControl isRequired>
                        <FormLabel htmlFor="type">Type</FormLabel>
                        <Field
                          as={Select}
                          id="type"
                          name="type"
                          placeholder="Sélectionnez un type"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                        >
                          <option value="Bourse">Bourse</option>
                          <option value="Subvention">Subvention</option>
                          <option value="Prêt">Prêt</option>
                          <option value="Autre">Autre</option>
                        </Field>
                        <ErrorMessage name="type">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Éligibilité */}
                      <FormControl isRequired>
                        <FormLabel htmlFor="eligibility">Éligibilité</FormLabel>
                        <Field
                          as={Textarea}
                          id="eligibility"
                          name="eligibility"
                          placeholder="Entrez les critères d'éligibilité"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                          resize="vertical"
                        />
                        <ErrorMessage name="eligibility">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* URL de l'Image */}
                      <FormControl>
                        <FormLabel htmlFor="imageUrl">URL de l'Image</FormLabel>
                        <Field
                          as={Input}
                          id="imageUrl"
                          name="imageUrl"
                          placeholder="Entrez l'URL de l'image (optionnel)"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                        />
                        <ErrorMessage name="imageUrl">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Description */}
                      <FormControl isRequired>
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <Field
                          as={Textarea}
                          id="description"
                          name="description"
                          placeholder="Entrez la description de l'aide"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                          resize="vertical"
                        />
                        <ErrorMessage name="description">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Critères de Sélection */}
                      <FormControl>
                        <FormLabel htmlFor="criteria">Critères de Sélection</FormLabel>
                        <Field
                          as={Textarea}
                          id="criteria"
                          name="criteria"
                          placeholder="Entrez les critères de sélection (optionnel)"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                          resize="vertical"
                        />
                        <ErrorMessage name="criteria">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Processus de Candidature */}
                      <FormControl>
                        <FormLabel htmlFor="applicationProcess">Processus de Candidature</FormLabel>
                        <Field
                          as={Textarea}
                          id="applicationProcess"
                          name="applicationProcess"
                          placeholder="Entrez le processus de candidature (optionnel)"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                          resize="vertical"
                        />
                        <ErrorMessage name="applicationProcess">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Informations de Contact */}
                      <Heading
                        as="h4"
                        size="md"
                        color="teal.500"
                        mb="2"
                      >
                        Informations de Contact
                      </Heading>
                      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
                        {/* Nom/Service */}
                        <FormControl>
                          <FormLabel htmlFor="contactName">Nom/Service</FormLabel>
                          <Field
                            as={Input}
                            id="contactName"
                            name="contactName"
                            placeholder="Entrez le nom ou le service"
                            bg={inputBg}
                            _hover={{ bg: inputHoverBg }}
                            focusBorderColor={focusBorderColor}
                          />
                          <ErrorMessage name="contactName">
                            {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                          </ErrorMessage>
                        </FormControl>

                        {/* Numéro de Téléphone */}
                        <FormControl>
                          <FormLabel htmlFor="contactPhone">Numéro de Téléphone</FormLabel>
                          <Field
                            as={Input}
                            id="contactPhone"
                            name="contactPhone"
                            placeholder="Entrez le numéro de téléphone"
                            bg={inputBg}
                            _hover={{ bg: inputHoverBg }}
                            focusBorderColor={focusBorderColor}
                          />
                          <ErrorMessage name="contactPhone">
                            {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                          </ErrorMessage>
                        </FormControl>

                        {/* Adresse Mail */}
                        <FormControl>
                          <FormLabel htmlFor="contactEmail">Adresse Mail</FormLabel>
                          <Field
                            as={Input}
                            id="contactEmail"
                            name="contactEmail"
                            placeholder="Entrez l'adresse mail"
                            bg={inputBg}
                            _hover={{ bg: inputHoverBg }}
                            focusBorderColor={focusBorderColor}
                          />
                          <ErrorMessage name="contactEmail">
                            {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                          </ErrorMessage>
                        </FormControl>
                      </SimpleGrid>

                      {/* Sélection des Financeurs */}
                      <FormControl>
                        <FormLabel htmlFor="financeurs">Financeurs Associés</FormLabel>
                        <CreatableSelect
                          isMulti
                          name="financeurs"
                          options={financeursOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selectedOptions) => setFieldValue('financeurs', selectedOptions)}
                          value={values.financeurs}
                          placeholder="Sélectionnez ou créez des financeurs..."
                          styles={customStyles}
                        />
                        <Button
                          mt="2"
                          size="sm"
                          onClick={onOpen}
                          colorScheme="teal"
                        >
                          Ajouter un Financeur
                        </Button>
                        <ErrorMessage name="financeurs">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* URL de Simulation */}
                      <FormControl>
                        <FormLabel htmlFor="simulationUrl" color={labelColor}>
                          <HStack spacing="2">
                            <Icon as={FaRegLightbulb} color="teal.500" />
                            <Text>URL de Simulation</Text>
                          </HStack>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="simulationUrl"
                          name="simulationUrl"
                          placeholder="Entrez l'URL de simulation (optionnel)"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                        />
                        <ErrorMessage name="simulationUrl">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* URL de Dépôt */}
                      <FormControl>
                        <FormLabel htmlFor="submissionUrl" color={labelColor}>
                          <HStack spacing="2">
                            <Icon as={FaEnvelope} color="teal.500" />
                            <Text>URL de Dépôt</Text>
                          </HStack>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="submissionUrl"
                          name="submissionUrl"
                          placeholder="Entrez l'URL de dépôt (optionnel)"
                          bg={inputBg}
                          _hover={{ bg: inputHoverBg }}
                          focusBorderColor={focusBorderColor}
                        />
                        <ErrorMessage name="submissionUrl">
                          {(msg) => <Box color="red.500" fontSize="sm">{msg}</Box>}
                        </ErrorMessage>
                      </FormControl>

                      {/* Indicateur Booléen pour Budget */}
                      <FormControl display="flex" alignItems="center">
                        <Field
                          as={Switch}
                          id="hasBudget"
                          name="hasBudget"
                          colorScheme="teal"
                        />
                        <FormLabel htmlFor="hasBudget" mb="0" ml="3" cursor="pointer">
                          Budget Disponible
                        </FormLabel>
                      </FormControl>

                      {/* Bouton de Soumission */}
                      <Button
                        type="submit"
                        colorScheme={buttonColorScheme}
                        leftIcon={<MdCheckCircle />}
                        isLoading={isSubmitting}
                        width="full"
                        size="lg"
                      >
                        {isSubmitting ? 'En cours...' : 'Ajouter l\'Aide'}
                      </Button>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={() => { onEditClose(); setCurrentAide(null); }}>
                      Annuler
                    </Button>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Enregistrer les Modifications
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default AddAides;
