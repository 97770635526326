// src/public/pages/AidesViewDetail.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Tag,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Flex,
  Divider,
  HStack,
  Tooltip,
  IconButton,
  useColorMode,
  useColorModeValue,
  chakra,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Avatar,
} from '@chakra-ui/react';
import { MdArrowBack, MdWbSunny, MdNightsStay, MdWarning, MdPhone, MdEmail, MdPerson } from 'react-icons/md';
import { FaUniversity, FaRegLightbulb, FaEnvelope, FaPhone, FaUser } from 'react-icons/fa';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { motion } from 'framer-motion';

import { db } from '../../firebase';

// Composant animé
const MotionBox = motion(chakra.div);

// Composant pour afficher les informations de section
const Section = ({ icon: Icon, title, children }) => {
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Box mb="6">
      <Flex align="center" mb="2">
        <Icon style={{ marginRight: '8px' }} color={headingColor} />
        <Heading as="h4" size="md" color={headingColor}>
          {title}
        </Heading>
      </Flex>
      <Text color={textColor} lineHeight="1.6">
        {children}
      </Text>
    </Box>
  );
};

// Composant pour afficher les financeurs avec logo
const FinanceurTags = ({ financeurIds, financeurs }) => {
  const headingColor = useColorModeValue('gray.700', 'gray.300');

  // Définir les couleurs en dehors du map pour éviter d'appeler les hooks dans les callbacks
  const bgColor = useColorModeValue('green.100', 'green.700');
  const textColor = useColorModeValue('green.800', 'white');

  return (
    <Box>
      <Flex align="center" mb="2">
        <FaUniversity style={{ marginRight: '8px' }} color={headingColor} />
        <Heading as="h4" size="md" color={headingColor}>
          Financeurs Associés
        </Heading>
      </Flex>
      <HStack spacing="2" wrap="wrap">
        {financeurIds.map((id) => {
          const financeur = financeurs.find((f) => f.id === id);
          return financeur ? (
            <Tag
              key={financeur.id}
              borderRadius="full"
              px="2"
              colorScheme="green"
              display="flex"
              alignItems="center"
              gap="2"
              bg={bgColor}
              color={textColor}
            >
              <Avatar
                size="xs"
                src={financeur.logo || ''}
                name={financeur.nom}
                bg="gray.200"
                icon={<FaUniversity />}
              />
              {financeur.nom}
            </Tag>
          ) : null;
        })}
      </HStack>
    </Box>
  );
};

// Composant pour afficher les informations de contact détaillées
const ContactInfo = ({ contactName, contactPhone, contactEmail }) => {
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <VStack align="start" spacing="4">
      <Flex align="center">
        <MdPerson color="teal.500" size="20px" />
        <Text fontWeight="bold" ml="2" color={headingColor}>
          Nom/Service:
        </Text>
        <Text ml="2" color={textColor}>
          {contactName}
        </Text>
      </Flex>
      <Flex align="center">
        <MdPhone color="teal.500" size="20px" />
        <Text fontWeight="bold" ml="2" color={headingColor}>
          Téléphone:
        </Text>
        <Text ml="2" color={textColor}>
          {contactPhone}
        </Text>
      </Flex>
      <Flex align="center">
        <MdEmail color="teal.500" size="20px" />
        <Text fontWeight="bold" ml="2" color={headingColor}>
          Email:
        </Text>
        <Text ml="2" color={textColor}>
          {contactEmail}
        </Text>
      </Flex>
    </VStack>
  );
};

const AidesViewDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const [aide, setAide] = useState(null);
  const [financeurs, setFinanceurs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Couleurs basées sur le mode de couleur
  const tagColorScheme = useColorModeValue('blue', 'teal');
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  // Définition des couleurs pour les onglets
  const tabProcessusBg = useColorModeValue('teal.100', 'teal.700');
  const tabProcessusSelectedBg = useColorModeValue('teal.500', 'teal.600');

  const tabCriteriaBg = useColorModeValue('blue.100', 'blue.700');
  const tabCriteriaSelectedBg = useColorModeValue('blue.500', 'blue.600');

  const tabEligibilityBg = useColorModeValue('purple.100', 'purple.700');
  const tabEligibilitySelectedBg = useColorModeValue('purple.500', 'purple.600');

  const tabContactBg = useColorModeValue('orange.100', 'orange.700');
  const tabContactSelectedBg = useColorModeValue('orange.500', 'orange.600');

  const tabFinanceursBg = useColorModeValue('green.100', 'green.700');
  const tabFinanceursSelectedBg = useColorModeValue('green.500', 'green.600');

  // Charger les financeurs depuis Firestore
  useEffect(() => {
    const fetchFinanceurs = async () => {
      try {
        const financeursCol = collection(db, 'financeurs');
        const snapshot = await getDocs(financeursCol);
        const financeursList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFinanceurs(financeursList);
      } catch (err) {
        console.error('Erreur lors du chargement des financeurs:', err);
      }
    };

    fetchFinanceurs();
  }, []);

  // Charger les détails de l'aide depuis Firestore
  useEffect(() => {
    const fetchAideDetail = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const aideDoc = doc(db, 'aidesFormation', id);
        const aideSnapshot = await getDoc(aideDoc);
        if (aideSnapshot.exists()) {
          setAide({ id: aideSnapshot.id, ...aideSnapshot.data() });
        } else {
          setError('Aide non trouvée.');
        }
      } catch (err) {
        console.error('Erreur lors de la récupération de l\'aide:', err);
        setError('Impossible de charger les détails de l\'aide. Veuillez réessayer plus tard.');
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchAideDetail();
    }
  }, [id]);

  // Gestionnaire pour le bouton Retour
  const handleBack = () => {
    navigate('/aides-formation');
  };

  // Fonction pour déterminer si une URL est externe
  const isExternal = (url) => {
    return /^(http|https):/.test(url);
  };

  return (
    <Box
      maxW="7xl"
      mx="auto"
      mt="10"
      p={{ base: '4', md: '6' }}
      bg={bgColor}
    >
      {/* Bouton de Changement de Thème */}
      <Flex justify="flex-end" mb="4">
        <Tooltip label={colorMode === 'light' ? 'Passer en mode sombre' : 'Passer en mode clair'}>
          <IconButton
            aria-label="Toggle theme"
            icon={colorMode === 'light' ? <MdNightsStay /> : <MdWbSunny />}
            onClick={toggleColorMode}
            variant="ghost"
            size="lg"
          />
        </Tooltip>
      </Flex>

      {/* Bouton Retour avec Animation */}
      <MotionBox
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Button
          leftIcon={<MdArrowBack />}
          onClick={handleBack}
          mb="6"
          colorScheme="teal"
          variant="solid"
          size="md"
        >
          Retour aux Aides
        </Button>
      </MotionBox>

      {/* Contenu Principal avec Gestion des États */}
      {isLoading ? (
        <Center mt="10">
          <Spinner size="xl" color="teal.500" />
        </Center>
      ) : error ? (
        <Alert status="error" mt="10">
          <AlertIcon />
          {error}
        </Alert>
      ) : aide ? (
        <MotionBox
          bg={cardBg}
          borderRadius="lg"
          boxShadow="lg"
          p={{ base: '4', md: '6' }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {/* Type de l'Aide */}
          {aide.type && typeof aide.type === 'string' && (
            <Tag
              borderRadius="full"
              px="3"
              py="1"
              colorScheme={tagColorScheme}
              mb="4"
              fontSize="sm"
              alignSelf="flex-start"
            >
              {aide.type}
            </Tag>
          )}

          {/* Titre de l'Aide */}
          <Heading as="h2" size="2xl" mb="4" color="teal.600">
            {typeof aide.title === 'string' ? aide.title : 'Titre non disponible'}
          </Heading>

          {/* Description de l'Aide */}
          <Text fontSize="lg" color={textColor} mb="6" lineHeight="1.6">
            {typeof aide.description === 'string' ? aide.description : 'Description non disponible'}
          </Text>

          {/* Boutons "Déposer un Dossier" et "Simuler cette Aide" */}
          <HStack spacing="4" mb="6">
            {aide.simulationUrl && (
              isExternal(aide.simulationUrl) ? (
                <Button
                  as="a"
                  href={aide.simulationUrl}
                  colorScheme="blue"
                  leftIcon={<FaRegLightbulb />}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Simuler cette Aide
                </Button>
              ) : (
                <Button
                  as={RouterLink}
                  to={aide.simulationUrl}
                  colorScheme="blue"
                  leftIcon={<FaRegLightbulb />}
                >
                  Simuler cette Aide
                </Button>
              )
            )}
            {aide.submissionUrl && (
              isExternal(aide.submissionUrl) ? (
                <Button
                  as="a"
                  href={aide.submissionUrl}
                  colorScheme="teal"
                  leftIcon={<FaEnvelope />}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Déposer un Dossier
                </Button>
              ) : (
                <Button
                  as={RouterLink}
                  to={aide.submissionUrl}
                  colorScheme="teal"
                  leftIcon={<FaEnvelope />}
                >
                  Déposer un Dossier
                </Button>
              )
            )}
          </HStack>

          {/* Bandeau d'Avertissement si hasBudget est false */}
          {aide.status === 'Actif' && !aide.hasBudget && (
            <Alert status="error" mb="6" borderRadius="md" boxShadow="md">
              <AlertIcon />
              <AlertTitle mr={2}>Attention!</AlertTitle>
              <AlertDescription>
                Cette aide est active mais aucune demande ne peut être faite par vous ou l'organisme de formation pour le moment.
              </AlertDescription>
            </Alert>
          )}

          <Divider mb="6" />

          {/* Onglets pour les détails de l'Aide */}
          <Tabs variant="enclosed-colored" colorScheme="teal">
            <TabList>
              {aide.applicationProcess && (
                <Tab
                  bg={tabProcessusBg}
                  _selected={{ bg: tabProcessusSelectedBg, color: 'white' }}
                  borderRadius="md"
                >
                  <HStack>
                    <FaRegLightbulb />
                    <Text>Processus</Text>
                  </HStack>
                </Tab>
              )}
              {aide.criteria && (
                <Tab
                  bg={tabCriteriaBg}
                  _selected={{ bg: tabCriteriaSelectedBg, color: 'white' }}
                  borderRadius="md"
                >
                  <HStack>
                    <FaUniversity />
                    <Text>Critères</Text>
                  </HStack>
                </Tab>
              )}
              {aide.eligibility && (
                <Tab
                  bg={tabEligibilityBg}
                  _selected={{ bg: tabEligibilitySelectedBg, color: 'white' }}
                  borderRadius="md"
                >
                  <HStack>
                    <FaRegLightbulb />
                    <Text>Éligibilité</Text>
                  </HStack>
                </Tab>
              )}
              {(aide.contactName || aide.contactPhone || aide.contactEmail) && (
                <Tab
                  bg={tabContactBg}
                  _selected={{ bg: tabContactSelectedBg, color: 'white' }}
                  borderRadius="md"
                >
                  <HStack>
                    <FaUser />
                    <Text>Contact</Text>
                  </HStack>
                </Tab>
              )}
              {aide.financeurs && aide.financeurs.length > 0 && (
                <Tab
                  bg={tabFinanceursBg}
                  _selected={{ bg: tabFinanceursSelectedBg, color: 'white' }}
                  borderRadius="md"
                >
                  <HStack>
                    <FaUniversity />
                    <Text>Financeurs</Text>
                  </HStack>
                </Tab>
              )}
            </TabList>

            <TabPanels>
              {aide.applicationProcess && (
                <TabPanel>
                  <Section icon={FaRegLightbulb} title="Processus de Candidature">
                    {aide.applicationProcess}
                  </Section>
                </TabPanel>
              )}
              {aide.criteria && (
                <TabPanel>
                  <Section icon={FaUniversity} title="Critères de Sélection">
                    {aide.criteria}
                  </Section>
                </TabPanel>
              )}
              {aide.eligibility && (
                <TabPanel>
                  <Section icon={FaRegLightbulb} title="Éligibilité">
                    {aide.eligibility}
                  </Section>
                </TabPanel>
              )}
              {(aide.contactName || aide.contactPhone || aide.contactEmail) && (
                <TabPanel>
                  <Section icon={FaUser} title="Informations de Contact">
                    <ContactInfo
                      contactName={aide.contactName || 'Non renseigné'}
                      contactPhone={aide.contactPhone || 'Non renseigné'}
                      contactEmail={aide.contactEmail || 'Non renseigné'}
                    />
                  </Section>
                </TabPanel>
              )}
              {aide.financeurs && aide.financeurs.length > 0 && (
                <TabPanel>
                  <FinanceurTags financeurIds={aide.financeurs} financeurs={financeurs} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </MotionBox>
      ) : null}
    </Box>
  );
};

export default AidesViewDetail;
