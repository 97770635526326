// src/admin/pages/Exam.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Heading,
  useToast,
  Flex,
  Spinner,
} from '@chakra-ui/react';

const Exam = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();

  // États pour la création de session d'examen
  const [examType, setExamType] = useState(''); // Type d'examen (ex: Examen final, Test d'entrée, etc.)
  const [examDate, setExamDate] = useState(''); // Date de l'examen
  const [formationId, setFormationId] = useState(''); // Formation à laquelle l'examen est rattaché
  const [formations, setFormations] = useState([]); // Liste des formations disponibles
  const [isLoadingFormations, setIsLoadingFormations] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fonction de récupération des formations
  const fetchFormations = useCallback(async () => {
    setIsLoadingFormations(true);
    try {
      const formationsCol = collection(db, 'formations');
      const formationsSnapshot = await getDocs(formationsCol);
      const formationsList = formationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFormations(formationsList);
    } catch (error) {
      console.error('Erreur lors de la récupération des formations:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les formations.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingFormations(false);
    }
  }, [db, toast]);

  useEffect(() => {
    fetchFormations();
  }, [fetchFormations]);

  // Fonction de création de session d'examen
  const handleCreateExam = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Ajouter une nouvelle session d'examen dans Firestore
      await addDoc(collection(db, 'exams'), {
        examType,
        examDate,
        formationId,
        createdAt: new Date(),
      });

      toast({
        title: 'Session d\'examen créée.',
        description: 'La session d\'examen a été créée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Réinitialiser les champs du formulaire après la création
      setExamType('');
      setExamDate('');
      setFormationId('');
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Erreur lors de la création de la session d\'examen:', error);
      toast({
        title: 'Erreur.',
        description: error.message || 'Impossible de créer la session d\'examen.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex direction="column" align="center" justify="start" minH="100vh" bg="gray.50" p="10">
      <Box
        w="full"
        maxW="4xl"
        bg="white"
        p="8"
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading as="h2" size="lg" mb="6">Enregistrer une Session d'Examen</Heading>
        <form onSubmit={handleCreateExam}>
          <Stack spacing="6">
            <FormControl id="examType" isRequired>
              <FormLabel>Type d'examen</FormLabel>
              <Input
                type="text"
                value={examType}
                onChange={(e) => setExamType(e.target.value)}
                placeholder="Entrez le type d'examen (ex: Examen final, Test d'entrée)"
                bg="gray.100"
              />
            </FormControl>

            <FormControl id="examDate" isRequired>
              <FormLabel>Date de l'examen</FormLabel>
              <Input
                type="date"
                value={examDate}
                onChange={(e) => setExamDate(e.target.value)}
                bg="gray.100"
              />
            </FormControl>

            <FormControl id="formationId" isRequired>
              <FormLabel>Formation associée</FormLabel>
              {isLoadingFormations ? (
                <Spinner />
              ) : (
                <Select
                  placeholder="Sélectionnez une formation"
                  value={formationId}
                  onChange={(e) => setFormationId(e.target.value)}
                >
                  {formations.map((formation) => (
                    <option key={formation.id} value={formation.id}>
                      {formation.title}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>

            <Button colorScheme="blue" type="submit" width="full" isLoading={isSubmitting}>
              Enregistrer la Session d'Examen
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default Exam;
