// src/admin/pages/Certificateurs.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  getFirestore,
  serverTimestamp,
  query,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  Box,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Image,
  useToast,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  VStack,
  HStack,
  Text,
} from '@chakra-ui/react';
import { MdAdd, MdCloudUpload } from 'react-icons/md';

const Certificateurs = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const db = getFirestore();
  const storage = getStorage();

  // États pour le formulaire
  const [name, setName] = useState('');
  const [siret, setSiret] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // États pour la liste des certificateurs
  const [certificateurs, setCertificateurs] = useState([]);
  const [loadingCertificateurs, setLoadingCertificateurs] = useState(true);

  // Gestionnaire de changement pour le logo
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  // Fonction de soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des champs
    if (!name || !logo) {
      toast({
        title: 'Champs manquants.',
        description: 'Veuillez remplir le nom et ajouter un logo.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validation du format du SIRET (14 chiffres) si fourni
    if (siret) {
      const siretRegex = /^\d{14}$/;
      if (!siretRegex.test(siret)) {
        toast({
          title: 'SIRET invalide.',
          description: 'Le numéro de SIRET doit contenir 14 chiffres.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    setIsSubmitting(true);

    try {
      // Upload du logo dans Firebase Storage
      const storageRef = ref(storage, `certificateurs/${Date.now()}_${logo.name}`);
      const snapshot = await uploadBytes(storageRef, logo);
      const logoURL = await getDownloadURL(snapshot.ref);

      // Préparer les données à ajouter
      const certificateurData = {
        name: name.trim(),
        logoURL,
        createdAt: serverTimestamp(),
      };

      // Ajouter le SIRET uniquement s'il est fourni
      if (siret) {
        certificateurData.siret = siret.trim();
      }

      // Ajout du document dans Firestore
      const docRef = await addDoc(collection(db, 'certificateurs'), certificateurData);

      // Réinitialisation du formulaire
      setName('');
      setSiret('');
      setLogo(null);
      setLogoPreview(null);

      toast({
        title: 'Certificateur créé.',
        description: `Le certificateur "${name}" a été ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Rafraîchir la liste des certificateurs
      fetchCertificateurs();

      // Optionnel : Redirection vers une autre page
      // navigate('/admin/certificateurs');
    } catch (error) {
      console.error('Erreur lors de la création du certificateur:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de créer le certificateur. Veuillez réessayer plus tard.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fonction pour récupérer les certificateurs existants
  const fetchCertificateurs = async () => {
    setLoadingCertificateurs(true);
    try {
      const q = query(collection(db, 'certificateurs'), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const fetchedCertificateurs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCertificateurs(fetchedCertificateurs);
    } catch (error) {
      console.error('Erreur lors de la récupération des certificateurs:', error);
      toast({
        title: 'Erreur.',
        description: 'Impossible de récupérer les certificateurs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingCertificateurs(false);
    }
  };

  // Utiliser useEffect pour récupérer les certificateurs au montage du composant
  useEffect(() => {
    fetchCertificateurs();
    // Nettoyage des URL.createObjectURL
    return () => {
      if (logoPreview) {
        URL.revokeObjectURL(logoPreview);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" align="center" justify="start" minH="100vh" bgGradient="linear(to-b, teal.50, blue.50)" p="10">
      {/* Header */}
      <Card
        w="full"
        maxW="6xl"
        mb="8"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        transition="all 0.3s ease"
        _hover={{ boxShadow: 'dark-lg' }}
      >
        <CardHeader p="6">
          <Flex justify="space-between" align="center">
            <Heading as="h2" size="lg" color="teal.600">
              Ajouter un Certificateur
            </Heading>
            <Button
              onClick={() => navigate('/admin/dashboard')}
              colorScheme="blue"
              leftIcon={<MdAdd />}
              variant="outline"
            >
              Retour au Tableau de Bord
            </Button>
          </Flex>
        </CardHeader>
      </Card>

      {/* Formulaire d'ajout */}
      <Card
        w="full"
        maxW="6xl"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        p="6"
        mb="8"
      >
        <CardBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <FormControl id="name" isRequired>
                <FormLabel>Nom du Certificateur</FormLabel>
                <Input
                  placeholder="Entrez le nom du certificateur"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <FormControl id="siret" mb="4">
                <FormLabel>Numéro de SIRET</FormLabel>
                <NumberInput max={99999999999999} min={0}>
                  <NumberInputField
                    placeholder="Entrez le numéro de SIRET (14 chiffres)"
                    value={siret}
                    onChange={(e) => setSiret(e.target.value)}
                  />
                </NumberInput>
              </FormControl>

              <FormControl id="logo" isRequired>
                <FormLabel>Logo du Certificateur</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                />
                {logoPreview && (
                  <Box mt="4">
                    <Image src={logoPreview} alt="Aperçu du logo" boxSize="150px" objectFit="contain" />
                  </Box>
                )}
              </FormControl>

              <Button
                type="submit"
                colorScheme="teal"
                leftIcon={<MdCloudUpload />}
                isLoading={isSubmitting}
                loadingText="Enregistrement..."
                width="full"
              >
                Enregistrer le Certificateur
              </Button>
            </VStack>
          </form>
        </CardBody>
      </Card>

      {/* Liste des certificateurs existants */}
      <Card
        w="full"
        maxW="6xl"
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        p="6"
      >
        <CardHeader>
          <Heading as="h3" size="md" color="teal.600">
            Certificateurs Existants
          </Heading>
        </CardHeader>
        <CardBody>
          {loadingCertificateurs ? (
            <Flex justify="center" align="center" py="10">
              <Spinner size="xl" color="teal.500" />
            </Flex>
          ) : certificateurs.length > 0 ? (
            <VStack spacing={4} align="stretch">
              {certificateurs.map(cert => (
                <Card key={cert.id} p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
                  <HStack spacing={4}>
                    <Image src={cert.logoURL} alt={`${cert.name} Logo`} boxSize="100px" objectFit="contain" />
                    <Box>
                      <Text fontWeight="bold" fontSize="lg">{cert.name}</Text>
                      {cert.siret && <Text>SIRET : {cert.siret}</Text>}
                      <Text>Date de Création : {cert.createdAt?.toDate().toLocaleString() || 'N/A'}</Text>
                    </Box>
                  </HStack>
                </Card>
              ))}
            </VStack>
          ) : (
            <Text>Aucun certificateur n'est disponible actuellement.</Text>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default Certificateurs;
